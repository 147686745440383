import { Col, Row, Select, Table, Form } from "antd";
import React, { useState } from "react";
import Assistant from "../../components/VirtualAssistant";
import { downloadDocumentosTalk } from "../../constants/aroldoTalks";
import DocumentList from "./_/components/DocumentList";
import "./styles.scss";
import { connect } from "react-redux";
import { selectCompany } from "../../components/CompanyList/_/containers/Companies/_/actions";
import { getDocuments } from "./_/actions";
import { bindActionCreators } from "redux";
import IconSESI from "../../components/IconsSESI";

const DownloadDocumentos = (props) => {
  const { Option } = Select;
  const uniqueCompanies = props.companyList.reduce((acc, obj) => {
    let exist = acc.find(({ cnpj }) => obj.cnpj === cnpj);
    if (!exist) {
      acc.push(obj);
    }
    return acc;
  }, []);
  const companies = uniqueCompanies;
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState({});

  const selectCompanyHandler = (cnpj) => {
    getDocuments(props.dispatch, cnpj, setShowTable, parseTableData);
  };

  const parseTableData = (data) => {
    let parsedTable = [];
    data.map((document, index) => {
      parsedTable.push({
        key: index,
        name: document.nomeArquivo,
        date: document.dataVersao,
        type: "PDF",
        download: document.caminho,
      });
    });
    setTableData(parsedTable);
  };

  return (
    <React.Fragment>
      {console.log(downloadDocumentosTalk[0])}
      <Row gutter={34}>
        <Col span={6}>
          <Assistant talk={downloadDocumentosTalk[0]} perfil={props.perfil} />
        </Col>
        <Col span={18} className="documents-wrapper">
          <div>
            <div className="header-form">
              <h2>Realize o download de documentos emitidos</h2>
            </div>
            <div className="content">
              <Form>
                <div className="section">
                  <Form.Item label="Empresa::">
                    <Select onSelect={selectCompanyHandler}>
                      {companies.map((company) => (
                        <Option key={company.cnpj} value={company.cnpj}>
                          {company.razaoSocial}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Form>
            </div>
            <div>
              {showTable ? <DocumentList tableData={tableData} /> : null}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    companyList: state.user.companies,
    perfil: state.user.userInfo.perfil,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getDocuments,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDocumentos);
