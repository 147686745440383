import axios from "axios";
import { redefinirSenha, redefinirSenhaUrl } from "../../../../constants";
import { REQUERIR_MUDANCA_SENHA, REDEFINIR_SENHA } from "./types";
import openNotification from "../../../../components/notifications";

export const requerirMudancaDeSenha = (dispatch, cpf) => {
  console.log(dispatch);
  axios
    .get(redefinirSenhaUrl(cpf))
    .then((response) => {
      dispatch({
        type: REQUERIR_MUDANCA_SENHA,
        payload: response.data,
        userCpf: cpf,
      });
    })
    .catch((err) => console.log(err));
};

export const novaSenha = (dispatch, newPasswordBody, resetState, nextTab) => {
  axios
    .post(redefinirSenha, newPasswordBody)
    .then((response) => {
      dispatch({
        type: REDEFINIR_SENHA,
        payload: response.data,
      });
      openNotification("Senha alterada com sucesso!", "", "success");
      nextTab(resetState);
    })
    .catch((err) => {
      if (err.response.data.message === "SENHA_FORA_DO_PADRAO") {
        openNotification(
          "Padrão de senha inválida!",
          "Verifique os requisitos da senha.",
          "error"
        );
      } else {
        openNotification("Erro ao redefinir sua senha!", "", "error");
      }
    });
};
