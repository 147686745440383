import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SideBar from "../SideBar";
import Content from "../Content";
import { bindActionCreators } from "redux";
import { patchBeginningTalk } from "../../actions";
import { AUTODECLARACAO } from "../../constants";

const AutodeclaracaoAssistenteVirtual = (props) => {
  const [currentTalk, setCurrentTalk] = useState({});
  const [unchangeableParagraphs, setUnchangeableParagraphs] = useState([]);

  useEffect(() => {
    props.talksAutodeclaracao.sort((a, b) => a.id - b.id);
    setCurrentTalk(props.talksAutodeclaracao[0]);
    setUnchangeableParagraphs(props.talksAutodeclaracao[0].naoEditavel);
  }, [JSON.stringify(props.talksAutodeclaracao)]);

  const changeTalk = (keyArr) => {
    let key = keyArr[0];
    switch (key) {
      case "0-0":
        setCurrentTalk(props.talksAutodeclaracao[0]);
        setUnchangeableParagraphs(props.talksAutodeclaracao[0].naoEditavel);
        break;
      case "0-1-0":
        setCurrentTalk(props.talksAutodeclaracao[1]);
        setUnchangeableParagraphs(props.talksAutodeclaracao[1].naoEditavel);
        break;
      case "0-1-1":
        setCurrentTalk(props.talksAutodeclaracao[2]);
        setUnchangeableParagraphs(props.talksAutodeclaracao[2].naoEditavel);
        break;
      case "0-1-2":
        setCurrentTalk(props.talksAutodeclaracao[3]);
        setUnchangeableParagraphs(props.talksAutodeclaracao[3].naoEditavel);
        break;
      case "0-1-3":
        setCurrentTalk(props.talksAutodeclaracao[4]);
        setUnchangeableParagraphs(props.talksAutodeclaracao[4].naoEditavel);
        break;
      case "0-1-4":
        setCurrentTalk(props.talksAutodeclaracao[5]);
        setUnchangeableParagraphs(props.talksAutodeclaracao[5].naoEditavel);
        break;
      case "0-2":
        setCurrentTalk(props.talksAutodeclaracao[6]);
        setUnchangeableParagraphs(props.talksAutodeclaracao[6].naoEditavel);
        break;
      default:
        break;
    }
  };

  return (
    <div className="admin-assistente-virtual-wrapper">
      <div className="admin-assistente-virtual-lateral">
        <SideBar
          content={props.talksAutodeclaracao}
          changeTalk={changeTalk}
          category={AUTODECLARACAO}
        />
      </div>
      <div className="admin-assistente-virtual-falas">
        <Content
          currentTalk={currentTalk}
          dispatch={props.dispatch}
          patchBeginningTalk={patchBeginningTalk}
          category={AUTODECLARACAO}
          changeTalk={changeTalk}
          unchangeableParagraphs={unchangeableParagraphs}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    talksAutodeclaracao: state.gestorDN.virtualAssistant.autoStatement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ patchBeginningTalk }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutodeclaracaoAssistenteVirtual);
