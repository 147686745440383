import React, { useState } from "react";
import logo from "../../../../constants/images/logo512.svg";
import sesiLogo from "../../../../constants/images/sesi-logo.svg";
import LoginForm from "../components/LoginForm";
import { Tabs } from "antd";
import PasswordForm from "../components/PasswordForm";
import CodeForm from "../components/CodeForm";
import NewPasswordForm from "../components/NewPasswordForm";
import ResetPasswordResponse from "../components/ResetPasswordResponse";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signIn, fetchCompanies } from "../../../../shared/LayoutApp/_/actions";
import { requerirMudancaDeSenha, novaSenha } from "../actions";

const Login = (props) => {
  const [currentTab, setCurrentTab] = useState("login");

  const toggleTab = (tab) => {
    setCurrentTab(tab);
  };

  const { TabPane } = Tabs;

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-header">
          <img src={logo} alt="Sesi Faciita" />
        </div>
        <div className="login-body">
          <div className="fake-background"></div>
          <Tabs activeKey={currentTab}>
            <TabPane tab="" key="login">
              <LoginForm
                tab={toggleTab}
                dispatch={props.dispatch}
                signIn={signIn}
              />
            </TabPane>
            <TabPane tab="" key="password">
              <PasswordForm
                tab={toggleTab}
                requerirMudancaDeSenha={requerirMudancaDeSenha}
                dispatch={props.dispatch}
              />
            </TabPane>
            <TabPane tab="" key="code">
              <CodeForm tab={toggleTab} mudancaDeSenha={props.mudancaDeSenha} />
            </TabPane>
            <TabPane tab="" key="newPassword">
              <NewPasswordForm
                tab={toggleTab}
                novaSenha={novaSenha}
                dispatch={props.dispatch}
                mudancaDeSenha={props.mudancaDeSenha}
              />
            </TabPane>
            <TabPane tab="" key="resetResponse">
              <ResetPasswordResponse tab={toggleTab} />
            </TabPane>
          </Tabs>
        </div>
        <div className="login-footer">
          <a
            href="http://www.portaldaindustria.com.br/sesi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={sesiLogo} alt="SESI" />
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    mudancaDeSenha: state.mudancaDeSenha,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      { signIn, fetchCompanies, requerirMudancaDeSenha },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
