import { Button, Checkbox, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getMAOs,
  getMAO,
  listarFatoresforGrupoDeRisco,
  patchMAO,
  emptyRelacaoMAOSelecionanda,
  addMAO,
} from "../../actions";
import "../../../styles.scss";

const { Option } = Select;

const MAOForm = (props) => {
  // const [newRiskRelation, setNewRiskRelation] = useState();
  const [fatoresDeRiscoPorGrupo, setFatoresDeRiscoPorGrupo] = useState([]);
  const [mode, setMode] = useState("new");
  const [
    relacaoMAOFatorDeRiscoSelecionada,
    setRelacaoMAOFatorDeRiscoSelecionada,
  ] = useState({});
  const [maos, setMaos] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getMAOs(props.dispatch);
  }, []);

  useEffect(() => {
    if (props.relacaoMAOFatorDeRiscoSelecionada) setMode("edit");
    if (props.relacaoMAOFatorDeRiscoSelecionada)
      setRelacaoMAOFatorDeRiscoSelecionada(
        props.relacaoMAOFatorDeRiscoSelecionada
      );

    setEdit(props.relacaoMAOFatorDeRiscoSelecionada);
  }, [JSON.stringify(props.relacaoMAOFatorDeRiscoSelecionada)]);

  useEffect(() => {
    setMaos(maos);
  }, [JSON.stringify(maos)]);

  const setEdit = (relacaoMAOFatorDeRiscoSelecionada) => {
    if (mode === "edit") {
      form.setFieldsValue({
        riskName: relacaoMAOFatorDeRiscoSelecionada.descricao,
        riskMAOs: relacaoMAOFatorDeRiscoSelecionada.maos.map((mao) => mao.id),
      });
    }
  };

  const handleGroupSelection = () => {
    form
      .validateFields()
      .then((values) => {
        listarFatoresforGrupoDeRisco(values.riskGroup, props.dispatch);
      })
      .catch((err) => console.log(err));
  };

  const addNewMAO = () => {
    console.log(form.getFieldsValue(true));
    form.validateFields().then((values) => {
      let newRelacaoMAO = {
        maos: values.riskMAOs,
      };
      addMAO(values.riskName, newRelacaoMAO, props.dispatch);
    });
  };

  const patchRelacaoMAO = () => {
    form.validateFields().then((values) => {
      let edittedMAO = {
        maos: values.riskMAOs,
      };
      patchMAO(
        props.relacaoMAOFatorDeRiscoSelecionada.id,
        edittedMAO,
        props.dispatch
      );
      emptyRelacaoMAOSelecionanda(props.dispatch);
    });
  };

  return (
    <React.Fragment>
      <Form
        form={form}
        onFinish={mode === "new" ? () => addNewMAO() : () => patchRelacaoMAO()}
      >
        <Form.Item name="riskGroup">
          <Select
            style={{
              width: 250,
            }}
            placeholder="Selecione um grupo de risco"
            onSelect={() => handleGroupSelection()}
          >
            <Option value="1">Físico</Option>
            <Option value="2">Químico</Option>
            <Option value="3">Biológico</Option>
            <Option value="4">Ergonômico</Option>
            <Option value="5">Acidente</Option>
            <Option value="6">Inespecífico</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Fator de risco" name="riskName">
          <Select>
            {props.fatoresDeRiscoPorGrupo
              ? props.fatoresDeRiscoPorGrupo.map((fator) => (
                  <Option value={fator.id}>{fator.descricao}</Option>
                ))
              : ""}
          </Select>
        </Form.Item>
        <Form.Item label="MAOs associados" name="riskMAOs">
          <Select
            mode="multiple"
            style={{
              width: "100%",
            }}
          >
            {props.maos
              ? props.maos.map((mao) => (
                  <Option value={mao.id}>{mao.nome}</Option>
                ))
              : ""}
          </Select>
        </Form.Item>
        <Button htmlType="submit">Salvar</Button>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    relacaoMAOFatorDeRiscoSelecionada:
      state.gestorDN.relacaoMAOFatorDeRiscoSelecionada,
    maos: state.gestorDN.listaMAOs,
    fatoresDeRiscoPorGrupo: state.gestorDN.fatoresDeRiscoPorGrupo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      getMAOs,
      getMAO,
      listarFatoresforGrupoDeRisco,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MAOForm);
