import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, List, Menu, Tag } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IconSESI from "../../../../components/IconsSESI";
import { gerarDocumento, listarEventos } from "../actions";

const ESocialFollowUp = (props) => {
  const [link, setLink] = useState("");

  useEffect(() => {
    listarEventos(props.dispatch);
  }, []);

  const handleGenerate = (id) => {
    gerarDocumento(id, props.dispatch);
    setLink(props.documentoGerado);
  };

  const menu = (evento) => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => handleGenerate(evento.id, props.dispatch)}
        >
          <IconSESI type="file-signature-outlined" /> Assinar
        </Menu.Item>
        {evento.assinado ? (
          <Menu.Item key="2">
            <IconSESI type="send" /> Enviar
          </Menu.Item>
        ) : null}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_BACKEND_URL}${link}`}
          download={true}
        >
          <Menu.Item
            key="3"
            onClick={() => handleGenerate(evento.id, props.dispatch)}
          >
            <IconSESI type="download-disabled" /> Baixar
          </Menu.Item>
        </a>
        {/* <Menu.Item key="4">
          <IconSESI type="edit" /> Retificar
        </Menu.Item>
        <Menu.Item key="5">
          <IconSESI type="add-circle" /> Editar
        </Menu.Item> */}
      </Menu>
    );
  };

  return (
    <div className="content">
      <List>
        {props.eventosEsocial.esociais2220
          ? props.eventosEsocial.esociais2220.map((evento) => {
              return (
                <List.Item>
                  <div>
                    <span>
                      <h4>Funcionário:</h4> <Tag>{evento.nomeTrab}</Tag>
                    </span>
                    <span>
                      <h4>Tipo do evento:</h4> <Tag>2220</Tag>
                    </span>
                    <span>
                      <h4>Data do ASO:</h4> <Tag>{evento.dtAso}</Tag>
                    </span>
                    <span>
                      <h4>Status de envio:</h4>{" "}
                      <Tag>{evento.enviado ? "Enviado" : "Não enviado"}</Tag>
                    </span>
                    <span>
                      <h4>Status de assinatura:</h4>
                      <Tag>
                        {evento.assinado ? "Assinado" : "Assinatura pendente"}
                      </Tag>
                    </span>
                  </div>
                  <Dropdown overlay={menu(evento)} trigger={["click"]}>
                    <Button>
                      <EllipsisOutlined />
                    </Button>
                  </Dropdown>
                </List.Item>
              );
            })
          : ""}
      </List>
    </div>
  );
};

const mapStateProps = (state) => {
  return {
    eventosEsocial: state.esocial.eventosEsocial
      ? state.esocial.eventosEsocial
      : [],
    documentoGerado: state.esocial.documentoGerado
      ? state.esocial.documentoGerado.url
      : "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        listarEventos,
        gerarDocumento,
      },
      dispatch
    ),
  };
};

export default connect(mapStateProps, mapDispatchToProps)(ESocialFollowUp);
