import { Col, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IconSESI from "../../../../../../../components/IconsSESI";
import { steps } from "../../../../../../../constants/steps";
import { getTalks } from "../actions";

const AssistenteVirtualContainer = (props) => {
  const stepsOptions = steps["ADMINASSISTENTEVIRTUAL"];
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    getTalks(props.dispatch);
  }, []);

  const renderChild = (stepsOptions, current) => {
    console.log(stepsOptions[current]);
    return stepsOptions[current].content({
      current: current,
      id: stepsOptions[current].id,
    });
  };

  const stepItem = (item) => {
    return (
      <Steps.Step
        key={item.id}
        title={item.title}
        className={`${item.title} assistente-virtual`}
        icon={<IconSESI type={item.icon} />}
        onClick={() => setCurrent(item.number)}
      />
    );
  };

  return (
    <Col lg={24} md={24}>
      <Steps type="navigation" current={current}>
        {stepsOptions.map((item) => {
          return stepItem(item);
        })}
      </Steps>
      <div className="steps-content">{renderChild(stepsOptions, current)}</div>
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    virtualAssistantTalks: state.gestorDN.virtualAssistant,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ getTalks }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssistenteVirtualContainer);
