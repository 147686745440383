import React, { useState, useEffect } from "react";
import Assistant from "../../../../../components/VirtualAssistant";
import SelectCompany from "../../../../../components/SelectCompany";
import CompanyInformationForm from "./_/components/CompanyInformationForm";
import { Row, Col, Button } from "antd";
import { companySelectionTalks } from "../../../../../constants/aroldoTalks";
import { Link } from "react-router-dom";
import IconSESI from "../../../../../components/IconsSESI";

const CompanySelectResponsavel = (props) => {
  const [, setIsEnabled] = useState(false);
  const [talk, setTalk] = useState(0);

  const formCompleted = (companySelected) => {
    const items = [
      companySelected.nomeResponsavel,
      companySelected.cargo,
      companySelected.numeroTelefoneContato,
      companySelected.emailResponsavel,
      companySelected.cpfResponsavel,
      companySelected.emailContato,
      companySelected.descricaCargoContato,
      companySelected.nomeContato,
    ];
    const itemsBool = items.map((item) => item === "" || item === undefined);
    return itemsBool.some((e) => e === true);
  };

  const getNextPage = (companySelected) => {
    if (companySelected.cnae.grauDeRisco < 3) {
      if (
        companySelected.autodeclaracao &&
        companySelected.questionarioAutodeclaracao
      ) {
        return "/caracterizacao";
      } else if (!companySelected.questionarioAutodeclaracao) {
        return "/autodeclaracao";
      } else if (
        companySelected.questionarioAutodeclaracao &&
        !companySelected.autodeclaracao
      ) {
        return "/gerar-autodeclaracao";
      } else {
        return "/caracterizacao";
      }
    } else {
      return "/caracterizacao";
    }
  };

  const handleDisabled = (bool) => {
    setIsEnabled(bool);
  };

  useEffect(() => {
    if (props.talkInicio) {
      let talks = companySelectionTalks(props.talkInicio[1].descricao);
      setTalk(talks[props.talkInicio[1].id]);
    }
  }, [JSON.stringify(props.talkInicio)]);

  return (
    <div className="selecionar-empresa-wrapper">
      <Assistant talk={talk} />
      <Row gutter={24}>
        <Col lg={6} md={24}>
          <div className="selecionar-empresa">
            <SelectCompany />
          </div>
        </Col>
        <Col lg={18} md={24}>
          <div className="definir-operador">
            <Row gutter={32} type="flex" justify="space-around" align="middle">
              <Col span={18}>
                <div className="header-form">
                  <Link to="/">
                    <Button shape="circle" ghost>
                      <IconSESI type="arrow-left" />
                    </Button>
                  </Link>
                  <h2>Empresa</h2>
                </div>
              </Col>
              <Col span={6}>
                <Link
                  to={
                    props.companySelected
                      ? formCompleted(props.companySelected)
                        ? "/selecaoempresa"
                        : getNextPage(props.companySelected)
                      : "/selecaoempresa"
                  }
                  className="ant-btn ant-btn-primary ant-btn-block next-step"
                  disabled={
                    props.companySelected
                      ? formCompleted(props.companySelected)
                      : true
                  }
                  isActive={
                    props.companySelected
                      ? formCompleted(props.companySelected)
                      : true
                  }
                >
                  <span>Próximo passo</span>
                </Link>
              </Col>
            </Row>
            <div className="content">
              <div className="dados-da-empresa">
                <CompanyInformationForm handleDisabled={handleDisabled} />
              </div>
              <Row gutter={32} type="flex" align="end">
                <Col span={6}>
                  <div className="footer">
                    <Link
                      to="/checklist"
                      className="ant-btn ant-btn-primary ant-btn-block next-step"
                    >
                      <span>Checklist</span>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompanySelectResponsavel;
