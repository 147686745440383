import Axios from "axios";
import {
  alterarTagsCNAESAutomatizados,
  listarCNAEsAutomatizados,
  listarCNAEsFaltantes,
  listarCNAEsNaoAutomatizados,
  salvarCNAEFaltante,
} from "../../../../../../../constants";
import {
  ADICIONAR_CNAE_FALTANTE,
  ALTERAR_TAGS,
  DELETAR_CNAE_FALTANTE,
  LISTAR_CNAES,
  LISTAR_CNAES_FALTANTES,
  LISTAR_CNAES_NAO_AUTOMATIZADOS,
} from "./types";
import openNotification from "../../../../../../../components/notifications";

export const getCurrentCNAEs = (dispatch) => {
  Axios.get(listarCNAEsAutomatizados)
    .then((response) => {
      dispatch({
        type: LISTAR_CNAES,
        payload: response.data,
      });
    })
    .catch(() => {
      openNotification(
        "Erro ao obter os CNAEs automatizados!",
        "Tente novamente",
        "error"
      );
    });
};

export const getNotAutomatedCNAEs = (dispatch) => {
  Axios.get(listarCNAEsNaoAutomatizados)
    .then((response) => {
      dispatch({
        type: LISTAR_CNAES_NAO_AUTOMATIZADOS,
        payload: response.data,
      });
    })
    .catch(() => {
      openNotification(
        "Erro ao obter os CNAEs não automatizados!",
        "Tente novamente",
        "error"
      );
    });
};

export const getMissingCNAEs = (dispatch) => {
  Axios.get(listarCNAEsFaltantes)
    .then((response) => {
      dispatch({
        type: LISTAR_CNAES_FALTANTES,
        payload: response.data,
      });
    })
    .catch(() => {
      openNotification(
        "Erro ao obter os CNAEs faltantes!",
        "Tente novamente",
        "error"
      );
    });
};

export const changeTags = (dispatch, cnae, tags) => {
  Axios.patch(alterarTagsCNAESAutomatizados(cnae), { tags: tags })
    .then((response) => {
      dispatch({
        type: ALTERAR_TAGS,
        payload: response.data,
      });
    })
    .catch(() => {
      openNotification("Erro ao modificar tags!", "Tente novamente", "error");
    });
};

export const saveMissingCNAE = (dispatch, cnae, callback) => {
  Axios.post(salvarCNAEFaltante, {
    cnae: cnae.codigo,
    descricao: cnae.nome,
    lote: cnae.lote,
    tags: [],
  })
    .then((response) => {
      dispatch({
        type: ADICIONAR_CNAE_FALTANTE,
        payload: response.data,
      });
      openNotification("CNAE faltante adicionado com sucesso!", "", "success");
      callback(false);
    })
    .catch(() => {
      openNotification(
        "Erro ao adicionar CNAE faltante!",
        "Tente novamente",
        "error"
      );
    });
};

export const deleteMissingCNAE = (dispatch, id) => {
  Axios.delete(`${salvarCNAEFaltante}/${id}`)
    .then(() => {
      dispatch({
        type: DELETAR_CNAE_FALTANTE,
        payload: id,
      });
      openNotification("CNAE faltante removido com sucesso!", "", "success");
    })
    .catch(() => {
      openNotification(
        "Erro ao deletar CNAE faltante!",
        "Tente novamente",
        "error"
      );
    });
};
