import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Form, Input, Collapse, Button, Tabs, Tag } from "antd";
import {
  RightCircleOutlined,
  FormOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  saveNomeFantasia,
  saveResponsavelEmpresa,
  saveContatoEmpresa,
  saveResponsavelPreenchimentoEmpresa,
} from "../../actions";
import { bindActionCreators } from "redux";
import MaskedInput from "antd-mask-input";
import { Row, Col } from "antd";
import "./style.scss";

const { Panel } = Collapse;
const { TabPane } = Tabs;

const CompanyInformationForm = (props) => {
  const [form] = Form.useForm();
  const [, setInitialValues] = useState({});
  const [canEdit, setCanEdit] = useState(false);
  const [currentTab] = useState("edit-responsible");
  const [canEditResponsible, setCanEditResponsible] = useState(true);
  const [canEditOperator, setCanEditOperator] = useState(false);
  const [canEditContact, setCanEditContact] = useState(false);
  const [responsavelCompleted, setResponsavelCompleted] = useState(false);
  const [contatoCompleted, setContatoCompleted] = useState(false);
  const [
    responsavelPreenchimentoCompleted,
    setResponsavelPreenchimentoCompleted,
  ] = useState(false);

  // const [canAddAccess, setCanAddAccess] = useState(false);
  // const [hasAddedAccess, setHasAddedAccess] = useState(false);
  // const [additionalAccessTab, setAdditionalAccessTab] = useState(
  //   "additional-access-button"
  // );

  useEffect(() => {
    setInitialValues(props.initialValues);
    getResponsavelAllCompleted();
    getContatoAllCompleted();
    getResponsavelPreenchimentoAllCompleted();
  }, [JSON.stringify(props.initialValues)]);

  useEffect(() => {
    getResponsavelAllCompleted();
    getContatoAllCompleted();
    getResponsavelPreenchimentoAllCompleted();
    setTimeout(
      props.handleDisabled(
        [
          responsavelCompleted,
          contatoCompleted,
          responsavelPreenchimentoCompleted,
        ].every((e) => e === false)
      ),
      100
    );
  }, [JSON.stringify(props.company)]);

  const toggleCanEditResponsible = (bool) => {
    setCanEditResponsible(bool);
  };

  const toggleCanEditContact = (bool) => {
    setCanEditContact(bool);
  };

  const toggleCanEditOperator = (bool) => {
    setCanEditOperator(bool);
  };

  const getNomeFantasia = () => {
    if (
      document.getElementById("nomeFantasia") &&
      document.getElementById("nomeFantasia").value &&
      document.getElementById("nomeFantasia").value !== "-"
    )
      return document.getElementById("nomeFantasia").value;
    return null;
  };

  const getNomeResponsavel = () => {
    if (
      document.getElementById("nomeResponsavel") &&
      document.getElementById("nomeResponsavel").value &&
      document.getElementById("nomeResponsavel").value !== "-"
    )
      return document.getElementById("nomeResponsavel").value;
    return null;
  };

  const getCpfResponsavel = () => {
    if (
      document.getElementById("cpfResponsavel") &&
      document.getElementById("cpfResponsavel").value
    )
      return document.getElementById("cpfResponsavel").value.replace(/\D/g, "");
    return null;
  };

  const getCargoResponsavel = () => {
    if (
      document.getElementById("cargo") &&
      document.getElementById("cargo").value &&
      document.getElementById("cargo").value !== "-"
    )
      return document.getElementById("cargo").value;
    return null;
  };

  const getTelefoneResponsavel = () => {
    if (
      document.getElementById("numeroTelefone") &&
      document.getElementById("numeroTelefone").value &&
      document.getElementById("numeroTelefone").value !== "-"
    )
      return document.getElementById("numeroTelefone").value.replace(/\D/g, "");
    return null;
  };

  const getEmailResponsavel = () => {
    if (
      document.getElementById("emailResponsavel") &&
      document.getElementById("emailResponsavel").value &&
      document.getElementById("emailResponsavel").value !== "-"
    )
      return document.getElementById("emailResponsavel").value;
    return null;
  };

  const getNomeContato = () => {
    if (
      document.getElementById("nomeContato") &&
      document.getElementById("nomeContato").value &&
      document.getElementById("nomeContato").value !== "-"
    )
      return document.getElementById("nomeContato").value;
    return null;
  };

  const getTelefoneContato = () => {
    if (
      document.getElementById("numeroTelefoneContato") &&
      document.getElementById("numeroTelefoneContato").value &&
      document.getElementById("numeroTelefoneContato").value !== "-"
    )
      return document
        .getElementById("numeroTelefoneContato")
        .value.replace(/\D/g, "");
    return null;
  };

  const getCargoContato = () => {
    if (
      document.getElementById("descricaCargoContato") &&
      document.getElementById("descricaCargoContato").value &&
      document.getElementById("descricaCargoContato").value !== "-"
    )
      return document.getElementById("descricaCargoContato").value;
    return null;
  };

  const getEmailContato = () => {
    if (
      document.getElementById("emailContato") &&
      document.getElementById("emailContato").value &&
      document.getElementById("emailContato").value !== "-"
    )
      return document.getElementById("emailContato").value;
    return null;
  };

  const getResponsavelAllCompleted = () => {
    props.handleDisabled(
      [
        responsavelCompleted,
        contatoCompleted,
        responsavelPreenchimentoCompleted,
      ].every((e) => e === false)
    );
    if (
      getNomeResponsavel() &&
      getCpfResponsavel() &&
      getCargoResponsavel() &&
      getTelefoneResponsavel() &&
      getEmailResponsavel()
    ) {
      setResponsavelCompleted(true);
    } else {
      setResponsavelCompleted(false);
    }
  };

  const getContatoAllCompleted = () => {
    props.handleDisabled(
      [
        responsavelCompleted,
        contatoCompleted,
        responsavelPreenchimentoCompleted,
      ].every((e) => e === false)
    );
    if (
      getNomeContato() &&
      getCargoContato() &&
      getTelefoneContato() &&
      getEmailContato()
    ) {
      setContatoCompleted(true);
    } else {
      setContatoCompleted(false);
    }
  };

  const getNomeResponsavelPreenchimento = () => {
    if (
      document.getElementById("nomeResponsavelPreenchimento") &&
      document.getElementById("nomeResponsavelPreenchimento").value &&
      document.getElementById("nomeResponsavelPreenchimento").value !== "-"
    )
      return document.getElementById("nomeResponsavelPreenchimento").value;
    return null;
  };

  const getCpfResponsavelPreenchimento = () => {
    if (
      document.getElementById("cpfResponsavelPreenchimento") &&
      document.getElementById("cpfResponsavelPreenchimento").value &&
      document.getElementById("cpfResponsavelPreenchimento").value !== "-"
    )
      return document.getElementById("cpfResponsavelPreenchimento").value;
    return null;
  };

  const getResponsavelPreenchimentoAllCompleted = () => {
    props.handleDisabled(
      [
        responsavelCompleted,
        contatoCompleted,
        responsavelPreenchimentoCompleted,
      ].every((e) => e === false)
    );
    if (getNomeResponsavelPreenchimento() && getCpfResponsavelPreenchimento()) {
      setResponsavelPreenchimentoCompleted(true);
    } else {
      setResponsavelPreenchimentoCompleted(false);
    }
  };

  const renderInput = ({ input, label, maskType }) => {
    var mask;
    if (maskType) {
      if (maskType === "telefone") {
        mask = "(11) 11111-1111";
        if (input.value.length < 11) {
          mask = "(11) 1111-1111";
        }
      }
      if (maskType === "cep") {
        mask = "11111-111";
      }
      if (maskType === "cnpj") {
        mask = "11.111.111/1111-11";
      }
      if (maskType === "cnae") {
        mask = "1111-1/11";
      }
      if (maskType === "cpf") {
        mask = "111.111.111-11";
      }
    }
    return (
      <Form.Item label={label}>
        {mask ? (
          <MaskedInput mask={mask} {...input} id={input.name} disabled />
        ) : input.value === "" ? (
          <Input mask={mask} value="-" id={input.name} disabled />
        ) : (
          <Input mask={mask} {...input} id={input.name} disabled />
        )}
      </Form.Item>
    );
  };

  const renderEditableInput = ({ input, label }) => {
    return (
      <Form.Item label={label}>
        <Input
          {...input}
          id={input.name}
          addonAfter={
            <Button
              onClick={() => setCanEdit(!canEdit)}
              className="edit-button"
            >
              <FormOutlined />
            </Button>
          }
          disabled={!canEdit}
        />
      </Form.Item>
    );
  };

  const renderResponsibleInput = ({ input, label, maskType }) => {
    let mask;
    if (maskType) {
      if (maskType === "telefone") {
        mask = "(11) 11111-1111";
      }
      if (maskType === "cep") {
        mask = "11111-111";
      }
      if (maskType === "cnpj") {
        mask = "11.111.111/1111-11";
      }
      if (maskType === "cnae") {
        mask = "1111-1/11";
      }
      if (maskType === "cpf") {
        mask = "111.111.111-11";
      }
    }

    return (
      <Form.Item label={label}>
        {mask ? (
          <MaskedInput
            mask={mask}
            id={input.name}
            disabled={canEditResponsible}
            defaultValue={input.value ? input.value : ""}
          />
        ) : input.value === "" ? (
          <Input id={input.name} disabled={canEditResponsible} />
        ) : (
          <Input {...input} id={input.name} disabled={canEditResponsible} />
        )}
      </Form.Item>
    );
  };

  const renderOperatorInput = ({ input, label, maskType }) => {
    var mask = null;
    if (maskType) {
      if (maskType === "telefone") {
        mask = "(11) 11111-1111";
      }
      if (maskType === "cep") {
        mask = "11111-111";
      }
      if (maskType === "cnpj") {
        mask = "11.111.111/1111-11";
      }
      if (maskType === "cnae") {
        mask = "1111-1/11";
      }
      if (maskType === "cpf") {
        mask = "111.111.111-11";
      }
    }
    return (
      <Form.Item label={label}>
        {mask ? (
          <MaskedInput
            mask={mask}
            id={input.name}
            disabled={!canEditOperator}
            defaultValue={input.value ? input.value : ""}
          />
        ) : input.value === "" ? (
          <Input id={input.name} disabled={!canEditOperator} />
        ) : (
          <Input {...input} id={input.name} disabled={!canEditOperator} />
        )}
      </Form.Item>
    );
  };

  const renderContactInput = ({ input, label, maskType }) => {
    var mask;
    if (maskType) {
      if (maskType === "telefone") {
        mask = "(11) 11111-1111";
      }
      if (maskType === "cep") {
        mask = "11111-111";
      }
      if (maskType === "cnpj") {
        mask = "11.111.111/1111-11";
      }
      if (maskType === "cnae") {
        mask = "1111-1/11";
      }
      if (maskType === "cpf") {
        mask = "111.111.111-11";
      }
    }
    return (
      <Form.Item label={label}>
        {mask ? (
          <MaskedInput
            mask={mask}
            id={input.name}
            disabled={!canEditContact}
            defaultValue={input.value ? input.value : ""}
          />
        ) : input.value === "" ? (
          <Input id={input.name} disabled={!canEditContact} />
        ) : (
          <Input {...input} id={input.name} disabled={!canEditContact} />
        )}
      </Form.Item>
    );
  };

  // const renderAdditionalAccessInput = ({ input, label, maskType }) => {
  //   var mask = null;
  //   if (maskType) {
  //     if (maskType === "telefone") {
  //       if (input.value.length === 11) {
  //         mask = "(11) 11111-1111";
  //       } else {
  //         mask = "(11) 1111-1111";
  //       }
  //     }
  //     if (maskType === "cep") {
  //       mask = "11111-111";
  //     }
  //     if (maskType === "cnpj") {
  //       mask = "11.111.111/1111-11";
  //     }
  //     if (maskType === "cnae") {
  //       mask = "1111-1/11";
  //     }
  //     if (maskType === "cpf") {
  //       mask = "111.111.111-11";
  //     }
  //   }
  //   return (
  //     <Form.Item label={label}>
  //       {mask ? (
  //         <MaskedInput
  //           mask={mask}
  //           {...input}
  //           id={input.name}
  //           disabled={!canAddAccess}
  //         />
  //       ) : input.value === "" ? (
  //         <Input
  //           mask={mask}
  //           defaultValue="-"
  //           id={input.name}
  //           disabled={!canAddAccess}
  //         />
  //       ) : (
  //         <Input
  //           mask={mask}
  //           {...input}
  //           id={input.name}
  //           disabled={!canAddAccess}
  //         />
  //       )}
  //     </Form.Item>
  //   );
  // };

  return (
    <>
      <Form className="company-info-form" form={form}>
        <h4>Dados da Empresa</h4>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <RightCircleOutlined rotate={isActive ? 90 : 0} />
          )}
          expandIconPosition="right"
        >
          <Panel header="Dados gerais" key="1">
            <div className="form-section">
              <Field
                name="nomeFantasia"
                label="Nome fantasia"
                component={renderEditableInput}
              />

              <Field
                name="razaoSocial"
                label="Razão social"
                component={renderInput}
              />

              <Row gutter={24}>
                <Col span={12}>
                  <Field
                    name="cnpj"
                    label="CNPJ"
                    maskType="cnpj"
                    component={renderInput}
                  />
                </Col>
                <Col span={6}>
                  <Field
                    name="grauDeRisco"
                    label="Grau de risco"
                    component={renderInput}
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <Field
                    name="porteEmpresa"
                    label="Porte da empresa"
                    // format={(value) =>
                    //   porteEmpresaDictionary[value.toLowerCase()]
                    // }
                    component={renderInput}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    name="empresaCnaes"
                    label="Atividade principal"
                    component={renderInput}
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <Field
                    name="cnae"
                    label="CNAE"
                    maskType="cnae"
                    component={renderInput}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    name="numeroInscricaoEstadual"
                    label="Inscrição estadual"
                    component={renderInput}
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <Field
                    name="numeroInscricaoMunicipal"
                    label="Inscrição municipal"
                    component={renderInput}
                  />
                </Col>
              </Row>
              {canEdit ? (
                <div className="save-button-div">
                  <Button
                    className="save-button"
                    onClick={() => {
                      setCanEdit(false);
                      saveNomeFantasia(props.dispatch, getNomeFantasia());
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </Panel>

          <Panel header="Endereço" key="2">
            <div className="form-section">
              <Row gutter={24}>
                <Col span={24}>
                  <Field
                    name="descEndereco"
                    label="Endereço"
                    component={renderInput}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Field name="bairro" label="Bairro" component={renderInput} />
                </Col>
                <Col span={12}>
                  <Field name="cidade" label="Cidade" component={renderInput} />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Field name="uf" label="UF" component={renderInput} />
                </Col>
                <Col span={12}>
                  <Field
                    name="cep"
                    maskType="cep"
                    label="CEP"
                    component={renderInput}
                  />
                </Col>
              </Row>
            </div>
          </Panel>

          <Panel
            header="Responsável pela empresa"
            extra={!responsavelCompleted ? <Tag>Preencher</Tag> : null}
            key="3"
            forceRender={true}
          >
            <div className="form-section">
              <Tabs activeKey={currentTab}>
                <TabPane tab="" key="finish-register-responsible">
                  <div>
                    <Row gutter={24}>
                      <Col span={24}>
                        <Field
                          name="nomeResponsavel"
                          label="Nome"
                          component={renderResponsibleInput}
                        />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Field
                          name="cpfResponsavel"
                          label="CPF"
                          maskType="cpf"
                          component={renderResponsibleInput}
                        />
                      </Col>
                      <Col span={12}>
                        <Field
                          name="cargo"
                          label="Cargo"
                          component={renderResponsibleInput}
                        />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Field
                          name="numeroTelefone"
                          label="Telefone"
                          maskType="telefone"
                          component={renderResponsibleInput}
                        />
                      </Col>
                      <Col span={12}>
                        <Field
                          name="emailResponsavel"
                          label="E-mail"
                          component={renderResponsibleInput}
                        />
                      </Col>
                    </Row>

                    <div className="finish-register-button-div">
                      <Button
                        className="finish-register-button"
                        onClick={() => {
                          saveResponsavelEmpresa(
                            props.dispatch,
                            getNomeResponsavel(),
                            getCargoResponsavel(),
                            getCpfResponsavel(),
                            getTelefoneResponsavel(),
                            getEmailResponsavel(),
                            props.company
                          );
                          setCanEditResponsible(!canEditResponsible);
                        }}
                      >
                        <UserAddOutlined /> Finalizar cadastro
                      </Button>
                    </div>
                  </div>
                </TabPane>

                <TabPane tab="" key="edit-responsible">
                  <div>
                    {canEditResponsible ? (
                      <div>
                        <Button
                          onClick={() => {
                            setCanEditResponsible(!canEditResponsible);
                          }}
                        >
                          <FormOutlined /> Editar responsável pela empresa
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}

                    <Row gutter={24}>
                      <Col span={24}>
                        <Field
                          name="nomeResponsavel"
                          label="Nome"
                          component={renderResponsibleInput}
                        />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Field
                          name="cpfResponsavel"
                          label="CPF"
                          maskType="cpf"
                          component={renderResponsibleInput}
                        />
                      </Col>
                      <Col span={12}>
                        <Field
                          name="cargo"
                          label="Cargo"
                          component={renderResponsibleInput}
                        />
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col span={12}>
                        <Field
                          name="numeroTelefone"
                          label="Telefone"
                          maskType="telefone"
                          component={renderResponsibleInput}
                        />
                      </Col>
                      <Col span={12}>
                        <Field
                          name="emailResponsavel"
                          label="E-mail"
                          component={renderResponsibleInput}
                        />
                      </Col>
                    </Row>
                    {!canEditResponsible ? (
                      <div className="save-button-div">
                        <Button
                          className="save-button"
                          onClick={() => {
                            saveResponsavelEmpresa(
                              props.dispatch,
                              {
                                nome: getNomeResponsavel(),
                                cargo: getCargoResponsavel(),
                                cpf: getCpfResponsavel(),
                                telefone: getTelefoneResponsavel(),
                                email: getEmailResponsavel(),
                              },
                              toggleCanEditResponsible,
                              props.company
                            );
                          }}
                        >
                          Salvar
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Panel>

          <Panel
            header="Contato com a empresa"
            extra={!contatoCompleted ? <Tag>Preencher</Tag> : null}
            key="4"
            forceRender={true}
          >
            <div className="form-section">
              {!canEditContact ? (
                <div>
                  <Button onClick={() => setCanEditContact(true)}>
                    <FormOutlined /> Editar contato com a empresa
                  </Button>
                </div>
              ) : (
                ""
              )}
              <Row gutter={24}>
                <Col span={18}>
                  <Field
                    name="nomeContato"
                    label="Nome"
                    component={renderContactInput}
                  />
                </Col>
                <Col span={6}>
                  <Field
                    name="numeroTelefoneContato"
                    label="Telefone"
                    maskType="telefone"
                    component={renderContactInput}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Field
                    name="descricaCargoContato"
                    label="Cargo"
                    component={renderContactInput}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    name="emailContato"
                    label="E-mail"
                    component={renderContactInput}
                  />
                </Col>
              </Row>
              {canEditContact ? (
                <div className="save-button-div">
                  <Button
                    className="save-button"
                    onClick={() => {
                      saveContatoEmpresa(
                        props.dispatch,
                        {
                          nome: getNomeContato(),
                          cargo: getCargoContato(),
                          telefone: getTelefoneContato(),
                          email: getEmailContato(),
                        },
                        toggleCanEditContact
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </Panel>

          <Panel
            header="Responsável pelo preenchimento (operador)"
            extra={
              !responsavelPreenchimentoCompleted ? <Tag>Preencher</Tag> : null
            }
            key="5"
            forceRender={true}
          >
            <div className="form-section">
              {!canEditOperator ? (
                <div>
                  <Button onClick={() => setCanEditOperator(true)}>
                    <FormOutlined /> Editar responsável pela empresa
                  </Button>
                </div>
              ) : (
                ""
              )}
              <Row gutter={24}>
                <Col span={18}>
                  <Field
                    name="nomeResponsavelPreenchimento"
                    label="Nome"
                    component={renderOperatorInput}
                  />
                </Col>
                <Col span={6}>
                  <Field
                    name="cpfResponsavelPreenchimento"
                    label="CPF"
                    maskType="cpf"
                    component={renderOperatorInput}
                  />
                </Col>
              </Row>
              {canEditOperator ? (
                <div className="save-button-div">
                  <Button
                    className="save-button"
                    onClick={() => {
                      saveResponsavelPreenchimentoEmpresa(
                        props.dispatch,
                        {
                          nome: getNomeResponsavelPreenchimento(),
                          cpf: getCpfResponsavelPreenchimento(),
                        },
                        toggleCanEditOperator
                      );
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </Panel>

          {/* <Panel
            header="Acesso adicional ao sistema"
            extra={<Tag>Preencher</Tag>}
            key="6"
          >
            <div className="form-section">
              <Row gutter={24}>
                <Col span={24}>
                  <p>
                    Além de você, este usuário poderá acessar o sistema através
                    do CPF e da senha cadastrados abaixo.
                  </p>
                </Col>
              </Row>
              <Tabs activeKey={additionalAccessTab}>
                <TabPane tab="" key="additional-access-button">
                  <Button
                    onClick={() => {
                      setCanAddAccess(true);
                      setAdditionalAccessTab("additional-access-form");
                    }}
                  >
                    <UserAddOutlined /> Adicionar acesso ao sistema
                  </Button>
                </TabPane>
                <TabPane tab="" key="additional-access-form">
                  <Row gutter={24}>
                    <Col span={24}>
                      <Field
                        name="nomeAdicional"
                        label="Nome"
                        component={renderAdditionalAccessInput}
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Field
                        name="cpfAdicional"
                        label="CPF"
                        component={renderAdditionalAccessInput}
                      />
                    </Col>

                    <Col span={12}>
                      <Field
                        name="emailAdicional"
                        label="Email"
                        component={renderAdditionalAccessInput}
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Field
                        name="senhaAdicional"
                        label="Senha"
                        component={renderAdditionalAccessInput}
                      />
                      <h6>
                        A senha deve conter no mínimo 8 caracteres, dentre eles
                        no mínimo um caractere especial e uma letra maiúscula
                      </h6>
                    </Col>

                    <Col span={12}>
                      <Field
                        name="confirmarSenhaAdicional"
                        label="Confirmar senha"
                        component={renderAdditionalAccessInput}
                      />
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <h4>Campo obrigatório*</h4>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      {!hasAddedAccess ? (
                        <div className="save-button-div">
                          <Button
                            className="save-button"
                            onClick={() => {
                              setHasAddedAccess(true);
                              setCanAddAccess(!canAddAccess);
                            }}
                          >
                            Salvar
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </div>
          </Panel> */}
        </Collapse>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  //descricao, logo, anexos
  const company = state.caracterizacao
    ? state.caracterizacao.companySelected
    : {};
  const {
    cargo,
    cnpj,
    descricaCargoContato,
    emailContato,
    emailResponsavel,
    cnae,
    endereco,
    nomeContato,
    nomeFantasia,
    nomeResponsavel,
    numeroInscricaoEstadual,
    numeroInscricaoMunicipal,
    numeroTelefone,
    numeroTelefoneContato,
    porteEmpresa,
    razaoSocial,
    cpfResponsavel,
  } = company;
  const { bairro, cep, cidade, telefone, uf } = endereco ? endereco : {};
  const descEndereco = endereco ? endereco.endereco : "";
  const { grauDeRisco, atividadePrincipal, numero } = cnae ? cnae : {};
  var cpf = state.user.userInfo.cpf;
  var nomeResponsavelLogged = state.user.userInfo.nome;
  return {
    company: company,
    initialValues: {
      razaoSocial,
      porteEmpresa,
      nomeContato,
      nomeFantasia,
      nomeResponsavel,
      cpfResponsavel,
      numeroInscricaoEstadual,
      numeroInscricaoMunicipal,
      numeroTelefone,
      numeroTelefoneContato,
      empresaCnaes: atividadePrincipal,
      cnae: numero,
      grauDeRisco,
      cargo,
      cnpj,
      descricaCargoContato,
      emailContato,
      emailResponsavel,
      bairro,
      cep,
      cidade,
      descEndereco,
      telefone,
      uf,
      nomeResponsavelPreenchimento: nomeResponsavelLogged,
      cpfResponsavelPreenchimento: cpf,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        saveNomeFantasia,
        saveResponsavelEmpresa,
        saveContatoEmpresa,
        saveResponsavelPreenchimentoEmpresa,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "companyInformationForm",
    destroyOnUnmount: false,
    enableReinitialize: true,
  })(CompanyInformationForm)
);
