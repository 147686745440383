import {
  EllipsisOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  List,
  Menu,
  Row,
  Select,
  Tag,
  Upload,
} from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IconSESI from "../../../../components/IconsSESI";
import {
  alterarCertificado,
  deleteCertificado,
  listarCertificados,
  postCertificado,
} from "../actions";

const ESocialSignatures = (props) => {
  const [form] = Form.useForm();
  const [isDefault, setIsDefault] = useState(false);
  const [fileState, setFileState] = useState([]);
  const [certificadosCadastrados, setCertificadosCadastrados] = useState([]);
  const CryptoJS = require("crypto-js");
  const { Option } = Select;
  const premiumCompanies = props.companies.filter((company) => company.premium);

  useEffect(() => {
    listarCertificados(props.dispatch);
  }, [JSON.stringify(props.certificadosCadastrados)]);

  const onChange = () => {
    setIsDefault(!isDefault);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const senha = values.senhaCertificado;
      const secret = "NdRgUkXp2s5v8y/B";
      let encrypted = CryptoJS.AES.encrypt(senha, secret).toString();
      let formData = new FormData();
      values.empresas.map((empresa) => formData.append("cnpjs", empresa));
      formData.append(
        "certificado",
        values.arquivoCertificado.fileList[0].originFileObj
      );
      formData.append("senha", encrypted);
      formData.append("isDefault", isDefault === true ? 1 : 0);
      postCertificado(formData, props.dispatch);
    });
  };

  const handleDelete = (id) => {
    deleteCertificado(id, props.dispatch);
  };

  const handleDefault = (id, certificado) => {
    let newBody = {
      id: certificado.id,
      url: certificado.url,
      nomeArquivo: certificado.nomeArquivo,
      isDefault: 1,
    };
    alterarCertificado(id, newBody, props.dispatch);
  };

  const menu = (certificado) => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => handleDelete(certificado.id)}>
          <IconSESI type="trash" /> Excluir
        </Menu.Item>
        {certificado.default ? (
          ""
        ) : (
          <Menu.Item
            key="2"
            onClick={() => handleDefault(certificado.id, certificado)}
          >
            <IconSESI type="checklist" />
            Definir como padrão
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <React.Fragment>
      <Row gutter={36}>
        <Col lg={24} md={36} className="esocial-dashboard">
          <div className="header-form">
            <h2>Gerenciar assinaturas digitais</h2>
          </div>
          <div className="content">
            <Form form={form} onFinish={handleSubmit}>
              <div className="esocial-card-signature">
                <Form.Item label="Empresa::" name="empresas">
                  <Select mode="tags">
                    {premiumCompanies.map((company) => (
                      <Option key={company.cnpj} value={company.cnpj}>
                        {company.razaoSocial}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="arquivoCertificado">
                  <Upload
                    accept=".pfx,.p12"
                    beforeUpload={() => {
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      Enviar certificado
                    </Button>
                  </Upload>
                </Form.Item>
                <Form.Item label="Senha" name="senhaCertificado">
                  <Input.Password
                    placeholder="digite a senha do seu certificado"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item name="isDefault">
                  <Checkbox onChange={onChange}>
                    Definir como assinatura padrão.
                  </Checkbox>
                </Form.Item>
                <div className="esocial-signature-btn-div">
                  <Button htmlType="submit">Salvar</Button>
                </div>
              </div>
            </Form>
            <div>
              <List>
                {props.certificadosCadastrados
                  ? props.certificadosCadastrados.map((certificado) => {
                      return (
                        <List.Item>
                          <div>
                            <span>
                              <h3>{certificado.nomeArquivo}</h3>
                              {certificado.default ? (
                                <Tag>Assinatura Padrão</Tag>
                              ) : (
                                ""
                              )}
                              <h5>Empresas:</h5>
                              <Tag>{certificado.nomeFantasia}</Tag>
                            </span>
                          </div>
                          <Dropdown
                            overlay={menu(certificado)}
                            trigger={["click"]}
                          >
                            <Button>
                              <EllipsisOutlined />
                            </Button>
                          </Dropdown>
                        </List.Item>
                      );
                    })
                  : ""}
              </List>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    certificadosCadastrados: state.esocial.certificadosCadastrados
      ? state.esocial.certificadosCadastrados
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        listarCertificados,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ESocialSignatures);
