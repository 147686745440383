export const LISTAR_CATEGORIAS = "LISTAR_CATEGORIAS";
export const LISTAR_EXAMES = "LISTAR_EXAMES";
export const LISTAR_FUNCIONARIOS_ESOCIAL = "LISTAR_FUNCIONARIOS_ESOCIAL";
export const ENVIAR_MONITORAMENTO = "ENVIAR_MONITORAMENTO";
export const SALVAR_ASSINATURA = "SALVAR_ASSINATURA";
export const LISTAR_ASSINATURAS = "LISTAR_ASSINATURAS";
export const DELETAR_ASSINATURA = "DELETAR_ASSINATURA";
export const ALTERAR_ASSINATURA = "ALTERAR_ASSINATURA";
export const LISTAR_EVENTOS = "LISTAR_EVENTOS";
export const GERAR_DOCUMENTO = "GERAR_DOCUMENTO";
