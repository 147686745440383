import { Col, Row, Button } from "antd";

import React from "react";

import Assistant from "../../../../components/VirtualAssistant";
import { ESocialTalks } from "../../../../constants/aroldoTalks";

const ESocialBanner = (props) => {
  return (
    <div className="esocial-banner-wrapper">
      <Row gutter={24}>
        <Col span={24}>
          <Assistant talk={ESocialTalks[0]} />
        </Col>
      </Row>

      <div className="action">
        <Button>
          <h3>Assinar PGR premium</h3>
        </Button>
      </div>
    </div>
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     dispatch,
//     ...bindActionCreators({
//       listQuestions,
//     }),
//   };
// };

export default ESocialBanner;
