import {
  fatorDeRiscoEditURL,
  incluirFuncionarios,
} from "../../../../../../../constants";
import axios from "axios";
import { setupInterceptors } from "../../../../../../../auth/SetupInterceptors";
import openNotification from "../../../../../../../components/notifications";
import { getInventarioDeRisco } from "../../../../../../InventarioRiscos/_/actions";
setupInterceptors();

export const addRealizadorProcessos = (
  realizadorProcessos,
  loading,
  handleOpenedPanel,
  dispatch
) => {
  loading(true);
  axios
    .post(`${incluirFuncionarios}`, realizadorProcessos)
    .then(() => {
      openNotification(
        "Trabalhador associado ao processo com sucesso!",
        "",
        "success"
      );
      getInventarioDeRisco(dispatch);
      loading(false);
      handleOpenedPanel(realizadorProcessos.processoProdutivoId);
    })
    .catch(() => {
      openNotification(
        "Erro ao associar trabalhador ao processo!",
        "Tente novamente",
        "error"
      );
      loading(false);
    });
};

const updateRisco = (id, frequency, time) => {
  const values = {
    frequencia: parseInt(frequency),
    tempoDeExposicao: parseInt(time),
    percepcaoIntensidade: 2,
    acidenteRegistrado: 1,
    epcs: [],
    epis: [],
    maos: [],
  };
  return axios.patch(fatorDeRiscoEditURL(id), values);
};
