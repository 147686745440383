import React from "react";
import "./style.scss";
import { Form, Input, Modal, Tooltip } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { confirm } = Modal;

const dictionary = {
  descricao: "descrição",
  funcionario: "trabalhador",
  maquina: "máquina",
  quimico: "químico",
  observaca: "observação",
  acao: "ação",
};

const nivelDeRiscoPrazos = {
  cítico: "3 meses",
  alto: "9 meses",
  médio: "14 meses",
  baixo: "24 meses",
};

const showModal = (error) => {
  //FUNCIONAMENTO DO TRATAMENTO DA STRING
  // Pega só o texto entre [], quando não há, como no erro do técnico, a última letra é cortada.
  // Depois todas as letras viram minúsculas e os _ são substituidos por espaços.
  // Por fim, tudo que não é letra, vírgula ou dois pontos também vira espaço (exitem números em alguns erros).
  let numberOfErrors = (error.match(/,/g) || []).length + 1;
  let consta = numberOfErrors > 1 ? "constam" : "consta";
  let errorText = error
    .slice(error.indexOf("[") + 1, error.indexOf("]"))
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/[^a-zA-Z,:áéíóêõãç]/g, " ");
  const keys = Object.keys(dictionary);
  const values = Object.values(dictionary);
  let newError;
  for (var i = 0; i < keys.length; i++) {
    newError = errorText.replace(keys[i], values[i]);
    errorText = newError;
  }
  let newErrorArray = newError.split(",");

  confirm({
    content: (
      <React.Fragment>
        <p>
          Ops, percebi que estão faltando algumas informações. Aqui {consta}:
        </p>
        {newErrorArray.map((error) => {
          return (
            <ul>
              <li className="error">
                <h5>{error}</h5>
              </li>
            </ul>
          );
        })}
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    className: "hideCancelButton",
  });
};

export const showModalIphone = () => {
  confirm({
    content: (
      <React.Fragment>
        <p>
          Ops! Para usuários de iPhone, é necessário ajustar algumas
          configurações.
        </p>
        <p>
          Vá em Ajustes {">"} Safari {">"} Privacidade e Segurança e desabilite
          a opção 'Impedir Rastreamento'.
        </p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
  });
};

export const showModalPrimeirosPassos = (texto) => {
  confirm({
    content: (
      <React.Fragment>
        {texto.map((paragraph, i) => {
          return <p key={i}>{paragraph}</p>;
        })}
      </React.Fragment>
    ),
    style: { marginTop: "-4rem" },
    className: "hideCancelButton",
  });
};

export const showModalTrabDesativado = () => {
  confirm({
    content: (
      <React.Fragment>
        <p>
          Ao inativar um trabalhador por desligamento é necessário inserir o
          Atestado de Saúde Ocupacional (ASO) de demissão.
        </p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    className: "hideCancelButton",
  });
};

export const showRemoveSuggestedProcessModal = (onOkFunction) => {
  confirm({
    content: (
      <React.Fragment>
        <h1>Você tem certeza?</h1>
        <p>Esta ação removerá este processo permanentemente.</p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    okText: (
      <>
        <CheckOutlined />
        REMOVER PROCESSO
      </>
    ),
    cancelText: (
      <>
        <CloseOutlined />
        CANCELAR
      </>
    ),
    className: "showIcons",
    onOk: onOkFunction,
  });
};

export const showRemoveRiskModal = (onOkFunction) => {
  confirm({
    content: (
      <React.Fragment>
        <h1>Você tem certeza?</h1>
        <p>Esta ação removerá esse risco permanentemente.</p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    okText: (
      <>
        <CheckOutlined />
        REMOVER RISCO
      </>
    ),
    cancelText: (
      <>
        <CloseOutlined />
        CANCELAR
      </>
    ),
    className: "showIcons",
    onOk: onOkFunction,
  });
};

export const showModalEmpresaNaoEfetivada = () => {
  confirm({
    content: (
      <React.Fragment>
        <p>
          <a
            style={{ textDecoration: "underline" }}
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_LIFERAY_URL}web/loja/acessos-ao-sistema`}
          >
            Clique aqui
          </a>{" "}
          para adicionar as informações faltantes da empresa. Será preciso
          deslogar e logar novamente após o preenchimento.
        </p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    className: "hideCancelButton",
  });
};

export const showAlterarDRDeEmpresaEmAnalise = () => {
  confirm({
    content: (
      <React.Fragment>
        <p>
          Atenção! Ao modificar essa análise, o analista atual irá perder a
          análise.
        </p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    className: "hideCancelButton",
  });
};

export const showPlanoDeAcaoVazio = () => {
  confirm({
    content: (
      <React.Fragment>
        <p>Você não tem nenhuma ação planejada para esse risco!</p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    className: "hideCancelButton",
  });
};

export const showPlanoDeAcaoDataInvalida = (nivelDeRisco) => {
  confirm({
    content: (
      <React.Fragment>
        <p>
          Você não tem nenhuma ação planejada até o prazo recomendado para o
          nível de risco mais alto desse perigo! É recomendado ter ao mesmo uma
          ação que finalize em até{" "}
          <span>{nivelDeRiscoPrazos[nivelDeRisco]}</span> .
        </p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    className: "hideCancelButton",
  });
};

export const showCNAESemDados = () => {
  confirm({
    content: (
      <React.Fragment>
        <p>
          Ainda não existem dados cadastrados sobre processos e perigos para o
          seu CNAE. Volte em breve para concluir seu PGR ou insira as
          informações manualmente.
        </p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    className: "hideCancelButton",
  });
};

export const showFinishQuestionQuestionario = (onOkFunction) => {
  confirm({
    content: (
      <React.Fragment>
        <h3>{`Confirma suas respostas?`}</h3>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    okText: (
      <>
        <CheckOutlined />
        CONFIRMO
      </>
    ),
    cancelText: (
      <>
        <CloseOutlined />
        CANCELAR
      </>
    ),
    className: "showIcons",
    onOk: onOkFunction,
  });
};

export const showFinishAutodeclaracaoQuestionario = (onOkFunction) => {
  confirm({
    content: (
      <React.Fragment>
        <h3>
          Confirma que sua empresa não possui fatores de risco biológicos?
        </h3>
        <p>Dos apresentados aqui e quaisquer outros perigos biológicos.</p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    okText: (
      <>
        <CheckOutlined />
        CONFIRMO
      </>
    ),
    cancelText: (
      <>
        <CloseOutlined />
        CANCELAR
      </>
    ),
    className: "showIcons",
    onOk: onOkFunction,
  });
};

export const showOptionToGoToPGR = (onOkFunction) => {
  confirm({
    content: (
      <React.Fragment>
        <p>
          Isso implica que sua empresa possui risco físico/químico/biológicos,
          portanto sua empresa terá que fazer o PGR. Confirma esssa resposta?
        </p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    okText: (
      <>
        <CheckOutlined />
        CONFIRMO
      </>
    ),
    cancelText: (
      <>
        <CloseOutlined />
        CANCELAR
      </>
    ),
    className: "showIcons",
    onOk: onOkFunction,
  });
};

export const showPGROrientations = () => {
  confirm({
    content: (
      <React.Fragment>
        <p>
          Seu PGR é composto pelo Inventário de riscos e pelo Plano de Ação. No
          documento gerado, você tem todas as orientações necessárias para ficar
          em dia com a legislação.
        </p>
        <p>
          No Inventário você vai ter todas as informações relativas à avaliação
          dos riscos de cada processo de sua empresa. Já no Plano de ação, foram
          listadas, por prioridades, as ações que devem ser executadas com o
          nome do responsável e o prazo para executar a ação. É importante que
          você acompanhe direitinho para não atrasar o seu cronograma.
        </p>
        <p>
          Lembre-se que, se algo mudar, ou um novo processo for implantado na
          sua empresa, você precisa revisar o seu PGR.
        </p>
        <p>
          Seguindo os passos abaixo, você estará alinhado com as normas de
          segurança do trabalho:
        </p>
        <p>
          1º Converse com as equipes enquadradas em cada processo apresentado no
          Inventário, para eles terem ciência dos perigos e as fontes geradoras
          que envolvem as suas atividades, assim como as medidas de controle,
          para que tudo ocorra bem! Esse momento é importante até mesmo para
          ouvi-los e confirmar todo esse levantamento. Para isso, e só dar uma
          olhada no item “Inventário de Riscos Ocupacionais” do seu PGR e
          procurar os respectivos processos que os seus trabalhadores estão
          envolvidos.
        </p>
        <p>
          2º Observe o cronograma do Plano de ação, com antecedência, para
          providenciar realizar as ações dentro do prazo previsto seguindo as
          prioridades definidas. Se seu Plano de Ação tiver previsão de
          avaliações ambientais, o SESI pode ajudá-lo com isso!
        </p>
        <p>
          3º Elabore e/ou atualize o{" "}
          <Tooltip
            title={
              <React.Fragment>
                <p>
                  Programa de Controle Médico de Saúde Ocupacional – PCMSO, que
                  é o programa que visa monitorar a saúde dos trabalhadores.
                </p>
                <p>
                  O PCMSO deve estar sob a responsabilidade de um médico do
                  trabalho e, define os exames, os tipos de exames e suas
                  periodicidades que os colaboradores devem realizar, além do
                  gerenciamento da saúde de toda a sua equipe, a partir dos
                  perigos e níveis de riscos apontados no PGR que você elaborou!
                  Após a realização das consultas e exames, deverá ser emitido o
                  ASO – Atestado de Saúde Ocupacional, que é o documento que
                  registra o monitoramento da saúde do trabalhador e a sua
                  aptidão para a realização das atividades. O ASO deve ser
                  realizado com base no PCMSO.{" "}
                </p>
              </React.Fragment>
            }
          >
            <span style={{ textDecoration: "underline" }}>PCMSO</span>
          </Tooltip>{" "}
          de sua empresa com base no seu PGR, o SESI pode ajudá-lo com isso!
        </p>
        <p>
          4º Com o{" "}
          <Tooltip
            title={
              <React.Fragment>
                <p>
                  Programa de Controle Médico de Saúde Ocupacional – PCMSO, que
                  é o programa que visa monitorar a saúde dos trabalhadores.
                </p>
                <p>
                  O PCMSO deve estar sob a responsabilidade de um médico do
                  trabalho e, define os exames, os tipos de exames e suas
                  periodicidades que os colaboradores devem realizar, além do
                  gerenciamento da saúde de toda a sua equipe, a partir dos
                  perigos e níveis de riscos apontados no PGR que você elaborou!
                  Após a realização das consultas e exames, deverá ser emitido o
                  ASO – Atestado de Saúde Ocupacional, que é o documento que
                  registra o monitoramento da saúde do trabalhador e a sua
                  aptidão para a realização das atividades. O ASO deve ser
                  realizado com base no PCMSO.{" "}
                </p>
              </React.Fragment>
            }
          >
            <span style={{ textDecoration: "underline" }}>PCMSO</span>
          </Tooltip>{" "}
          em mãos, providencie encaminhar seus trabalhadores para realização das
          consultas e exames previstos nele. O SESI também pode ajudá-lo com
          isso!
        </p>
        <p>
          5º Adicionalmente, a depender dos perigos que os seus trabalhadores
          estão expostos, podem ser necessários treinamentos obrigatórios pela
          legislação. Quer saber quais? O SESI também pode ajudá-lo com isso!
        </p>
        <p>
          Em breve o SESI entrará em contato, para lhe orientá-lo em como ficar
          em dia com esses requisitos e outros da legislação de segurança e
          saúde no trabalho.
        </p>
        <p>
          Para conhecer mais esses e outros serviços que o SESI oferta, visite o
          site:{" "}
          <a href="https://www.sesivivamais.com.br" target="_blank">
            sesivivamais.com.br
          </a>
        </p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    className: "hideCancelButton pgrOrientation",
  });
};

export const showRisksDefinitions = () => {
  confirm({
    content: (
      <React.Fragment>
        <p>
          Perigo ou fator de risco: agente físico, químico, biológico,
          ergonômico ou de acidente, presente no ambiente de trabalho, com o
          potencial de causar lesões ou agravos à saúde aos trabalhadores
          expostos (Exemplo: ruído, calor, substâncias químicas, radiação,
          queda, levantamento de peso). Fonte geradora: Fonte ou situação que
          gera o perigo. (Exemplo: Máquinas, matérias-primas, ferramentas,
          processos).
        </p>
        <p>
          Um produto químico é formado por uma substância, composto ou mistura,
          que lhe permitem cumprir com uma determina função, como por exemplo,
          colar, pintar, limpar, diluir, entre outros.
        </p>
        <p>
          Muitas empresas utilizam produtos químicos, como matérias-primas ou
          insumos em diversas atividades como, pintura, soldagem, fundição,
          tinturaria, atividades de limpeza, lubrificação etc.
        </p>
        <p>
          Os trabalhadores podem estar expostos a perigos químicos quando
          manuseiam ou estão em ambientes onde eles são manipulados. A exposição
          pode ocorrer por meio das vias respiratórias (gases, vapores,
          neblinas, névoas, fumaças e fumos) e pelo contato com a pele e olhos.
        </p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    className: "hideCancelButton pgrOrientation",
  });
};

export const alertNoControl = () => {
  confirm({
    content: (
      <React.Fragment>
        <p>
          Você não cadastrou nenhuma medida (MPC, EPI, ou MAO) para proteção do
          trabalhador. Tem certeza que a empresa não implementa nenhuma medida
          preventiva?
        </p>
      </React.Fragment>
    ),
    style: { marginTop: "-3rem" },
    className: "hideCancelButton",
  });
};

export default showModal;
// toLowerCase().replace(/_/g, ' ').split(',')
