import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button as ButtonAnt, Tabs } from "antd";
import {
  acceptProductiveProcess,
  addProcessoProdutivo,
  editProcessoProdutivo,
  fetchProcessosPorCNAE,
  fetchProcessos,
  fetchProcesso,
  deleteProductiveProcess,
  rejectProductiveProcess,
  selectProcessSuggestedByCNAE,
} from "../actions";
import ProcessoProdutivoAcceptedList from "../components/ProcessoProdutivoAcceptedList";
import ProcessoProdutivoCNAEList from "../components/ProcessoProdutivoCNAEList";
import Button from "../../../../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../../../../components/IconsSESI";
import ProcessoProdutivoFormContainer from "../components/ProcessoProdutivoFormContainer";
import { bindActionCreators } from "redux";
import FinishStep from "../../../../../../../components/FinishStep";
import { showCNAESemDados } from "../../../../../../../components/modals";
import { PROCESSOS_PRODUTIVOS } from "../../../../../../../constants/stepTypes";

const CaracterizacaoProcessosProdutivos = (props) => {
  const [currentTab, setCurrentTab] = useState("lista");
  const [mode, setMode] = useState("new");
  //const [viewOnly, setViewOnly] = useState(props.stepsEmpresa);
  const { TabPane } = Tabs;

  useEffect(() => {
    fetchProcessosPorCNAE(props.dispatch);
    fetchProcessos(props.dispatch);
    if (props.processosCNAE.length === 0) {
      showCNAESemDados();
    }
    props.onChangeTalk("talk1", 3);
  }, [
    JSON.stringify(props.processosProdutivosDisponiveis),
    JSON.stringify(props.processosProdutivos),
    JSON.stringify(props.processosCNAE),
  ]);

  const configAdd = (callback) => {
    const toggleTab = currentTab === "lista" ? "form" : "lista";
    setMode("new");
    setCurrentTab(toggleTab);
    props.onChangeTalk("talk2", 3);
    //setViewOnly(props.stepsEmpresa.SETORES);
    if (callback) callback();
  };

  const changeMode = (mode) => {
    setMode(mode);
  };

  const configEdit = (id) => {
    const toggleTab = currentTab === "lista" ? "form" : "lista";
    fetchProcesso(props.dispatch, id);
    setCurrentTab(toggleTab);
    setMode("edit");
  };

  const handleAcceptProcessByCNAE = (id, nome) => {
    const toggleTab = currentTab === "lista" ? "form" : "lista";
    selectProcessSuggestedByCNAE(props.dispatch, {
      processoProdutivoId: id,
      nome: nome,
      descricao: null,
    });
    setCurrentTab(toggleTab);
    setMode("accept");
  };

  useEffect(() => {
    if (props.stepsEmpresa.PROCESSOS_PRODUTIVOS) {
      props.onChangeTalk("talk3", 3);
    } else {
      props.onChangeTalk("talk1", 3);
    }
  }, [props.stepsEmpresa.PROCESSOS_PRODUTIVOS]);

  return (
    <Row gutter={32}>
      <Col lg={24} md={24}>
        <Tabs activeKey={currentTab}>
          <TabPane tab="" key="lista">
            <div className="container">
              <Row
                gutter={32}
                type="flex"
                justify="space-around"
                align="middle"
              >
                <Col lg={18} md={16}>
                  <div className="header-form">
                    <Button
                      shape="circle"
                      ghost
                      onClick={() => props.nextStep(props.current - 1)}
                      text={<IconSESI type="arrow-left" />}
                    />
                    <h2>Processos Produtivos</h2>
                  </div>
                </Col>
                <Col lg={6} md={8}>
                  <Button
                    onClick={() => props.nextStep(props.current + 1)}
                    type="primary"
                    text="PRÓXIMO PASSO"
                    className="next-step"
                    block
                  />
                </Col>
              </Row>
              <div className="content">
                {!props.stepsEmpresa.PROCESSOS_PRODUTIVOS ? (
                  <ButtonAnt ghost className="add" onClick={() => configAdd()}>
                    <IconSESI type="add-circle" theme="filled" />
                    Adicionar Processo Produtivo
                  </ButtonAnt>
                ) : null}
                <div className="processos-produtivos-wrapper">
                  {props.processos.length > 0 ? (
                    <h3>Processos produtivos da sua empresa</h3>
                  ) : null}
                  <ProcessoProdutivoAcceptedList
                    processos={props.processos}
                    edit={configEdit}
                    remove={deleteProductiveProcess}
                    mode={mode}
                    completed={props.stepsEmpresa.PROCESSOS_PRODUTIVOS}
                  />
                  {props.processosProdutivosDisponiveis.length > 0 ? (
                    <h3>
                      Processos produtivos sugeridos de acordo com o CNAE da sua
                      empresa
                    </h3>
                  ) : null}
                  <ProcessoProdutivoCNAEList
                    dispatch={props.dispatch}
                    acceptProductiveProcess={acceptProductiveProcess}
                    processosProdutivosDisponiveis={
                      props.processosProdutivosDisponiveis
                    }
                    rejectProductiveProcess={rejectProductiveProcess}
                    handleAcceptProcessByCNAE={handleAcceptProcessByCNAE}
                  />
                </div>

                <FinishStep
                  completed={props.stepsEmpresa.PROCESSOS_PRODUTIVOS}
                  url="processos-produtivos"
                  perfil={props.perfil}
                  step={PROCESSOS_PRODUTIVOS}
                />
              </div>
            </div>
          </TabPane>

          <TabPane tab="" key="form">
            <ProcessoProdutivoFormContainer
              tab={configAdd}
              dispatch={props.dispatch}
              fatoresDeRiscoDisponiveis={props.fatoresDeRiscoDisponiveis}
              cnae={props.cnae}
              addProcessoProdutivo={addProcessoProdutivo}
              editProcessoProdutivo={editProcessoProdutivo}
              mode={mode}
              changeMode={changeMode}
              processoProdSelecionado={props.processoProdSelecionado}
              onChangeTalk={props.onChangeTalk}
              processos={props.processos}
              acceptProductiveProcess={acceptProductiveProcess}
            />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const customizacoes = state.user.userInfo
    ? state.user.userInfo.customizacoes
    : {};
  return {
    erro: state.caracterizacao.setores.erro,
    processos: state.caracterizacao.processosProdutivos.processosProdutivos,
    fatoresDeRiscoDisponiveis:
      state.caracterizacao.processosProdutivos.fatoresDeRiscoDisponiveis,
    page: state.buttons.pageStepsSetores,
    perfil: state.user.userInfo.perfil,
    setoresCatalogo: state.caracterizacao.setores.setoresCatalogo,
    companySelected: state.caracterizacao.companySelected,
    setorSelecionado: state.caracterizacao.setores.setorSelecionado,
    setoresIndisponiveis: state.caracterizacao.setores.setoresIndisponiveis,
    stepsEmpresa: state.caracterizacao.stepsEmpresa.stepsEmpresa,
    cnae: state.caracterizacao.companySelected.cnae.numero,
    processosProdutivosDisponiveis:
      state.caracterizacao.processosProdutivos.processosProdutivosDisponiveis,
    customizacoes,
    processoProdSelecionado:
      state.caracterizacao.processosProdutivos.processoProdSelecionado,
    processosCNAE: state.caracterizacao.processosProdutivos.processosCNAE,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        acceptProductiveProcess,
        addProcessoProdutivo,
        fetchProcesso,
        editProcessoProdutivo,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaracterizacaoProcessosProdutivos);
