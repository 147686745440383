import React, { useEffect, useState } from "react";
import SearchBar from "./_/components/SearchBar";
import CompanyList from "./_/components/CompanyList";
import { connect } from "react-redux";
import { Spin } from "antd";
import { bindActionCreators } from "redux";
import { selectCompany } from "../CompanyList/_/containers/Companies/_/actions";

const SelectCompany = (props) => {
  //const [filter, setFilter] = useState("");
  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    if (props.companyList) {
      const uniqueCompanies = props.companyList.reduce((acc, obj) => {
        let exist = acc.find(({ cnpj }) => obj.cnpj === cnpj);
        if (!exist) {
          acc.push(obj);
        }
        return acc;
      }, []);
      const empresas = uniqueCompanies;
      setEmpresas(empresas);
    }
  }, [JSON.stringify(props.companyList)]);

  const filterList = (event) => {
    const value = event.target.value;
    if (value.length > 2) {
      const empresas = this.state.empresas;
      const filteredEmpresas = empresas.filter((empresa) => {
        if (empresa.empresa) {
          return (
            empresa.nomeFantasia.toLowerCase().indexOf(value.toLowerCase()) !==
            -1
          );
        } else {
          return (
            empresa.nomeFantasia.toLowerCase().indexOf(value.toLowerCase()) !==
            -1
          );
        }
      });
      setEmpresas(filteredEmpresas);
    } else {
      setEmpresas(Object.values(props.companyList).flat());
    }
  };

  if (!props.companyList) {
    return (
      <div className="spin">
        <Spin tip="Carregando..." />
      </div>
    );
  }

  return (
    <div>
      <h2>Selecionar empresa</h2>
      <SearchBar
        name={"Empresa"}
        filterList={filterList}
        dataSelect={empresas}
      />
      <CompanyList
        companies={empresas}
        dispatch={props.dispatch}
        selectCompanyAction={selectCompany}
        selectedCompany={props.selectedCompany}
      ></CompanyList>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companyList: state.user.companies,
    selectedCompany: state.caracterizacao.companySelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ selectCompany }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCompany);
