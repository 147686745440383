import {
  LISTAR_SETORES,
  LISTAR_SETORES_ERRO,
  FETCH_SETORES_CATALOGO,
  FETCH_SETOR,
  LISTAR_SETORES_ATUALIZAR_CATALOGO,
  REMOVE_SETOR,
  EDIT_SETOR_CLIENTE,
} from "./types";
import {
  setoresUrl,
  novoSetoresUrl,
  setorUrl,
} from "../../../../../../../constants";
import axios from "axios";
import { setupInterceptors } from "../../../../../../../auth/SetupInterceptors";
import openNotification from "../../../../../../../components/notifications";
setupInterceptors();

export const fetchSetores = (dispatch) => {
  axios
    .get(setoresUrl)
    .then((res) => {
      if (res.error) {
        throw res.error;
      }
      dispatch({
        type: LISTAR_SETORES,
        payload: res.data,
      });
    })
    .catch((erro) => {
      dispatch({
        type: LISTAR_SETORES_ERRO,
        payload: erro,
      });
    });
};

export const addSetor = (dispatch, setor, switchTab, resetFields, loading) => {
  loading(true);
  axios
    .post(`${setoresUrl}`, setor)
    .then((response) => {
      openNotification("Setor adicionado com sucesso!", "", "success");
      dispatch({
        type: LISTAR_SETORES_ATUALIZAR_CATALOGO,
        payload: { setores: response.data, novoSetor: setor.nome },
      });
      loading(false);
      switchTab(resetFields);
    })
    .catch(() => {
      openNotification("Erro ao adicionar setor!", "Tente novamente", "error");
      loading(false);
    });
};

export const removerSetor = (dispatch, id) => {
  console.log(id);
  axios
    .delete(`${setorUrl(id)}`)
    .then((response) => {
      if (response.status === 200) {
        openNotification("Setor excluído com sucesso!", "Salvo", "success");
        dispatch({
          type: REMOVE_SETOR,
          payload: id,
        });
      }
    })
    .catch((err) => {
      if (err) {
        openNotification(
          "Setor está associado em outro item!",
          "Remova a associação e tente novamente",
          "error"
        );
      }
    });
};

export const fetchSetoresAdmin = (dispatch) => {
  axios.get(novoSetoresUrl).then((novoSetorResponse) => {
    dispatch({
      type: FETCH_SETORES_CATALOGO,
      payload: {
        disponiveis: novoSetorResponse.data,
        indisponiveis: novoSetorResponse.data.indisponiveis,
      },
    });
  });
};

export const fetchSetor = (dispatch, id) => {
  axios
    .get(`${setorUrl(id)}`)
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: FETCH_SETOR,
          payload: response.data,
        });
      }
    })
    .catch(() => {
      openNotification("Erro ao buscar o setor!", "Tente novamente", "error");
    });
};

export const editSetor = (
  dispatch,
  id,
  setor,
  cargosRemovidos,
  loading,
  switchTab,
  resetFields,
  quantidadeDeTrabalhadores
) => {
  loading(true);
  // return (dispatch) => {
  //   anexosLoading();
  //   axios({
  //     method: "PATCH",
  //     headers: { "Content-Type": "multipart/form-data" },
  //     data: setor.anexos,
  //     url: `${setoresUrl}/${id}/anexos`,
  //   })
  //     .then(() => {
  //       anexosLoading();
  axios
    .patch(`${setorUrl(id)}`, setor)
    .then((response) => {
      switchTab(resetFields);
      openNotification("Setor editado com sucesso!", "", "success");
      loading(false);
      dispatch({
        type: EDIT_SETOR_CLIENTE,
        payload: { setor, id, cargosRemovidos, quantidadeDeTrabalhadores },
      });
    })
    .catch(() => {
      loading(false);
      openNotification("Erro ao editar setor!", "Tente novamente", "error");
    });
  // })
  // .catch(() => {
  //   anexosLoading();
  //   loading();
  //   openNotification(
  //     "Erro ao adicionar anexo!",
  //     "Tente novamente",
  //     "error"
  //   );
  // });
  // };
};
