import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  acompanhamentoTalks,
  talksAcompanhamento,
  talksInventariodeRiscos,
} from "../../constants/aroldoTalks";
import Assistant from "../../components/VirtualAssistant";
import { Tabs, Row } from "antd";
import SearchBar from "./_/components/SearchBar";
import ProcessesList from "./_/components/ProcessesList";
import { bindActionCreators } from "redux";
import "./styles.scss";
import RiskList from "./_/components/RisksList";
import {
  getInventarioDeRisco,
  getInventarioDeRiscoByProcess,
  editRiskInventory,
  getEPCEPIMAO,
  excluirFatorDeRisco,
  getFatorDeRiscoPorTipo,
  addFatorDeRisco,
  getProcessWithoutInventarioDeRisco,
  addAusenciaDeRisco,
} from "./_/actions";
import { isEmpty } from "../../util/arrayUtil";
import { onUnauthenticated } from "../../auth/SetupInterceptors";
import moment from "moment";

const InventarioRiscos = (props) => {
  const { TabPane } = Tabs;
  const [currentTab, setCurrentTab] = useState("listOfProcesses");
  const [processes, setProcesses] = useState([]);
  const [talk, setTalk] = useState({});

  useEffect(() => {
    if (isEmpty(props.companySelected)) {
      onUnauthenticated();
    }
    getInventarioDeRisco(props.dispatch);
    setProcesses(
      props.inventarioDeRisco.filter(
        (proc) => proc.dataFim === null || moment(proc.dataFim) > new Date()
      )
    );
    getEPCEPIMAO(props.dispatch);
  }, [JSON.stringify(props.inventarioDeRisco)]);

  useEffect(() => {
    if (props.talksRiscos) {
      let talks =
        currentTab === "listOfProcesses"
          ? talksInventariodeRiscos(
              props.talksRiscos[0].descricao,
              props.talksRiscos[0].naoEditavel
            )
          : talksInventariodeRiscos(
              props.talksRiscos[1].descricao,
              props.talksRiscos[1].naoEditavel
            );
      currentTab === "listOfProcesses"
        ? setTalk(talks[props.talksRiscos[0].id])
        : setTalk(talks[props.talksRiscos[1].id]);
    }
  }, [JSON.stringify(props.talksRiscos), currentTab]);

  const toggleTab = () => {
    setCurrentTab(
      currentTab === "listOfProcesses" ? "listOfRisks" : "listOfProcesses"
    );
    setProcesses(props.inventarioDeRisco);
  };

  const filterList = (event) => {
    const value = event.target.value;
    if (value.length > 2) {
      let filteredProcesses = processes.filter((process) => {
        return process.nome.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      });
      setProcesses(filteredProcesses);
    } else {
      setProcesses(props.inventarioDeRisco);
    }
  };

  return (
    <React.Fragment>
      <Assistant talk={talk} />
      <h1>
        {currentTab === "listOfProcesses"
          ? "Inventário de riscos"
          : "Inventário de fatores de risco"}
      </h1>
      <Tabs activeKey={currentTab} className="inventario-riscos-wrapper">
        <TabPane tab="" key="listOfProcesses">
          <Row align="middle">
            <h2>Processos da empresa</h2>
            <SearchBar name="processos existentes" filterList={filterList} />
            <h6>{`${processes.filter((process) => process.completo).length}/${
              processes.length
            } processos completos`}</h6>
          </Row>
          <ProcessesList
            processes={processes}
            toggleTab={toggleTab}
            getInventarioDeRiscoByProcess={getInventarioDeRiscoByProcess}
            getProcessWithoutInventarioDeRisco={
              getProcessWithoutInventarioDeRisco
            }
            addAusenciaDeRisco={addAusenciaDeRisco}
            dispatch={props.dispatch}
          />
        </TabPane>
        <TabPane tab="" key="listOfRisks">
          <RiskList
            inventarioDeRiscoDoProcesso={props.inventarioDeRiscoDoProcesso}
            toggleTab={toggleTab}
            edit={editRiskInventory}
            dispatch={props.dispatch}
            excluirFatorDeRisco={excluirFatorDeRisco}
            epis={props.epis}
            epcs={props.epcs}
            maos={props.maos}
            getInventarioDeRisco={getInventarioDeRisco}
            getFatorDeRiscoPorTipo={getFatorDeRiscoPorTipo}
            fatoresDeRiscoDisponiveis={props.fatoresDeRiscoDisponiveis}
            addFatorDeRisco={addFatorDeRisco}
          />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    inventarioDeRisco:
      state.caracterizacao.inventarioDeRisco.inventarioDeRiscos,
    inventarioDeRiscoDoProcesso:
      state.caracterizacao.inventarioDeRisco.inventarioDeRiscosDoProcesso,
    epis: state.caracterizacao.inventarioDeRisco.epi,
    epcs: state.caracterizacao.inventarioDeRisco.epc,
    maos: state.caracterizacao.inventarioDeRisco.mao,
    companySelected: state.caracterizacao.companySelected,
    fatoresDeRiscoDisponiveis:
      state.caracterizacao.processosProdutivos.fatoresDeRiscoDisponiveis,
    talksRiscos: state.gestorDN.virtualAssistant.monitoringTalks.risksTalks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getInventarioDeRisco,
        getInventarioDeRiscoByProcess,
        editRiskInventory,
        excluirFatorDeRisco,
        getFatorDeRiscoPorTipo,
        addFatorDeRisco,
        getProcessWithoutInventarioDeRisco,
        addAusenciaDeRisco,
      },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InventarioRiscos);
