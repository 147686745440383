import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getEPIs,
  getEPI,
  listarFatoresforGrupoDeRisco,
  patchEPI,
  emptyRelacaoEPISelecionanda,
  addEPI,
} from "../../actions";
import "../../../styles.scss";

const { Option } = Select;

const EPIForm = (props) => {
  // const [newRiskRelation, setNewRiskRelation] = useState();
  const [fatoresDeRiscoPorGrupo, setFatoresDeRiscoPorGrupo] = useState([]);
  const [mode, setMode] = useState("new");
  const [
    relacaoEPIFatorDeRiscoSelecionada,
    setRelacaoEPIFatorDeRiscoSelecionada,
  ] = useState({});
  const [epis, setEpis] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getEPIs(props.dispatch);
  }, []);

  useEffect(() => {
    if (props.relacaoEPIFatorDeRiscoSelecionada) setMode("edit");
    if (props.relacaoEPIFatorDeRiscoSelecionada)
      setRelacaoEPIFatorDeRiscoSelecionada(
        props.relacaoEPIFatorDeRiscoSelecionada
      );

    setEdit(props.relacaoEPIFatorDeRiscoSelecionada);
  }, [JSON.stringify(props.relacaoEPIFatorDeRiscoSelecionada)]);

  useEffect(() => {
    setEpis(epis);
  }, [JSON.stringify(epis)]);

  const setEdit = (relacaoEPIFatorDeRiscoSelecionada) => {
    if (mode === "edit") {
      form.setFieldsValue({
        riskName: relacaoEPIFatorDeRiscoSelecionada.descricao,
        riskEPIs: relacaoEPIFatorDeRiscoSelecionada.epis.map((epi) => epi.id),
      });
    }
  };

  const handleGroupSelection = () => {
    form
      .validateFields()
      .then((values) => {
        listarFatoresforGrupoDeRisco(values.riskGroup, props.dispatch);
      })
      .catch((err) => console.log(err));
  };

  const addNewEPI = () => {
    console.log(form.getFieldsValue(true));
    form.validateFields().then((values) => {
      let newRelacaoEPI = {
        epis: values.riskEPIs,
      };
      addEPI(values.riskName, newRelacaoEPI, props.dispatch);
    });
  };

  const patchRelacaoEPI = () => {
    form.validateFields().then((values) => {
      let edittedEPI = {
        epis: values.riskEPIs,
      };
      patchEPI(
        props.relacaoEPIFatorDeRiscoSelecionada.id,
        edittedEPI,
        props.dispatch
      );
      emptyRelacaoEPISelecionanda(props.dispatch);
    });
  };

  return (
    <React.Fragment>
      <Form
        form={form}
        onFinish={mode === "new" ? () => addNewEPI() : () => patchRelacaoEPI()}
      >
        <Form.Item name="riskGroup">
          <Select
            style={{
              width: 250,
            }}
            placeholder="Selecione um grupo de risco"
            onSelect={() => handleGroupSelection()}
          >
            <Option value="1">Físico</Option>
            <Option value="2">Químico</Option>
            <Option value="3">Biológico</Option>
            <Option value="4">Ergonômico</Option>
            <Option value="5">Acidente</Option>
            <Option value="6">Inespecífico</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Fator de risco" name="riskName">
          <Select>
            {props.fatoresDeRiscoPorGrupo
              ? props.fatoresDeRiscoPorGrupo.map((fator) => (
                  <Option value={fator.id}>{fator.descricao}</Option>
                ))
              : ""}
          </Select>
        </Form.Item>
        <Form.Item label="EPIs associados" name="riskEPIs">
          <Select
            mode="multiple"
            style={{
              width: "100%",
            }}
          >
            {props.epis
              ? props.epis.map((epi) => (
                  <Option value={epi.id}>{epi.nome}</Option>
                ))
              : ""}
          </Select>
        </Form.Item>
        <Button htmlType="submit">Salvar</Button>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    relacaoEPIFatorDeRiscoSelecionada:
      state.gestorDN.relacaoEPIFatorDeRiscoSelecionada,
    epis: state.gestorDN.listaEPIs,
    fatoresDeRiscoPorGrupo: state.gestorDN.fatoresDeRiscoPorGrupo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      getEPIs,
      getEPI,
      listarFatoresforGrupoDeRisco,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EPIForm);
