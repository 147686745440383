import React, { useEffect, useState } from "react";
import { Row, Col, Select, Form, Button, Tabs } from "antd";

import Assistant from "../../../../components/VirtualAssistant";
import { ESocialTalks } from "../../../../constants/aroldoTalks";
import { Link } from "react-router-dom";
import ESocialCompanySelector from "./ESocialCompanySelector";
import ESocialEventSelector from "./ESocialEventSelector";
import ESocialForm from "./ESocialForm";
import ESocialSignatures from "./ESocialSignatures";
import ESocialFollowUp from "./ESocialFollowUp";

const { TabPane } = Tabs;
const { Option } = Select;

const ESocialDashboard = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const premiumCompanies = props.companies.filter((company) => company.premium);

  return (
    <Row gutter={32}>
      <Assistant
        perfil={props.perfil}
        talk={ESocialTalks[1]}
        path="caracterizacao"
      />
      <Col lg={16} md={20} className="esocial-dashboard">
        <Tabs activeKey={activeTab}>
          <TabPane key="1">
            <div className="header-form">
              <h2>Selecione a empresa para gerar o eSocial</h2>
            </div>
            <div className="content">
              <Form>
                <div className="section">
                  <Form.Item label="Empresa::">
                    <Select>
                      {premiumCompanies.map((company) => (
                        <Option key={company.cnpj} value={company.cnpj}>
                          {company.razaoSocial}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Form>

              <Row gutter={24} className="buttons-wrapper">
                <Col span={8}>
                  <div className="go-to-pgr-button">
                    <Button>
                      <h3 onClick={() => setActiveTab("2")}>Gerar Eventos</h3>
                    </Button>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="go-to-pgr-button">
                    <Button>
                      <h3 onClick={() => setActiveTab("4")}>
                        Gerenciar assinaturas
                      </h3>
                    </Button>
                  </div>
                </Col>
                <Col span={8}>
                  <Link>
                    <div className="go-to-pgr-button">
                      <Button>
                        <h3 onClick={() => setActiveTab("5")}>
                          Acompanhar Eventos
                        </h3>
                      </Button>
                    </div>
                  </Link>
                </Col>
              </Row>
            </div>
          </TabPane>
          <TabPane key="2">
            <div className="header-form">
              <h2>Selecione o tipo de evento</h2>
            </div>
            <div className="content">
              <Form>
                <div className="section">
                  <Form.Item label="Evento::">
                    <Select>
                      {/* <Option
                        key="S-2210"
                        value="S-2210 - Comunicação de Acidente de Trabalho"
                      >
                        S-2210 - Comunicação de Acidente de Trabalho
                      </Option> */}
                      <Option
                        key="S-2220"
                        value="S-2220 - Monitoramento da Saúde do Trabalhador"
                      >
                        S-2220 - Monitoramento da Saúde do Trabalhador
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="event-buttons">
                  <Button onClick={() => setActiveTab("3")}>
                    PRÓXIMO PASSO
                  </Button>
                </div>
              </Form>
            </div>
          </TabPane>
          <TabPane key="3">
            <ESocialForm />
          </TabPane>
          <TabPane key="4">
            <ESocialSignatures {...props} />
          </TabPane>
          <TabPane key="5">
            <ESocialFollowUp />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default ESocialDashboard;
