import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  acompanhamentoTalks,
  talksAcompanhamento,
} from "../../constants/aroldoTalks";
import Assistant from "../../components/VirtualAssistant";
import { Link } from "react-router-dom";

import "./style.scss";
import { Button, Row, Steps, Progress, Col, Collapse, Table } from "antd";
import {
  fetchPGRPreview,
  fetchTrabalhadoresPGRPreview,
  generatePGR,
  toggleStatusFluxo,
} from "./_/actions";
import { isEmpty } from "../../util/arrayUtil";
import { onUnauthenticated } from "../../auth/SetupInterceptors";
import ButtonGroup from "antd/lib/button/button-group";
import { DownloadOutlined } from "@ant-design/icons";
import { captureLead } from "../Autodeclaracao/_/actions";
import { getInventarioDeRisco } from "../InventarioRiscos/_/actions";
import { bindActionCreators } from "redux";
import { getPlanoDeAcao } from "../PlanoDeAcao/_/actions";
import moment from "moment";
import { showPGROrientations } from "../../components/modals";
import {
  inventarioDeRiscoPreviewColumns,
  planoDeAcaoPreviewColumns,
  trabalhadoresPreviewColumns,
} from "./_/components/tableColumns";

const { Step } = Steps;
const { Panel } = Collapse;

const steps = [
  { key: 0, title: "Inventário de risco" },
  { key: 1, title: "Plano de ação" },
  // { key: 2, title: "Visualizar PGR" },
  { key: 2, title: "Gerar PGR" },
];

const Acompanhamento = (props) => {
  const [link, setLink] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [processes, setProcesses] = useState([]);
  const [completed, setCompleted] = useState(0);
  const [inventarioCompletionPercentage, setInventarioCompletionPercentage] =
    useState(0);
  const [planoAcaoCompletionPercentage, setPlanoAcaoCompletionPercentage] =
    useState(0);
  const [previewInventarioDeRiscos, setPreviewInventarioDeRiscos] = useState(
    []
  );
  const [previewTrabalhadores, setPreviewTrabalhadores] = useState([]);
  const [inventarioDeRiscosTableData, setInventarioDeRiscosTableData] =
    useState([]);
  const [planoDeAcaoTableData, setPlanoDeAcaoTableData] = useState([]);
  const [talk, setTalk] = useState({});

  const getLink = (url) => {
    setLink(url.data.url);
    setIsDisabled(false);
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (isEmpty(props.companySelected)) {
      onUnauthenticated();
    }
    getInventarioDeRisco(props.dispatch);
    getPlanoDeAcao(props.dispatch);
    fetchPGRPreview(props.dispatch);
    fetchTrabalhadoresPGRPreview(props.dispatch);
  }, []);

  useEffect(() => {
    if (props.talksAcompanhamento) {
      let talks = talksAcompanhamento(
        props.talksAcompanhamento[0].descricao,
        props.talksAcompanhamento[0].naoEditavel
      );
      setTalk(talks[props.talksAcompanhamento[0].id]);
    }
  }, [JSON.stringify(props.talksAcompanhamento)]);

  useEffect(() => {
    if (currentStep === 0) {
      calculateCompletion("inventario");
    } else if (currentStep === 1) {
      calculateCompletion("planoDeAcao");
    }
  }, [currentStep]);

  useEffect(() => {
    setPreviewInventarioDeRiscos(props.previewInventarioDeRiscos);
    getInventarioDeRiscoTableData(props.previewInventarioDeRiscos);
    getPlanoDeAcaoTableData(props.planoDeAcao.planoDeAcao);
  }, [JSON.stringify(props.previewInventarioDeRiscos)]);

  useEffect(() => {
    setPreviewTrabalhadores(props.previewTrabalhadores);
  }, [JSON.stringify(props.previewTrabalhadores)]);

  useEffect(() => {
    if (
      props.companySelected.statusFluxo === 2 ||
      props.companySelected.statusFluxo === 1
    ) {
      setProcesses(
        props.inventarioDeRisco.inventarioDeRiscos.filter(
          (proc) => proc.dataFim === null || moment(proc.dataFim) > new Date()
        )
      );
      calculateCompletion("inventario");
    } else if (props.companySelected.statusFluxo === 3) {
      setCurrentStep(1);
      calculateCompletion("planoDeAcao");
    } else if (props.companySelected.statusFluxo === 4) {
      setCurrentStep(4);
    }
  }, [JSON.stringify(props.inventarioDeRisco)]);

  const checkLead = () => {
    const isLeadChecked = document.getElementById("lead-checkbox").checked;
    if (isLeadChecked) {
      captureLead("pgr");
    }
  };

  const openSurvey = () => {
    const newWindows = window.open(
      "https://evaluate.typeform.com/to/Xr66h8tU",
      "name",
      "height=500,width=550"
    );
    if (window.focus) {
      newWindows.focus();
    }
    return false;
  };

  const calculateCompletion = (type) => {
    if (type === "inventario") {
      if (props.inventarioDeRisco.inventarioDeRiscos.length > 0) {
        const completedProcesses = props.inventarioDeRisco.inventarioDeRiscos
          .filter(
            (proc) => proc.dataFim === null || moment(proc.dataFim) > new Date()
          )
          .filter((process) => process.completo).length;
        setCompleted(completedProcesses);
        setInventarioCompletionPercentage(
          (completedProcesses /
            props.inventarioDeRisco.inventarioDeRiscos.filter(
              (proc) =>
                proc.dataFim === null || moment(proc.dataFim) > new Date()
            ).length) *
            100
        );
      } else if (props.inventarioDeRisco.inventarioDeRiscos.length > 0) {
        setInventarioCompletionPercentage(100);
      }
    } else if (type === "planoDeAcao") {
      if (props.planoDeAcao.planoDeAcao.length > 0) {
        const completedPlans = props.planoDeAcao.planoDeAcao.filter(
          (plan) => plan.controleFatorDeRisco
        ).length;
        setCompleted(completedPlans);
        setPlanoAcaoCompletionPercentage(
          (completedPlans / props.planoDeAcao.planoDeAcao.length) * 100
        );
      } else if (props.planoDeAcao.planoDeAcao.length === 0) {
        setPlanoAcaoCompletionPercentage(100);
      }
    }
  };

  const getDisabledButtonStatus = () => {
    if (props.companySelected.statusFluxo === 2) {
      const inventarioDeRiscoComplete =
        props.inventarioDeRisco.inventarioDeRiscos
          .filter(
            (proc) => proc.dataFim === null || moment(proc.dataFim) > new Date()
          )
          .map((inventario) => inventario.completo)
          .every((completo) => completo);
      return !inventarioDeRiscoComplete;
    } else if (props.companySelected.statusFluxo === 3) {
      const planoDeAcaoComplete = props.planoDeAcao.planoDeAcao
        .map((plano) => plano.controleFatorDeRisco)
        .every((completo) => completo);
      return !planoDeAcaoComplete;
    }
  };

  const getGoToInventarioDeRiscoDisabled = () => {
    const stepsEmpresa = props.stepsEmpresa;
    stepsEmpresa.PLANO_DE_ACAO = true;
    const stepsEmpresaAllDone = Object.values(stepsEmpresa).every(
      (step) => step
    );
    return !stepsEmpresaAllDone;
  };

  const getInventarioDeRiscoTableData = (inventarioDeRiscos) => {
    let tableData = {};
    if (inventarioDeRiscos) {
      inventarioDeRiscos.forEach((inventario) => {
        tableData[inventario.processoProdutivoId] = inventario.inventarios.map(
          (risco) => {
            return {
              key: risco.idFatorDeRisco,
              risk: risco.fatorDeRiscoNome,
              type: getRiskType(risco.fatorDeRiscoTipo),
              numberExposed: risco.numeroDeTrabalhadoresExpostos,
              possiblesInjures: risco.fatorDeRiscoDanosPossiveis,
              legal: risco.fatorDeRiscoPadraoLegal,
              source: risco.fonteGeradora,
              propagation: risco.fatorDeRiscoMeioDePropagacao,
              measure: risco.valorMedicao ? risco.valorMedicao : "-",
              probalitity: getProbabilityType(risco.indiceProbabilidade),
              severity: getSeverityType(risco.indiceSeveridade),
              riskLevel: risco.niveldeRisco,
              priority: risco.prioridade,
            };
          }
        );
      });
    }

    setInventarioDeRiscosTableData(tableData);
  };

  const prioridade = {
    intoleravel: "PR1",
    alto: "PR2",
    moderado: "PR3",
    toleravel: "PR4",
    irrelevante: "PR5",
  };

  const getPlanoDeAcaoTableData = (planoDeAcao) => {
    let tableData = {};
    planoDeAcao.forEach((plano) => {
      tableData[plano.idFatorDeRisco] = plano.acoesPlanejadas.map((acao) => {
        return {
          key: plano.idFatorDeRisco,
          actionPlan: acao.descricao,
          responsible: acao.responsavel,
          startDate: acao.previsaoInicio.split("-").reverse().join("/"),
          endDate: acao.previsaoFim.split("-").reverse().join("/"),
          actionPriority: prioridade[plano.processosProdutivos[0].nivelDeRisco],
        };
      });
    });
    setPlanoDeAcaoTableData(tableData);
  };

  const getRiskType = (typeId) => {
    switch (typeId) {
      case 1:
        return "Físico";
      case 2:
        return "Químico";
      case 3:
        return "Biológico";
      case 4:
        return "Ergonômico";
      case 5:
        return "Acidente";
      case 6:
        return "Inespecífico";
      default:
        break;
    }
  };

  const getSeverityType = (typeId) => {
    switch (typeId) {
      case 0:
        return "Não Aplicável";
      case 1:
        return "Mínima";
      case 2:
        return "Mediana";
      case 3:
        return "Considerável";
      case 4:
        return "Crítica";
      default:
        break;
    }
  };

  const getProbabilityType = (typeId) => {
    switch (typeId) {
      case 0:
        return "Não Aplicável";
      case 1:
        return "Improvável";
      case 2:
        return "Pouco Provável";
      case 3:
        return "Provável";
      case 4:
        return "Altamente Provável";
      default:
        break;
    }
  };

  return (
    <div className="acompanhamento-wrapper" id="responsivo">
      <Assistant talk={talk} />

      <div className="steps-div">
        <Steps current={currentStep}>
          {steps.map((item) => (
            <Step key={item.key} title={item.title} />
          ))}
        </Steps>
        <div className="step-content">
          {currentStep === 0 ? (
            <Row gutter={24}>
              <Col span={8} className="progress-step">
                <Progress
                  type="circle"
                  percent={Math.round(inventarioCompletionPercentage).toFixed(
                    2
                  )}
                  trailColor="none"
                  strokeColor="var(--alto)"
                />
              </Col>
              <Col span={16} className="left-part-step">
                <Row>
                  <p>
                    Para poder seguir com a elaboração do seu PGR, agora você
                    precisa caracterizar todos os perigos de cada processo
                    produtivo criado e/ou aceito na etapa anterior. Para
                    começar, é só clicar no botão abaixo “IR PARA O INVENTÁRIO
                    DE RISCOS”.
                  </p>
                  <p>
                    Caso o botão não esteja ativo, volte na caracterização e
                    marque todas as etapas como concluídas.
                  </p>
                  <p>
                    Ao lado, você vê a porcentagem de processos que você
                    concluiu o inventário de riscos.
                  </p>
                  <p>
                    Quando estiver 100%, clique em “PRÓXIMO PASSO” e siga para o
                    Plano de Ação!
                  </p>
                </Row>
                <Row className="action-step">
                  <Link to={"/inventario-de-riscos"}>
                    <Button
                      disabled={getGoToInventarioDeRiscoDisabled()}
                      onClick={() => {
                        props.toggleStatusFluxo({
                          statusFluxo: 2,
                        });
                      }}
                    >
                      IR PARA O INVENTÁRIO DE RISCO
                    </Button>
                  </Link>
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {currentStep === 1 ? (
            <Row gutter={24}>
              <Col span={8} className="progress-step">
                <Progress
                  type="circle"
                  percent={Math.round(planoAcaoCompletionPercentage).toFixed(2)}
                  trailColor="none"
                  strokeColor="var(--alto)"
                />
              </Col>
              <Col span={16} className="left-part-step">
                <Row>
                  <p>
                    Você já está quase lá! Para preparar o seu Plano de Ação,
                    clique no botão abaixo: “IR PARA O PLANO DE AÇÃO”.
                  </p>
                  <p>
                    O PGR ficará disponível para você após a conclusão de todas
                    as etapas!
                  </p>
                </Row>
                <Row className="action-step">
                  <Link to={"/plano-de-acao"}>
                    <Button
                      onClick={() =>
                        props.toggleStatusFluxo({
                          statusFluxo: 3,
                        })
                      }
                    >
                      IR PARA O PLANO DE AÇÃO
                    </Button>
                  </Link>
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {/* {currentStep === 2 ? (
            <Row gutter={24}>
              <Col span={24} className="left-part-step">
                <p>
                  Você já está quase lá! Visualize o resultado do seu PGR antes
                  de gerar uma versão do documento. Confira todas as informações
                  e se estiver tudo certo, clique em próximo passo. Caso
                  contrário, volte nos passos anteriores para realizar alguma
                  alteração.
                </p>
                <Collapse className="acompanhamento-table-wrapper">
                  <Panel header="Inventário de riscos" key="1">
                    {previewInventarioDeRiscos.map((processo) => (
                      <div>
                        <h4>{`Processo: ${processo.nome}`}</h4>
                        <Table
                          columns={inventarioDeRiscoPreviewColumns}
                          dataSource={
                            inventarioDeRiscosTableData[
                              `${processo.processoProdutivoId}`
                            ]
                          }
                        ></Table>
                      </div>
                    ))}
                  </Panel>
                  <Panel header="Plano de ação" key="2">
                    {props.planoDeAcao.planoDeAcao.map((acao) => (
                      <div>
                        <h4>{`Perigo: ${acao.descricao}`}</h4>
                        <Table
                          columns={planoDeAcaoPreviewColumns}
                          dataSource={
                            planoDeAcaoTableData[`${acao.idFatorDeRisco}`]
                          }
                          locale={{
                            emptyText: "Sem ações planejadas para esse perigo.",
                          }}
                        ></Table>
                      </div>
                    ))}
                  </Panel>
                  <Panel header="Trabalhadores, cargos e setores" key="3">
                    <Table
                      columns={trabalhadoresPreviewColumns}
                      dataSource={previewTrabalhadores}
                    ></Table>
                  </Panel>
                </Collapse>

                <p>
                  O PGR ficará disponível para você após a conclusão de todas as
                  etapas!
                </p>

                <Row className="action-step"></Row>
              </Col>
            </Row>
          ) : (
            ""
          )} */}
          {currentStep >= 2 ? (
            <Row gutter={24}>
              <Col span={24} className="left-part-step">
                <Row>
                  <p>
                    Parabéns, agora é só clicar em “GERAR PGR” e em seguida
                    clicar na caixinha para baixar, e então você concluiu o PGR
                    de sua empresa!
                  </p>
                  <p>
                    Agora é hora de pô-lo em prática! Reserve um tempinho para
                    ler todo o documento.
                  </p>
                  <p>
                    <a
                      href="https://evaluate.typeform.com/to/Xr66h8tU"
                      target={"_blank"}
                      style={{ textDecoration: "underline" }}
                    >
                      Responda a nossa pesquisa de satisfação.
                    </a>
                  </p>
                  <p>
                    Aviso importante!{" "}
                    <span
                      onClick={() => showPGROrientations()}
                      style={{ textDecoration: "underline" }}
                    >
                      Clique aqui
                    </span>{" "}
                    para entender melhor e saber quais são os próximos passos.
                  </p>
                </Row>
                <Row className="action-step">
                  {currentStep >= 2 ? (
                    <React.Fragment>
                      <ButtonGroup>
                        <div>
                          <Button
                            onClick={() => {
                              checkLead();
                              generatePGR(getLink, setLoading);
                              props.toggleStatusFluxo({
                                statusFluxo: 4,
                              });
                              openSurvey();
                            }}
                            loading={loading}
                            className="generate-pgr-button"
                          >
                            GERAR PGR
                          </Button>
                        </div>
                        <div>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${process.env.REACT_APP_BACKEND_URL}${link}`}
                            download={true}
                          >
                            <Button
                              onClick={() => generatePGR(getLink)}
                              disabled={isDisabled}
                              className="download-button"
                            >
                              <DownloadOutlined />
                            </Button>
                          </a>
                        </div>
                      </ButtonGroup>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </div>
        <div className="steps-button-div">
          <Button
            onClick={() => {
              {
                currentStep > 2
                  ? setCurrentStep(currentStep - 2)
                  : setCurrentStep(currentStep - 1);
              }

              // props.toggleStatusFluxo({
              //   statusFluxo: props.companySelected.statusFluxo - 1,
              // });
            }}
            disabled={currentStep <= 0}
          >
            VOLTAR
          </Button>
          {currentStep !== 2 ? (
            <Button
              onClick={() => {
                setCurrentStep(currentStep + 1);

                // props.toggleStatusFluxo({
                //   statusFluxo: props.companySelected.statusFluxo + 1,
                // });
              }}
              disabled={getDisabledButtonStatus()}
            >
              PRÓXIMO PASSO
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companySelected: state.caracterizacao.companySelected,
    inventarioDeRisco: state.caracterizacao.inventarioDeRisco,
    stepsEmpresa: state.caracterizacao.stepsEmpresa.stepsEmpresa,
    planoDeAcao: state.planoDeAcao,
    previewInventarioDeRiscos: state.dashboard.previewPGR,
    previewTrabalhadores: state.dashboard.previewTrabalhadoresPGR,
    talksAcompanhamento:
      state.gestorDN.virtualAssistant.monitoringTalks.monitoringTalks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        getInventarioDeRisco,
        toggleStatusFluxo,
      },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Acompanhamento);
