import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  postMAO,
  getMAOFromList,
  editMAO,
  emptyMAOSelecionado,
  deleteMAO,
} from "../../actions";
import "../../../styles.scss";

const CadastroMAOForm = (props) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("new");
  const [maoSelecionado, setMaoSelecionado] = useState({});

  useEffect(() => {
    if (maoSelecionado) setMode("edit");
    if (props.maoSelecionado) setMaoSelecionado(props.maoSelecionado);
    setEdit(props.maoSelecionado);
  }, [JSON.stringify(props.maoSelecionado)]);

  const setEdit = (maoSelecionado) => {
    if (mode == "edit" || props.maoSelecionado) {
      form.setFieldsValue({
        maoCode: maoSelecionado.id,
        maoName: maoSelecionado.nome,
      });
    }
  };

  const handleEdit = () => {
    form.validateFields().then((values) => {
      let newMAO = {
        id: values.maoCode,
        nome: values.maoName,
      };
      editMAO(values.maoCode, newMAO, props.dispatch);
      form.resetFields();
    });
  };

  const handleNew = () => {
    form.validateFields().then((values) => {
      let newMAO = {
        id: values.maoCode,
        nome: values.maoName,
      };
      postMAO(newMAO, props.dispatch);
      form.resetFields();
    });
  };

  const handleDelete = () => {
    form.validateFields().then((values) => {
      deleteMAO(values.maoCode, props.dispatch);
      setMode("new");
      form.resetFields();
    });
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={mode === "new" ? () => handleNew() : () => handleEdit()}
      >
        <Form.Item label="Código do MAO" name="maoCode">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="MAO" name="maoName">
          <Input />
        </Form.Item>
        <div className="mao-button-div">
          {!maoSelecionado.nome ? (
            <Button onClick={() => handleNew()}>Salvar</Button>
          ) : (
            ""
          )}
          {maoSelecionado.nome ? (
            <React.Fragment>
              <Button onClick={() => handleEdit()}>Alterar</Button>
              <Button onClick={() => handleDelete()}>Excluir</Button>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    maoSelecionado: state.gestorDN.maoSelecionado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...dispatch,
    ...bindActionCreators({
      getMAOFromList,
      emptyMAOSelecionado,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroMAOForm);
