import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import Button from "../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../components/IconsSESI";
import Assistant from "../../../../components/VirtualAssistant";
import { autodeclaracaoTalks } from "../../../../constants/aroldoTalks";
import Perguntas from "./Perguntas";
import { connect } from "react-redux";
import { isEmpty } from "../../../../util/arrayUtil";
import { onUnauthenticated } from "../../../../auth/SetupInterceptors";
import { getCompanyAnswers } from "../actions";
import { bindActionCreators } from "redux";

const Questionario = (props) => {
  const [hasAnswers, setHasAnswers] = useState(false);
  const [currentTalk, setCurrentQuestion] = useState(1);

  useEffect(() => {
    if (isEmpty(props.companySelected)) {
      onUnauthenticated();
    }
  });

  useEffect(() => {
    getCompanyAnswers(props.dispatch);
  }, []);

  useEffect(() => {
    setHasAnswers(true);
  }, [JSON.stringify(props.respostasAutodeclaracao)]);

  const changeTalk = (talk) => {
    setCurrentQuestion(talk);
  };

  return (
    <div className="questionario-wrapper">
      <Row gutter={24}>
        <Col span={6}>
          <Assistant talk={autodeclaracaoTalks[currentTalk]} />
        </Col>
        <Col span={18}>
          <Row gutter={24}>
            <Col span={24}>
              <div className="header-form">
                <Link to="/autodeclaracao">
                  <Button
                    shape="circle"
                    ghost
                    text={<IconSESI type="arrow-left" />}
                  />
                </Link>
                <h2>Autodeclaração</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Perguntas continue={hasAnswers} changeTalk={changeTalk} />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companySelected: state.caracterizacao.companySelected,
    respostasAutodeclaracao: state.autodeclaracao.respostas
      ? state.autodeclaracao.respostas
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      getCompanyAnswers,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Questionario);
