import React from "react";
import SelecionarEmpresa from "../scenes/SelecionarEmpresa/index";
//import MeuPerfil from "../scenes/MeuPerfil";
import LoginPage from "../scenes/Login";
// import PrimeirosPassos from "../scenes/Caracterizacao/_/components/PrimeirosPassos";
import Acompanhamento from "../scenes/Acompanhamento";
// import Resumo from "../scenes/Resumo";
import Caracterizacao from "../scenes/Caracterizacao";
import InventarioRiscos from "../scenes/InventarioRiscos";
import PlanoDeAcao from "../scenes/PlanoDeAcao";
import GestorDR from "../scenes/Admin/GestorDR";
import Autodeclaracao from "../scenes/Autodeclaracao";
import Questionario from "../scenes/Autodeclaracao/_/components/Questionario";
import GerarAutodeclaracao from "../scenes/Autodeclaracao/_/components/GerarAutodeclaracao";
import Itens from "../scenes/Itens";
import { FileTextOutlined } from "@ant-design/icons";
import RelatorioSMD from "../scenes/Admin/GestorDR/RelatorioSMD";
import RelatorioEmpresas from "../scenes/Admin/GestorDR/RelatorioEmpresas";
import DownloadDocumentos from "../scenes/DownloadDocumentos";
import ESocial from "../scenes/ESocial";
import FatoresDeRiscoDNList from "../scenes/Admin/GestorDN/_/components/FatoresDeRisco/FatoresDeRiscoDNList";
import RelacaoEPCList from "../scenes/Admin/GestorDN/_/components/RelacaoEPC/RelacaoEPCList";
import EPIList from "../scenes/Admin/GestorDN/_/components/EPI/EPIList";
import MAOList from "../scenes/Admin/GestorDN/_/components/MAO/MAOList";
import FatorDeRiscoDNForm from "../scenes/Admin/GestorDN/_/components/FatoresDeRisco/FatorDeRiscoDNForm";
import MAOForm from "../scenes/Admin/GestorDN/_/components/MAO/MAOForm";
import EPIForm from "../scenes/Admin/GestorDN/_/components/EPI/EPIForm";
import RelacaoEPCForm from "../scenes/Admin/GestorDN/_/components/RelacaoEPC/RelacaoEPCForm";
import CadastroEPCList from "../scenes/Admin/GestorDN/_/components/CadastroEPC/CadastroEPCList";
import CadastroEPCForm from "../scenes/Admin/GestorDN/_/components/CadastroEPC/CadastroEPCForm";
import CadastroEPIList from "../scenes/Admin/GestorDN/_/components/CadastroEPI/CadastroEPIList";
import CadastroEPIForm from "../scenes/Admin/GestorDN/_/components/CadastroEPI/CadastroEPIForm";
import CadastroMAOList from "../scenes/Admin/GestorDN/_/components/CadastroMAO/CadastroMAOList";
import CadastroMAOForm from "../scenes/Admin/GestorDN/_/components/CadastroMAO/CadastroMAOForm";
import ExportacaoDeDadosDR from "../scenes/Admin/GestorDR/ExportacaoEmpresas";
import CNAEsAutomatizados from "../scenes/Admin/GestorDN/_/components/CNAEs";
import AssistenteVirtual from "../scenes/Admin/GestorDN/_/components/AssistenteVirtual";

const MenuResponsavelOperador = (nomeFantasia) => [
  {
    key: 1,
    path: "/selecaoempresa",
    icon: "building",
    title: "Seleção de empresa",
    description: `Visualizando: ${nomeFantasia}`,
    component: (props) => {
      return <SelecionarEmpresa perfil="RESPONSAVEL" {...props} />;
    },
  },
  {
    key: 2,
    path: "/checklist",
    icon: "checklist",
    title: "Itens Necessários",
    component: (props) => <Itens {...props} />,
  },
  {
    key: 3,
    path: "/caracterizacao",
    icon: "edit",
    title: "Caracterização da empresa",
    component: (props) => <Caracterizacao {...props} />,
  },
  // {
  //   key: 4,
  //   path: "/resumo",
  //   icon: "sitemap",
  //   title: "Resumo",
  //   component: (props) => <Resumo perfil="RESPONSAVEL" {...props} />,
  // },
  {
    key: 5,
    path: "/acompanhamento",
    icon: "icon-pgr",
    title: "Elaboração PGR",
    component: (props) => <Acompanhamento {...props} />,
  },
  // {
  //   key: 6,
  //   path: "/perfil",
  //   icon: "bg-colors",
  //   title: "Customizar Perfil",
  //   component: (props) => <MeuPerfil {...props} />,
  // },
  // {
  //   key: 7,
  //   path: "/primeirospassos",
  //   icon: "history",
  //   title: "Primeiros Passos",
  //   hideFromMenu: true,
  //   component: (props) => <PrimeirosPassos {...props} />,
  // },
  {
    key: 53,
    path: "/e-social",
    icon: "esocial",
    title: "E-Social",
    hideFromMenu: false,
    component: (props) => <ESocial {...props} />,
  },
  {
    key: 8,
    path: "/logout",
    icon: "logout",
    title: "Sair",
    hideFromMenu: false,
  },
  {
    key: 45,
    path: "/login",
    icon: "",
    title: "",
    component: (props) => <LoginPage {...props} />,
    hideFromMenu: true,
  },
  {
    key: 46,
    path: "/inventario-de-riscos",
    icon: "",
    title: "",
    component: (props) => <InventarioRiscos {...props} />,
    hideFromMenu: true,
  },
  {
    key: 47,
    path: "/plano-de-acao",
    icon: "",
    title: "",
    component: (props) => <PlanoDeAcao {...props} />,
    hideFromMenu: true,
  },
  {
    key: 48,
    path: "/autodeclaracao",
    icon: "",
    title: "Autodeclaração",
    component: (props) => <Autodeclaracao {...props} />,
    hideFromMenu: true,
  },
  {
    key: 48,
    path: "/questionario-autodeclaracao",
    icon: "",
    title: "",
    component: (props) => <Questionario {...props} />,
    hideFromMenu: true,
  },
  {
    key: 49,
    path: "/gerar-autodeclaracao",
    icon: "",
    title: "",
    component: (props) => <GerarAutodeclaracao {...props} />,
    hideFromMenu: true,
  },
  {
    key: 60,
    path: "/download-documentos",
    icon: "download-document",
    title: "Download de documentos",
    component: (props) => <DownloadDocumentos {...props} />,
  },
];

const MenuItems = (nomeFantasia) => {
  return {
    RESPONSAVEL: MenuResponsavelOperador(nomeFantasia),
    GESTOR_DR: [
      {
        key: 50,
        path: "/exportacao-dados",
        icon: "database",
        title: "Exportação de dados",
        component: (props) => <ExportacaoDeDadosDR {...props} />,
        hideFromMenu: false,
      },
      {
        key: 51,
        path: "/logout",
        icon: "logout",
        title: "Sair",
        hideFromMenu: false,
      },
      {
        key: 52,
        path: "/relatorio-smd",
        icon: "file-outlined",
        title: "Relatório SMD",
        component: (props) => <RelatorioSMD {...props} />,
        hideFromMenu: false,
      },
      {
        key: 54,
        path: "/relatorio-diario",
        icon: "file-outlined",
        title: "Relatório Empresas",
        component: (props) => <RelatorioEmpresas {...props} />,
        hideFromMenu: true,
      },
    ],
    ADMINISTRADOR: [
      {
        key: 1,
        path: "/fatores-de-risco",
        icon: "skull-crossbones",
        title: "Fatores de risco",
        component: (props) => <FatoresDeRiscoDNList {...props} />,
      },
      {
        key: 2,
        path: "/relacao-epc",
        icon: "traffic-cone",
        title: "Relação fator de risco X EPC",
        component: (props) => <RelacaoEPCList {...props} />,
      },
      {
        key: 21,
        path: "/epc",
        icon: "traffic-cone",
        title: "EPC",
        component: (props) => <CadastroEPCList {...props} />,
      },
      {
        key: 22,
        path: "/formulario-epc",
        icon: "",
        title: "",
        component: (props) => <CadastroEPCForm {...props} />,
        hideFromMenu: true,
      },
      {
        key: 3,
        path: "/epi",
        icon: "technician",
        title: "Relação fator de risco X EPI",
        component: (props) => <EPIList {...props} />,
      },
      {
        key: 31,
        path: "/cadastro-epi",
        icon: "user-hard-hat",
        title: "EPI",
        component: (props) => <CadastroEPIList {...props} />,
      },
      {
        key: 32,
        path: "/form-epi",
        icon: "",
        title: "",
        component: (props) => <CadastroEPIForm {...props} />,
        hideFromMenu: true,
      },
      {
        key: 4,
        path: "/mao",
        icon: "file-outlined",
        title: "Relação fator de risco X MAO",
        component: (props) => <MAOList {...props} />,
      },
      {
        key: 41,
        path: "/cadastro-mao",
        icon: "file-outlined",
        title: "MAO",
        component: (props) => <CadastroMAOList {...props} />,
      },
      {
        key: 42,
        path: "/form-mao",
        icon: "",
        title: "",
        component: (props) => <CadastroMAOForm {...props} />,
        hideFromMenu: true,
      },
      {
        key: 61,
        path: "/formulario-epi",
        icon: "",
        title: "",
        component: (props) => <EPIForm {...props} />,
        hideFromMenu: true,
      },
      {
        key: 62,
        path: "/formulario-mao",
        icon: "",
        title: "",
        component: (props) => <MAOForm {...props} />,
        hideFromMenu: true,
      },
      {
        key: 65,
        path: "/formulario-relacao-epc",
        icon: "",
        title: "",
        component: (props) => <RelacaoEPCForm {...props} />,
        hideFromMenu: true,
      },
      {
        key: 63,
        path: "/formulario-fator-de-risco",
        icon: "",
        title: "",
        component: (props) => <FatorDeRiscoDNForm {...props} />,
        hideFromMenu: true,
      },
      {
        key: 67,
        path: "/assistente-virtual",
        icon: "cnae",
        title: "Assistente Virtual",
        component: (props) => <AssistenteVirtual {...props} />,
        hideFromMenu: false,
      },
      {
        key: 66,
        path: "/cnaes",
        icon: "cnae",
        title: "CNAEs Automatizados",
        component: (props) => <CNAEsAutomatizados {...props} />,
        hideFromMenu: false,
      },
      {
        key: 64,
        path: "/logout",
        icon: "logout",
        title: "Sair",
      },
    ],
    RESPONSAVELSEMEMPRESA: [
      {
        key: 24,
        path: "/selecaoempresa",
        icon: "building",
        title: "Seleção de empresa",
        component: (props) => {
          return <SelecionarEmpresa perfil="RESPONSAVEL" {...props} />;
        },
      },
      // {
      //   key: 25,
      //   path: "/perfil",
      //   icon: "bg-colors",
      //   title: "Customizar Perfil",
      //   component: (props) => <MeuPerfil {...props} />,
      // },
      {
        key: 26,
        path: "/logout",
        icon: "logout",
        title: "Sair",
        hideFromMenu: false,
      },
    ],
    OPERADOR: MenuResponsavelOperador(nomeFantasia),
    INTRO: [],
    DESLOGADO: [
      {
        key: 45,
        path: "/login",
        icon: "",
        component: (props) => <LoginPage {...props} />,
      },
    ],
  };
};
export default MenuItems;
