import axios from "axios";
import openNotification from "../../../../../components/notifications";
import { relatorioSMD } from "../../../../../constants";

const getMonths = (month) => {
  let monthString;
  switch (month) {
    case "01":
      monthString = "JAN";
      break;
    case "02":
      monthString = "FEV";
      break;
    case "03":
      monthString = "MAR";
      break;
    case "04":
      monthString = "ABR";
      break;
    case "05":
      monthString = "MAI";
      break;
    case "06":
      monthString = "JUN";
      break;
    case "07":
      monthString = "JUL";
      break;
    case "08":
      monthString = "AGO";
      break;
    case "09":
      monthString = "SET";
      break;
    case "10":
      monthString = "OUT";
      break;
    case "11":
      monthString = "NOV";
      break;
    case "12":
      monthString = "DEZ";
      break;
    default:
      break;
  }
  return monthString;
};

export const emitirSMD = (dataConsulta, month, year) => {
  axios({
    url: relatorioSMD,
    data: dataConsulta,
    method: "POST",
    responseType: "blob",
  })
    .then((response) => {
      if (response.data.size > 0) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `_SMD_${getMonths(month)}_${year}_0032105.txt`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        openNotification(
          "SMD não encontrado para o mês/ano selecionado.",
          "",
          "error"
        );
      }
    })
    .catch((err) => console.error(err));
};
