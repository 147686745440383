import { Col, Row, Select, Form, Button } from "antd";
import React from "react";
import Assistant from "../../../../components/VirtualAssistant";
import { ESocialTalks } from "../../../../constants/aroldoTalks";

const { Option } = Select;

const ESocialEventSelector = (props) => {
  return (
    <React.Fragment>
      <Row gutter={32}>
        <Col lg={16} md={24} className="esocial-dashboard">
          <div className="header-form">
            <h2>Selecione o tipo de evento</h2>
          </div>
          <div className="content">
            <Form>
              <div className="section">
                <Form.Item label="Evento::">
                  <Select>
                    {/* <Option
                      key="S-2210"
                      value="S-2210 - Comunicação de Acidente de Trabalho"
                    >
                      S-2210 - Comunicação de Acidente de Trabalho
                    </Option> */}
                    <Option
                      key="S-2220"
                      value="S-2220 - Monitoramento d Saúde do Trabalhador"
                    >
                      S-2220 - Monitoramento da Saúde do Trabalhador
                    </Option>
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ESocialEventSelector;
