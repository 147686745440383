import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  postEPC,
  getEPCFromList,
  editEPC,
  emptyEPCSelecionado,
  deleteEPC,
} from "../../actions";
import "../../../styles.scss";

const CadastroEPCForm = (props) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("new");
  const [epcSelecionado, setEpcSelecionado] = useState({});

  useEffect(() => {
    if (epcSelecionado) setMode("edit");
    if (props.epcSelecionado) setEpcSelecionado(props.epcSelecionado);
    setEdit(props.epcSelecionado);
  }, [JSON.stringify(props.epcSelecionado)]);

  const setEdit = (epcSelecionado) => {
    if (mode == "edit" || props.epcSelecionado) {
      form.setFieldsValue({
        epcCode: epcSelecionado.id,
        epcName: epcSelecionado.nome,
      });
    }
  };

  const handleEdit = () => {
    form.validateFields().then((values) => {
      let newEPC = {
        id: values.epcCode,
        nome: values.epcName,
      };
      editEPC(values.epcCode, newEPC, props.dispatch);
      form.resetFields();
    });
  };

  const handleNew = () => {
    form.validateFields().then((values) => {
      let newEPC = {
        id: values.epcCode,
        nome: values.epcName,
      };
      postEPC(newEPC, props.dispatch);
      form.resetFields();
    });
  };

  const handleDelete = () => {
    form.validateFields().then((values) => {
      deleteEPC(values.epcCode, props.dispatch);
      setMode("new");
      form.resetFields();
    });
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={mode === "new" ? () => handleNew() : () => handleEdit()}
      >
        <Form.Item label="Código do EPC" name="epcCode">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="EPC" name="epcName">
          <Input />
        </Form.Item>
        <div className="epc-button-div">
          {!epcSelecionado.nome ? (
            <Button onClick={() => handleNew()}>Salvar</Button>
          ) : (
            ""
          )}
          {epcSelecionado.nome ? (
            <React.Fragment>
              <Button onClick={() => handleEdit()}>Alterar</Button>
              <Button onClick={() => handleDelete()}>Excluir</Button>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    epcSelecionado: state.gestorDN.epcSelecionado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...dispatch,
    ...bindActionCreators({
      getEPCFromList,
      emptyEPCSelecionado,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroEPCForm);
