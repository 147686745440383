import {
  ALTERAR_ASSINATURA,
  DELETAR_ASSINATURA,
  LISTAR_ASSINATURAS,
  LISTAR_CATEGORIAS,
  LISTAR_EXAMES,
  LISTAR_FUNCIONARIOS_ESOCIAL,
  SALVAR_ASSINATURA,
  LISTAR_EVENTOS,
  GERAR_DOCUMENTO,
} from "../actions/types";

export const esocialReducer = (
  state = {
    categoriasTrabalhadores: [],
    tiposExames: [],
    eventosEsocial: [],
    certificadosCadastrados: [],
    documentoGerado: [],
  },
  action
) => {
  switch (action.type) {
    case LISTAR_CATEGORIAS:
      return {
        ...state,
        categoriasTrabalhadores: action.payload,
      };
    case LISTAR_EXAMES:
      return {
        ...state,
        tiposExames: action.payload,
      };
    case LISTAR_FUNCIONARIOS_ESOCIAL:
      return {
        ...state,
        funcionariosEsocial: action.payload,
      };
    case LISTAR_ASSINATURAS:
      return {
        ...state,
        certificadosCadastrados: action.payload,
      };
    case SALVAR_ASSINATURA:
      return {
        ...state,
        certificadosCadastrados: [action.payload],
      };
    case DELETAR_ASSINATURA:
      return {
        ...state,
        // certificadosCadastrados: action.payload,
      };
    case ALTERAR_ASSINATURA:
      return {
        ...state,
        certificadosCadastrados: [action.payload],
      };
    case LISTAR_EVENTOS:
      return {
        ...state,
        eventosEsocial: action.payload,
      };
    case GERAR_DOCUMENTO:
      return {
        ...state,
        documentoGerado: action.payload,
      };
    default:
      return state;
  }
};
