import React, { useEffect } from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import { signIn, logout, fetchCompanies } from "./_/actions";
import CustomSider from "./../CustomSider";
import { NotificationContainer } from "react-notifications";
import { withRouter } from "react-router-dom";
import "./styles.scss";

import { isPerfilCliente } from "../../constants/userTypes";

import { changeTheme } from "../../scenes/MeuPerfil/_/utils/changeColors";
import { bindActionCreators } from "redux";
import ContactUs from "../ContactUs";
import { getTalks } from "../../scenes/Admin/GestorDN/_/components/AssistenteVirtual/actions";

const LayoutApp = (props) => {
  const { user } = props;
  //const previousUser = usePrevious({ user });

  useEffect(() => {
    if (user.userInfo) {
      fetchCompanies(props.dispatch);
      getTalks(props.dispatch);
      setTheme();
    }
  }, [JSON.stringify(user)]);

  // const doLogin = async () => {
  //   const { signIn, fetchCompanies, authenticated } = props;
  //   let perfil = getPerfil();
  //   if (authenticated) {
  //     if (isPerfilCliente(perfil)) {
  //       await signIn(fetchCompanies);
  //     } else {
  //       await signIn();
  //     }
  //   }
  // };

  const getPerfil = () => {
    const { user } = props;
    if (user.userInfo) {
      return user.userInfo.perfil;
    }
  };

  const setTheme = () => {
    if (props.customizacoes === null) {
      changeTheme("azul");
    } else {
      if (!isPerfilCliente(props.profile)) {
        changeTheme(props.customizacoes.temaDeCoresDoUsuario);
      } else {
        const { temasDeCoresDasEmpresas } = props.customizacoes;
        const currentCompanyTheme =
          temasDeCoresDasEmpresas.length > 0
            ? temasDeCoresDasEmpresas.find(
                (e) => e.cnpj === props.companySelected.cnpj
              )
            : undefined;
        if (
          currentCompanyTheme !== undefined &&
          (props.location.pathname === "/caracterizacao" ||
            props.location.pathname === "/acompanhamento" ||
            props.location.pathname === "/primeirospassos" ||
            props.location.pathname === "/checklist" ||
            props.location.pathname === "/selecaoempresa" ||
            props.location.pathname === "/resumo")
        ) {
          changeTheme(currentCompanyTheme.temaDeCoresDaEmpresa);
        } else {
          changeTheme(props.customizacoes.temaDeCoresDoUsuario);
        }
      }
    }
  };

  const { Content } = Layout;

  return (
    <Layout style={{ minHeight: "100vh" }} className="blue-theme">
      <NotificationContainer />
      <CustomSider
        username={props.nome}
        profile={props.profile}
        customizacoes={props.customizacoes}
        perfil={getPerfil()}
        authenticated={props.authenticated}
      />
      <Content>
        <div className="max-container">{props.children}</div>
        <ContactUs />
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const nome =
    state.user && state.user.userInfo ? state.user.userInfo.nome : "";
  const profile =
    state.user && state.user.userInfo ? state.user.userInfo.perfil : "";
  const customizacoes = state.user.userInfo
    ? state.user.userInfo.customizacoes
    : {};
  return {
    authenticated: state.user.authenticated,
    user: state.user,
    nome: nome,
    customizacoes,
    profile: profile,
    companySelected: state.caracterizacao
      ? state.caracterizacao.companySelected
      : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      { signIn, logout, fetchCompanies, getTalks },
      dispatch
    ),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutApp)
);
