import axios from "axios";
import {
  alterarRelacaoFatorDeRiscoMAO,
  editarFatorDeRisco,
  incluirFatorDeRisco,
  listarEPCPorGrupo,
  EPCsUrl,
  listarFatoresDeRiscoPorGrupo,
  listarMAOPorGrupo,
  listarMAOs,
  obterFatorDeRisco,
  obterRelacaoFatorDeRiscoMAO,
  obterRelacaoFatorDeRiscoEPC,
  listarEPIs,
  listarEPIPorGrupo,
  obterRelacaoFatorDeRiscoEPI,
  alterarRelacaoFatorDeRiscoEPI,
  EPCUrl,
  EPIsUrl,
  EPIUrl,
  MAOsUrl,
  MAOUrl,
} from "../../../../../constants";
import {
  ALTERAR_FATOR_DE_RISCO,
  ALTERAR_MAO_POR_GRUPO,
  LIMPAR_FATOR_DE_RISCO_SELECIONADO,
  LISTAR_FATORES_DE_RISCO,
  LISTAR_MAOS,
  LISTAR_MAOS_POR_GRUPO,
  OBTER_FATOR_DE_RISCO,
  OBTER_MAO,
  OBTER_EPC,
  SALVAR_FATOR_DE_RISCO,
  LIMPAR_MAO_SELECIONADO,
  SALVAR_MAO_POR_GRUPO,
  LISTAR_EPCS_POR_GRUPO,
  LISTAR_EPCS,
  SALVAR_EPC_POR_GRUPO,
  LISTAR_EPIS,
  LISTAR_EPIS_POR_GRUPO,
  OBTER_EPI,
  SALVAR_EPI_POR_GRUPO,
  ALTERAR_EPI_POR_GRUPO,
  LIMPAR_EPI_SELECIONADO,
  CRIAR_EPC,
  EDITAR_EPC,
  OBTER_EPC_DA_LISTA,
  LIMPAR_EPC_SELECIONADO,
  DELETAR_EPC,
  CRIAR_EPI,
  EDITAR_EPI,
  OBTER_EPI_DA_LISTA,
  LIMPAR_SELECAO_EPI,
  DELETAR_EPI,
  CRIAR_MAO,
  EDITAR_MAO,
  OBTER_MAO_DA_LISTA,
  LIMPAR_SELECAO_MAO,
  DELETAR_MAO,
} from "./types";

export const listarFatoresforGrupoDeRisco = (grupoDeRisco, dispatch) => {
  axios
    .post(listarFatoresDeRiscoPorGrupo, { grupoDeRisco })
    .then((response) => {
      dispatch({
        type: LISTAR_FATORES_DE_RISCO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const salvarFatorDeRisco = (newRisk, dispatch) => {
  axios
    .post(incluirFatorDeRisco, newRisk)
    .then((response) => {
      dispatch({
        type: SALVAR_FATOR_DE_RISCO,
        payload: response.data,
      });
      window.location.href = "/fatores-de-risco";
    })
    .catch((err) => console.log(err));
};

export const fetchFatorDeRisco = (id, dispatch) => {
  axios
    .get(obterFatorDeRisco(id))
    .then((response) => {
      dispatch({
        type: OBTER_FATOR_DE_RISCO,
        payload: response.data,
      });
    })
    .catch();
};

export const patchFatorDeRisco = (id, edittedRisk, dispatch) => {
  axios
    .patch(editarFatorDeRisco(id), edittedRisk)
    .then((response) => {
      dispatch({
        type: ALTERAR_FATOR_DE_RISCO,
        payload: response.data,
      });
      window.location.href = "/fatores-de-risco";
    })
    .catch();
};

export const emptyFatorDeRiscoSelecionado = (dispatch) => {
  dispatch({
    type: LIMPAR_FATOR_DE_RISCO_SELECIONADO,
    payload: {},
  });
};

export const emptyRelacaoMAOSelecionanda = (dispatch) => {
  dispatch({
    type: LIMPAR_MAO_SELECIONADO,
    payload: {},
  });
};

export const getMAOs = (dispatch) => {
  axios
    .get(listarMAOs)
    .then((response) => {
      dispatch({
        type: LISTAR_MAOS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEPCs = (dispatch) => {
  axios
    .get(EPCsUrl)
    .then((response) => {
      dispatch({
        type: LISTAR_EPCS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getMAOPorGrupo = (grupoDeRisco, dispatch) => {
  axios
    .post(listarMAOPorGrupo, { grupoDeRisco })
    .then((response) => {
      dispatch({
        type: LISTAR_MAOS_POR_GRUPO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEPCPorGrupo = (grupoDeRisco, dispatch) => {
  axios
    .post(listarEPCPorGrupo, { grupoDeRisco })
    .then((response) => {
      dispatch({
        type: LISTAR_EPCS_POR_GRUPO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getMAO = (id, dispatch) => {
  axios
    .get(obterRelacaoFatorDeRiscoMAO(id))
    .then((response) => {
      dispatch({
        type: OBTER_MAO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEPC = (id, dispatch) => {
  axios
    .get(obterRelacaoFatorDeRiscoEPC(id))
    .then((response) => {
      dispatch({
        type: OBTER_EPC,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const addMAO = (idFatorDeRisco, newMAO, dispatch) => {
  axios
    .patch(alterarRelacaoFatorDeRiscoMAO(idFatorDeRisco), newMAO)
    .then((response) => {
      dispatch({
        type: SALVAR_MAO_POR_GRUPO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const patchMAO = (idFatorDeRisco, edittedMAO, dispatch) => {
  axios
    .post(alterarRelacaoFatorDeRiscoMAO(idFatorDeRisco), edittedMAO)
    .then((response) => {
      dispatch({
        type: ALTERAR_MAO_POR_GRUPO,
        payload: response.data,
      });
      window.location.href = "/mao";
    })
    .catch((err) => console.log(err));
};

export const addEPC = (idFatorDeRisco, newEPC, dispatch) => {
  axios
    .patch(obterRelacaoFatorDeRiscoEPC(idFatorDeRisco), newEPC)
    .then((response) => {
      dispatch({
        type: SALVAR_EPC_POR_GRUPO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEPIs = (dispatch) => {
  axios
    .get(listarEPIs)
    .then((response) => {
      dispatch({
        type: LISTAR_EPIS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const patchEPC = (idFatorDeRisco, edittedEPC, dispatch) => {
  axios
    .post(obterRelacaoFatorDeRiscoEPC(idFatorDeRisco), edittedEPC)
    .then((response) => {
      dispatch({
        type: SALVAR_EPC_POR_GRUPO,
        payload: response.data,
      });
      window.location.href = "/relacao-epc";
    })
    .catch((err) => console.log(err));
};

export const getEPIPorGrupo = (grupoDeRisco, dispatch) => {
  axios
    .post(listarEPIPorGrupo, { grupoDeRisco })
    .then((response) => {
      dispatch({
        type: LISTAR_EPIS_POR_GRUPO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEPI = (id, dispatch) => {
  axios
    .get(obterRelacaoFatorDeRiscoEPI(id))
    .then((response) => {
      dispatch({
        type: OBTER_EPI,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const addEPI = (idFatorDeRisco, newEPI, dispatch) => {
  axios
    .patch(alterarRelacaoFatorDeRiscoEPI(idFatorDeRisco), newEPI)
    .then((response) => {
      dispatch({
        type: SALVAR_EPI_POR_GRUPO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const patchEPI = (idFatorDeRisco, edittedEPI, dispatch) => {
  axios
    .post(alterarRelacaoFatorDeRiscoEPI(idFatorDeRisco), edittedEPI)
    .then((response) => {
      dispatch({
        type: ALTERAR_EPI_POR_GRUPO,
        payload: response.data,
      });
      window.location.href = "/epi";
    })
    .catch((err) => console.log(err));
};

export const emptyRelacaoEPISelecionanda = (dispatch) => {
  dispatch({
    type: LIMPAR_EPI_SELECIONADO,
    payload: {},
  });
};

export const postEPC = (newEPC, dispatch) => {
  axios
    .post(EPCsUrl, newEPC)
    .then((response) => {
      dispatch({
        type: CRIAR_EPC,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const editEPC = (id, patchedBody, dispatch) => {
  axios
    .patch(EPCUrl(id), patchedBody)
    .then((response) => {
      dispatch({
        type: EDITAR_EPC,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEPCFromList = (id, dispatch) => {
  axios
    .get(EPCUrl(id))
    .then((response) => {
      dispatch({
        type: OBTER_EPC_DA_LISTA,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const emptyEPCSelecionado = (dispatch) => {
  dispatch({
    type: LIMPAR_EPC_SELECIONADO,
    payload: {},
  });
};

export const deleteEPC = (id, dispatch) => {
  axios
    .delete(EPCUrl(id))
    .then((response) => {
      dispatch({
        type: DELETAR_EPC,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const postEPI = (newEPI, dispatch) => {
  axios
    .post(EPIsUrl, newEPI)
    .then((response) => {
      dispatch({
        type: CRIAR_EPI,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const editEPI = (id, patchedBody, dispatch) => {
  axios
    .patch(EPIUrl(id), patchedBody)
    .then((response) => {
      dispatch({
        type: EDITAR_EPI,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEPIFromList = (id, dispatch) => {
  axios
    .get(EPIUrl(id))
    .then((response) => {
      dispatch({
        type: OBTER_EPI_DA_LISTA,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const emptyEPISelecionado = (dispatch) => {
  dispatch({
    type: LIMPAR_SELECAO_EPI,
    payload: {},
  });
};

export const deleteEPI = (id, dispatch) => {
  axios
    .delete(EPIUrl(id))
    .then((response) => {
      dispatch({
        type: DELETAR_EPI,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const postMAO = (newMAO, dispatch) => {
  axios
    .post(MAOsUrl, newMAO)
    .then((response) => {
      dispatch({
        type: CRIAR_MAO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const editMAO = (id, patchedBody, dispatch) => {
  axios
    .patch(MAOUrl(id), patchedBody)
    .then((response) => {
      dispatch({
        type: EDITAR_MAO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getMAOFromList = (id, dispatch) => {
  axios
    .get(MAOUrl(id))
    .then((response) => {
      dispatch({
        type: OBTER_MAO_DA_LISTA,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const emptyMAOSelecionado = (dispatch) => {
  dispatch({
    type: LIMPAR_SELECAO_MAO,
    payload: {},
  });
};

export const deleteMAO = (id, dispatch) => {
  axios
    .delete(MAOUrl(id))
    .then((response) => {
      dispatch({
        type: DELETAR_MAO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};
