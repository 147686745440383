import { Button, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  emptyFatorDeRiscoSelecionado,
  fetchFatorDeRisco,
  listarFatoresforGrupoDeRisco,
} from "../../actions";
import "../../../styles.scss";

const { Option } = Select;

const FatoresDeRiscoDNList = (props) => {
  const [fatoresDeRiscoPorGrupo, setFatoresDeRiscoPorGrupo] = useState([]);
  const [selected, setSelected] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setFatoresDeRiscoPorGrupo(props.fatoresDeRiscoPorGrupo);
  }, [JSON.stringify(props.fatoresDeRiscoPorGrupo)]);

  const handleGroupSelection = () => {
    form
      .validateFields()
      .then((values) => {
        listarFatoresforGrupoDeRisco(values.riskGroup, props.dispatch);
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (id) => {
    fetchFatorDeRisco(id, props.dispatch);
  };

  const handleNew = () => {
    emptyFatorDeRiscoSelecionado(props.dispatch);
  };

  const filterByCode = (e) => {
    setFatoresDeRiscoPorGrupo(
      props.fatoresDeRiscoPorGrupo.filter(
        (fator) => fator.codigo.toString().indexOf(e.target.value) > -1
      )
    );
  };

  const filterByName = (e) => {
    setFatoresDeRiscoPorGrupo(
      props.fatoresDeRiscoPorGrupo.filter(
        (fator) =>
          fator.descricao.toLowerCase().indexOf(e.target.value.toLowerCase()) >
          -1
      )
    );
  };

  return (
    <React.Fragment>
      <div>
        <div>
          <Form form={form}>
            <Form.Item name="riskGroup">
              <Select
                style={{
                  width: 250,
                }}
                placeholder="Selecione um grupo de risco"
                onSelect={() => handleGroupSelection()}
              >
                <Option value="1">Físico</Option>
                <Option value="2">Químico</Option>
                <Option value="3">Biológico</Option>
                <Option value="4">Ergonômico</Option>
                <Option value="5">Acidente</Option>
                <Option value="6">Inespecífico</Option>
              </Select>
            </Form.Item>
          </Form>
          <Row>
            <Input
              style={{
                width: 300,
              }}
              placeholder="Filtrar por código do fator de risco"
              onChange={filterByCode}
            />
            <Input
              style={{
                width: 300,
              }}
              placeholder="Filtrar por nome do fator de risco"
              onChange={filterByName}
            />
          </Row>
          <Link to="/formulario-fator-de-risco">
            <Button onClick={() => handleNew()}>
              Adicionar fator de risco
            </Button>
          </Link>
        </div>
        <div className="dn-content">
          {fatoresDeRiscoPorGrupo
            ? fatoresDeRiscoPorGrupo.map((fatorDeRisco) => {
                return (
                  <div className="fatores-de-risco-item">
                    <p>{fatorDeRisco.descricao}</p>
                    <p>{fatorDeRisco.codigo}</p>
                    <p>{fatorDeRisco.classificacaoDeRisco}</p>
                    <p>{fatorDeRisco.ativo ? "Ativo" : "Inativo"}</p>
                    <p>{fatorDeRisco.gravidade}</p>
                    <Link to="/formulario-fator-de-risco">
                      <Button onClick={() => handleEdit(fatorDeRisco.id)}>
                        Editar
                      </Button>
                    </Link>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    fatoresDeRiscoPorGrupo: state.gestorDN.fatoresDeRiscoPorGrupo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ listarFatoresforGrupoDeRisco }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FatoresDeRiscoDNList);
