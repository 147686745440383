import { Affix } from "antd";
import React from "react";
import ContactUsForm from "./_/components/ContactUsForm";
import "./styles.scss";

const ContactUs = () => {
  return (
    <React.Fragment>
      <Affix style={{ position: "absolute", bottom: "1%", left: "95%" }}>
        <ContactUsForm />
      </Affix>
    </React.Fragment>
  );
};

export default ContactUs;
