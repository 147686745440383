import React, { useEffect, useState } from "react";
import FinishStep from "../../../../../../../components/FinishStep";
import { RightCircleOutlined } from "@ant-design/icons";
import { Collapse, Row, Col, Cascader, Form, Card, Button } from "antd";
import { addRealizadorProcessos } from "../actions";
import { REALIZADOR_DE_PROCESSOS } from "../../../../../../../constants/stepTypes";
import moment from "moment";

const ProcessesList = (props) => {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [associationType, setAssociationType] = useState({});
  const [associated, setAssociated] = useState({});
  const [exposedToTheProcess, setExposedToTheProcess] = useState({});
  const [inventarioDeRisco, setInventarioDeRisco] = useState([]);
  const [activeKey, setActiveKey] = useState(0);

  const tooltipCascader =
    "Você só poderá escolher uma forma de selecionar. Ao clicar em Setor, todos os setores da sua empresa serão selecionados sem poder desselecionar algum. O mesmo acontecerá ao clicar em Cargo, todos os cargos da sua empresa serão selecionados. Você também pode pesquisar por trabalhador, ao clicar em Trabalhador todos os trabalhadores cadastrados em sua empresa aparecerão em ordem alfabética. Para facilitar, é possível que você escreva no campo de busca o que deseja e ele encontrará respostas de acordo com a sua busca.";

  useEffect(() => {
    if (props.processosProdutivos.length > 0) {
      setActiveKey(props.processosProdutivos[0].idProcessoProdutivoDaEmpresa);
    }
  }, []);

  useEffect(() => {
    setInventarioDeRisco(props.inventarioDeRisco.inventarioDeRiscos);
  }, [JSON.stringify(props.inventarioDeRisco)]);

  const optionSetores = {
    label: "Setor",
    value: "setores",
    children: [],
  };

  const optionCargos = {
    label: "Cargo",
    value: "cargos",
    children: [],
  };

  const optionTrabalhadores = {
    label: "Trabalhador(a)",
    value: "trabalhador",
    children: [],
  };

  const emptyOption = {
    label: "",
    value: "",
  };

  const setoresReduced = props.funcionarios.reduce((a, b) => {
    let key = b.setor;
    if (!a[key]) {
      a[key] = [];
    }
    a[key].push(b.id);
    return a;
  }, {});

  const valuesSetores = Object.values(setoresReduced);
  const keysSetores = Object.keys(setoresReduced);

  const setoresObject = keysSetores.map((key, index) => {
    return {
      label: key,
      value: valuesSetores[index],
    };
  });

  optionSetores.children = setoresObject.map((setor) => {
    return {
      label: setor.label,
      value: setor.value,
    };
  });

  const cargosReduced = props.funcionarios.reduce((a, b) => {
    let key = `${b.setor} - ${b.cargo}`;
    if (!a[key]) {
      a[key] = [];
    }
    a[key].push(b.id);
    return a;
  }, {});

  const valuesCargos = Object.values(cargosReduced);
  const keysCargos = Object.keys(cargosReduced);

  const cargosObject = keysCargos.map((key, index) => {
    return {
      label: key,
      value: valuesCargos[index],
    };
  });

  optionCargos.children = cargosObject.map((cargo) => {
    return {
      label: cargo.label,
      value: cargo.value,
    };
  });

  const funcionarios = props.funcionarios.map((funcionario) => {
    return {
      nome: funcionario.nome,
      id: funcionario.id,
      ativo: funcionario.ativo,
    };
  });
  optionTrabalhadores.children = funcionarios
    ? funcionarios.map((funcionario) => {
        return {
          label: funcionario.nome,
          value: funcionario.id,
          disabled: !funcionario.ativo,
        };
      })
    : [];
  optionSetores.children.push(emptyOption);
  optionCargos.children.push(emptyOption);
  optionTrabalhadores.children.push(emptyOption);
  const options = [];
  if (options.length < 4) {
    options.push(optionSetores, optionCargos, optionTrabalhadores, emptyOption);
  }

  const filter = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
  };

  const displayRender = (label) => {
    return label[label.length - 1];
  };

  const onSelectProcessAgent = (value, processName) => {
    let association = "";
    if (value.length === 1) {
      association = value[0][0];
    } else if (value.length > 1) {
      const parents = new Set(value.map((v) => v[0]));
      if (parents.size > 1) {
        association = "trabalhador";
      } else {
        association = value[0][0];
      }
    }
    setAssociationType({
      ...associationType,
      ...{ [processName]: association },
    });
    const selectedTrabalhadoresId = value.map((v) => v[1]);
    setExposedToTheProcess({
      ...exposedToTheProcess,
      ...{ [processName]: selectedTrabalhadoresId.flat() },
    });
    //setExposedToTheProcess(selectedTrabalhadoresId.flat());

    if (association === "setores") {
      const setoresSelected = selectedTrabalhadoresId.map((value) => {
        return optionSetores.children
          .filter((setor) => {
            if (setor.value.length > 0) {
              if (setor.value.join() === value.join()) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          })
          .map((selected) => selected.label)
          .flat();
      });
      setAssociated({ ...associated, ...{ [processName]: setoresSelected } });
    } else if (association === "cargos") {
      const cargosSelected = selectedTrabalhadoresId.map((value) => {
        return optionCargos.children
          .filter((cargo) => {
            if (cargo.value.length > 0) {
              if (cargo.value.join() === value.join()) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          })
          .map((selected) => selected.label)
          .flat();
      });
      setAssociated({ ...associated, ...{ [processName]: cargosSelected } });
    } else if (association === "trabalhador") {
      value.map((v) => {
        const trabalhadoresSelected = value
          .map((v) => v[1])
          .map((value) => {
            return optionTrabalhadores.children
              .filter((trabalhador) => {
                if (trabalhador.value !== "") {
                  if (typeof value === "number") {
                    if (trabalhador.value === value) {
                      return true;
                    } else {
                      return false;
                    }
                  } else if (value.includes(trabalhador.value)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              })
              .map((selected) => selected.label)
              .flat();
          });
        setAssociated({
          ...associated,
          ...{
            [processName]: [...new Set(trabalhadoresSelected.flat())],
          },
        });
      });
    }
    if (value.length === 0) {
      setAssociated({});
    }
  };

  const toggleLoading = (bool) => {
    setLoading(bool);
  };

  const getSavedValues = (id, processName, grupoExposto) => {
    let initialValues = [];
    let association;
    if (grupoExposto) {
      const grupoExpostoConst = grupoExposto;
      if (grupoExpostoConst.categoria === "cargos") {
        const initialValue = grupoExpostoConst.valor.map((valor) => {
          association = "cargos";
          if (optionCargos.children.length > 1) {
            return [
              "cargos",
              optionCargos.children.find((cargo) => valor === cargo.label)
                .value,
            ];
          }
        });
        initialValues.push(initialValue);
      } else if (grupoExpostoConst.categoria === "setores") {
        association = "setores";
        const initialValue = grupoExpostoConst.valor.map((valor) => {
          return [
            "setores",
            optionSetores.children.find((setor) => valor === setor.label).value,
          ];
        });
        initialValues.push(initialValue);
      } else if (grupoExpostoConst.categoria === "trabalhador") {
        association = "trabalhador";
        const initialValue = grupoExpostoConst.valor.map((valor) => {
          return [
            "trabalhador",
            optionTrabalhadores.children
              ? optionTrabalhadores.children.find(
                  (trabalhador) => valor === trabalhador.label
                ).value
              : "",
          ];
        });
        initialValues.push(initialValue);
      }
      if (association === "setores") {
        const setoresSelected = initialValues
          .flat()
          .map((v) => v[1])
          .map((value) => {
            return optionSetores.children
              .filter((setor) => {
                if (setor.value.length > 0) {
                  if (setor.value.join() === value.join()) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              })
              .map((selected) => selected.label)
              .flat();
          });
        if (!associated[processName]) {
          setAssociated({
            ...associated,
            ...{ [processName]: setoresSelected },
          });
          setAssociationType({
            ...associated,
            ...{ [processName]: association },
          });
          const exposedToThisProcess = children
            .filter((child) => {
              if (child) {
                return setoresSelected.flat().includes(child.label);
              }
            })
            .map((child) => child.value)
            .flat();
          setExposedToTheProcess({
            ...exposedToTheProcess,
            ...{ [processName]: exposedToThisProcess },
          });
        }
      } else if (association === "cargos") {
        const cargosSelected = initialValues
          .flat()
          .map((v) => v[1])
          .map((value) => {
            return optionCargos.children
              .filter((cargo) => {
                if (cargo.value.length > 0) {
                  if (cargo.value.join() === value.join()) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              })
              .map((selected) => selected.label)
              .flat();
          });
        if (!associated[processName]) {
          setAssociated({
            ...associated,
            ...{ [processName]: cargosSelected },
          });
          setAssociationType({
            ...associated,
            ...{ [processName]: association },
          });
          const exposedToThisProcess = children
            .filter((child) => {
              if (child) {
                return cargosSelected.flat().includes(child.label);
              }
            })
            .map((child) => child.value)
            .flat();
          setExposedToTheProcess({
            ...exposedToTheProcess,
            ...{ [processName]: exposedToThisProcess },
          });
        }
      } else if (association === "trabalhador") {
        const trabalhadoresSelected = initialValues
          .flat()
          .map((v) => v[1])
          .map((value) => {
            return optionTrabalhadores.children
              .filter((trabalhador) => {
                if (trabalhador.value !== "") {
                  if (typeof value === "number") {
                    if (trabalhador.value === value) {
                      return true;
                    } else {
                      return false;
                    }
                  } else if (value.includes(trabalhador.value)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              })
              .map((selected) => selected.label)
              .flat();
          });
        if (!associated[processName]) {
          setAssociated({
            ...associated,
            ...{
              [processName]: [...new Set(trabalhadoresSelected.flat())],
            },
          });
          setAssociationType({
            ...associated,
            ...{ [processName]: association },
          });
          const exposedToThisProcess = children
            .filter((child) => {
              if (child) {
                return trabalhadoresSelected.flat().includes(child.label);
              }
            })
            .map((child) => child.value)
            .flat();
          setExposedToTheProcess({
            ...exposedToTheProcess,
            ...{ [processName]: exposedToThisProcess },
          });
        }
      }
      return initialValues.flat();
    }
  };

  const handleSubmit = (id, name, isCatalogo) => {
    addRealizadorProcessos(
      {
        processoProdutivoId: id,
        funcionarios: exposedToTheProcess[name],
        tipoDeAssociacao: associationType[name],
      },
      toggleLoading,
      handleOpenedPanel,
      props.dispatch
    );
    //setExposedToTheProcess([]);
  };

  const handleOpenedPanel = (id) => {
    const processesIDs = props.processosProdutivos.map(
      (process) => process.idProcessoProdutivoDaEmpresa
    );
    const next = processesIDs.indexOf(activeKey) + 1;
    setActiveKey(processesIDs[next]);
  };

  const children = options.map((option) => option.children).flat();
  return (
    <div>
      <Collapse
        expandIconPosition="right"
        bordered={false}
        expandIcon={({ isActive }) => (
          <RightCircleOutlined rotate={isActive ? 90 : 0} />
        )}
        accordion
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        {props.processosProdutivos
          .filter(
            (proc) => proc.dataFim === null || moment(proc.dataFim) > new Date()
          )
          .map((process) => (
            <Panel
              header={process.nome}
              key={process.idProcessoProdutivoDaEmpresa}
              // onChange={() => setCollapsed(...collapsed, process.id)}
            >
              <div className="processMakerCard">
                <Form
                  form={form}
                  onFinish={() => {
                    handleSubmit(
                      process.idProcessoProdutivoDaEmpresa,
                      process.nome,
                      process.isCatalogo
                    );
                  }}
                  name={process.idProcessoProdutivoDaEmpresa}
                >
                  <div className="processCardFields">
                    <Row gutter={24}>
                      <Col span={12}>
                        <Form.Item
                          label={<h3>Quem realiza esse processo?</h3>}
                          tooltip={tooltipCascader}
                          name={`${process.idProcessoProdutivoDaEmpresa}-processResponsable`}
                          initialValue={getSavedValues(
                            process.idProcessoProdutivoDaEmpresa,
                            process.nome,
                            process.grupoExposto
                          )}
                        >
                          <Cascader
                            showSearch={{ filter }}
                            expandTrigger="hover"
                            displayRender={displayRender}
                            multiple
                            options={options}
                            onChange={(value) =>
                              onSelectProcessAgent(value, process.nome)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Card size="small" title="">
                          <p>{`Selecionado(s):`}</p>
                          {associated[process.nome]
                            ? associated[process.nome].map((value) => (
                                <p>{`${value}`}</p>
                              ))
                            : null}
                          {/* {trabalhadoresExpostosPorCargo.length > 0 &&
                        associationType === "trabalhador"
                          ? trabalhadoresExpostosPorCargo.map((value) => (
                              <p>{`${value.nome}`}</p>
                            ))
                          : null}
                        {trabalhadoresExpostosPorSetor.length > 0 &&
                        associationType === "trabalhador"
                          ? trabalhadoresExpostosPorSetor.map((value) => (
                              <p>{`${value.nome}`}</p>
                            ))
                          : null} */}

                          <p>{`Total de trabalhadores associados ao processo: ${
                            associated[process.nome]
                              ? children
                                  .filter((child) => {
                                    if (child) {
                                      return associated[process.nome]
                                        .flat()
                                        .includes(child.label);
                                    }
                                  })
                                  .map((child) => child.value)
                                  .flat().length
                              : 0
                          }.`}</p>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                  <div className="processMakerSaveButton">
                    <Row gutter={24}>
                      <Col span={24}>
                        <Form.Item>
                          <Button loading={loading} htmlType="submit">
                            SALVAR
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </Panel>
          ))}
      </Collapse>
      <FinishStep
        completed={props.completed}
        url="realizador-processo"
        perfil={props.perfil}
        step={REALIZADOR_DE_PROCESSOS}
      />
    </div>
  );
};

export default ProcessesList;
