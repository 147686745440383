import React, { useState, useEffect } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Col, Collapse, Form, Row, Tag, Button as ButtonAnt } from "antd";
import IconSESI from "../../../../components/IconsSESI";
import SuggestedAction from "./SuggestedAction";
import PlannedAction from "./PlannedAction";
import {
  showPlanoDeAcaoVazio,
  showPlanoDeAcaoDataInvalida,
} from "../../../../components/modals";
import moment from "moment";

const ActionPlan = (props) => {
  const [actionPlan, setActionPlan] = useState([]);

  useEffect(() => {
    setActionPlan(props.planoDeAcao);
  }, [JSON.stringify(props.planoDeAcao)]);

  const { Panel } = Collapse;
  const [form] = Form.useForm();

  const checkPlanDates = (id, niveisDeRisco) => {
    const planoDeAcao = actionPlan.find((plan) => plan.idFatorDeRisco === id);
    const acoesPlanejadas = planoDeAcao.acoesPlanejadas;
    if (
      planoDeAcao.acoesPlanejadas.length === 0 &&
      (id !== 190 || id !== 191 || id !== 192 || id !== 193 || id !== 194)
    ) {
      showPlanoDeAcaoVazio();
      return false;
    } else {
      const monthDifferences = acoesPlanejadas.map(({ previsaoFim }) =>
        checkMonthsDifference(previsaoFim)
      );
      if (
        niveisDeRisco.includes("crítico") &&
        monthDifferences.filter((month) => month <= 3).length === 0
      ) {
        showPlanoDeAcaoDataInvalida("intoleravel");
        return false;
      } else if (
        niveisDeRisco.includes("alto") &&
        monthDifferences.filter((month) => month <= 9).length === 0
      ) {
        showPlanoDeAcaoDataInvalida("alto");
        return false;
      } else if (
        niveisDeRisco.includes("médio") &&
        monthDifferences.filter((month) => month <= 14).length === 0
      ) {
        showPlanoDeAcaoDataInvalida("médio");
        return false;
      } else if (
        niveisDeRisco.includes("baixo") &&
        monthDifferences.filter((month) => month <= 24).length === 0
      ) {
        showPlanoDeAcaoDataInvalida("baixo");
        return false;
      }
    }
    return true;
  };

  const checkMonthsDifference = (date) => {
    const monthDifference = moment(new Date(date)).diff(
      new Date(),
      "months",
      true
    );
    return monthDifference;
  };

  const prioridade = {
    crítico: "PR1",
    alto: "PR2",
    médio: "PR3",
    baixo: "PR4",
    irrelevante: "PR5",
  };

  return (
    <div className="action-plan-content">
      <div className="risk-factor-list">
        <Row gutter={32} justify="center">
          <Col span={24}>
            <Collapse
              expandIconPosition="right"
              bordered={false}
              expandIcon={({ isActive }) => (
                <RightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              {actionPlan.map((action) => (
                <Panel
                  className={`${
                    action.controleFatorDeRisco ? "completed" : ""
                  }`}
                  header={
                    action.controleFatorDeRisco ? (
                      <React.Fragment>
                        <div id="base"></div> <h4>{action.descricao}</h4>
                      </React.Fragment>
                    ) : (
                      <h4>{action.descricao}</h4>
                    )
                  }
                  key={action.idFatorDeRisco}
                >
                  <div className="risk-list-card">
                    <Form form={form}>
                      <div className="risk-card-content">
                        <div className="process-list">
                          <Row gutter={24}>
                            <Col span={24}>
                              {action.processosProdutivos.map((process) => (
                                <div className="process-item">
                                  <div className="process-name">
                                    <h5>Processo: {process.nome}</h5>
                                  </div>
                                  <div className="risk-level">
                                    <h5>Nível de Risco:</h5>
                                    <Tag
                                      className={process.nivelDeRisco.toUpperCase()}
                                    >
                                      {process.nivelDeRisco.toUpperCase()}
                                    </Tag>
                                    <h5>Prioridade de ação:</h5>
                                    <Tag
                                      className={process.nivelDeRisco.toUpperCase()}
                                    >
                                      {prioridade[process.nivelDeRisco]}
                                    </Tag>
                                  </div>
                                </div>
                              ))}
                            </Col>
                          </Row>
                        </div>

                        <div className="add-action-button">
                          <Row gutter={24}>
                            <Col span={24}>
                              <ButtonAnt
                                ghost
                                className="add"
                                onClick={() => {
                                  props.handleSwitchTab(
                                    "form",
                                    {},
                                    false,
                                    action.idFatorDeRisco
                                  );
                                  //props.handleTalk(2);
                                }}
                              >
                                <IconSESI type="add-circle" theme="filled" />
                                <h4 style={{ marginBottom: "0" }}>
                                  Adicionar ação
                                </h4>
                              </ButtonAnt>
                            </Col>
                          </Row>
                        </div>

                        <div className="planed-actions">
                          <h3>Ações planejadas</h3>
                          <div className="action-list">
                            <Row gutter={24}>
                              <Col span={24}>
                                <PlannedAction
                                  dispatch={props.dispatch}
                                  idFatorDeRisco={props.idFatorDeRisco}
                                  planoDeAcao={action}
                                  configEdit={props.configEdit}
                                  deleteAction={props.deleteAction}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <SuggestedAction
                          planoDeAcao={action}
                          handleSwitchTab={props.handleSwitchTab}
                          handleTalk={props.handleTalk}
                          rejectSuggestedAction={props.rejectSuggestedAction}
                        />
                        <div className="finish-button">
                          {console.log(action)}
                          <Row gutter={24}>
                            <Col span={24}>
                              <ButtonAnt
                                onClick={() => {
                                  checkPlanDates(
                                    action.idFatorDeRisco,
                                    action.processosProdutivos.map(
                                      ({ nivelDeRisco }) => nivelDeRisco
                                    )
                                  );
                                  props.finishActionPlanForRisk(
                                    props.dispatch,
                                    action.idFatorDeRisco
                                  );
                                }}
                              >
                                CONCLUIR AÇÕES DO FATOR DE RISCO
                              </ButtonAnt>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Panel>
              ))}
            </Collapse>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ActionPlan;
