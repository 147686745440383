import { Table } from "antd";
import React from "react";
import IconSESI from "../../../../components/IconsSESI";

const DocumentList = (props) => {
  const columns = [
    {
      title: "Nome do documento",
      dataIndex: "name",
      key: "name",
      // render: () => <IconSESI type="pdf" /> + ` teste`,
    },
    {
      title: "DATA",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "ARQUIVO",
      dataIndex: "type",
      key: "type",
      align: "center",
    },
    {
      title: "DOWNLOAD",
      dataIndex: "download",
      key: "download",
      align: "center",
      render: (link) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${process.env.REACT_APP_BACKEND_URL}${link}`}
          download={true}
        >
          <IconSESI type="download-document" />
        </a>
      ),
    },
  ];
  const paginationOption = {
    showSizeChanger: false,
  };
  return (
    <React.Fragment>
      <div className="table-container">
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-dark" : "table-row-martinique"
          }
          dataSource={props.tableData}
          columns={columns}
          pagination={paginationOption}
          bordered
        ></Table>
      </div>
    </React.Fragment>
  );
};

export default DocumentList;
