import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getEPCPorGrupo,
  emptyRelacaoMAOSelecionanda,
  getEPC,
} from "../../actions";
import "../../../styles.scss";

const { Option } = Select;

const RelacaoEPCList = (props) => {
  const [listaEPCsPorGrupo, setListaEPCsPorGrupo] = useState([]);
  const [form] = Form.useForm();

  const handleGroupSelection = () => {
    form
      .validateFields()
      .then((values) => {
        getEPCPorGrupo(values.riskGroup, props.dispatch);
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (id) => {
    emptyRelacaoMAOSelecionanda(props.dispatch);
    getEPC(id, props.dispatch);
  };

  useEffect(() => {
    setListaEPCsPorGrupo(props.listaEPCsPorGrupo);
  }, [JSON.stringify(props.listaEPCsPorGrupo)]);

  return (
    <React.Fragment>
      <div>
        <div>
          <Form form={form}>
            <Form.Item name="riskGroup">
              <Select
                style={{
                  width: 250,
                }}
                placeholder="Selecione um grupo de risco"
                onSelect={() => handleGroupSelection()}
              >
                <Option value="1">Físico</Option>
                <Option value="2">Químico</Option>
                <Option value="3">Biológico</Option>
                <Option value="4">Ergonômico</Option>
                <Option value="5">Acidente</Option>
                <Option value="6">Inespecífico</Option>
              </Select>
            </Form.Item>
          </Form>
          {/* <Link to="/formulario-mao">
            <Button onClick={() => handleNew()}>Adicionar MAO</Button>
          </Link> */}
        </div>
        <div className="dn-content">
          {listaEPCsPorGrupo.map((epc) => {
            return (
              <div className="fatores-de-risco-item">
                <p>Fator de risco: {epc.descricao}</p>
                <p>Código do fator de risco {epc.codigo}</p>
                {epc.epcs.map((epcItem) => {
                  return (
                    <>
                      <p>EPC: {epcItem.nome}</p>
                      <p>Código EPC: {epcItem.id}</p>
                    </>
                  );
                })}
                <Link to="/formulario-relacao-epc">
                  <Button onClick={() => handleEdit(epc.id)}>Editar</Button>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    listaEPCsPorGrupo: state.gestorDN.listaEPCsPorGrupo
      ? state.gestorDN.listaEPCsPorGrupo
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ getEPCPorGrupo, getEPC }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelacaoEPCList);
