import React, { useEffect, useState } from "react";
import Button from "../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../components/IconsSESI";
import {
  Collapse,
  Form,
  Button as ButtonAntd,
  Row,
  Col,
  Select,
  Switch,
  Input,
  Radio,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  alertNoControl,
  showRemoveRiskModal,
} from "../../../../components/modals";
import { excluirFatorDeRisco } from "../actions";

const { Panel } = Collapse;
const { Option } = Select;

const RiskList = (props) => {
  const [form] = Form.useForm();
  const [newRiskForm] = Form.useForm();
  const [inventarioDeRiscoDoProcesso, setInventarioDeRiscosDoProcesso] =
    useState({ inventarios: [], nome: "", processoProdutivoId: 0 });
  const [showMeasureFields, setShowMeasureFields] = useState({});
  const [showAccidentFields, setShowAccidentFields] = useState({});
  const [epiList, setEpiList] = useState([]);
  const [epcList, setEpcList] = useState([]);
  const [maoList, setMaoList] = useState([]);
  const [fatorDeRiscoSelected, setFatorDeRiscoSelected] = useState({});
  const [addFatorDeRiscoDisabled, setAddFatorDeRiscoDisabled] = useState(true);
  const [showSelectNewRisk, setShowSelectNewRisk] = useState(false);
  const [newRiscosFormList, setNewRiscosFormList] = useState([]);
  const [fatoresDeRiscoDisponiveis, setFatoresDeRiscoDisponiveis] = useState(
    []
  );
  const [fatoresDeRiscoTypes] = useState([
    "Físico",
    "Químico",
    "Biológico",
    "Ergonômico",
    "Acidente/Mecânico",
    "Inespecífico",
  ]);
  const [riskSavedStatus, setRiskSavedStatus] = useState({});

  useEffect(() => {
    if (props.inventarioDeRiscoDoProcesso) {
      setInventarioDeRiscosDoProcesso(props.inventarioDeRiscoDoProcesso);
      if (
        props.inventarioDeRiscoDoProcesso.inventarios &&
        props.inventarioDeRiscoDoProcesso.inventarios.length > 0
      ) {
        const measures = props.inventarioDeRiscoDoProcesso.inventarios.reduce(
          (curr, risco) => {
            return {
              ...curr,
              ...{ [risco.fatorDeRiscoNome]: risco.valorMedicao > 0 },
            };
          }
        );
        const accidents = props.inventarioDeRiscoDoProcesso.inventarios.reduce(
          (curr, risco) => {
            return {
              ...curr,
              ...{
                [risco.fatorDeRiscoNome]: risco.acidenteOuDoencaRegistrada > 1,
              },
            };
          }
        );
        setShowMeasureFields(measures);
        setShowAccidentFields(accidents);
      }
      //setAllHasMeasures(props.inventarioDeRiscoDoProcesso);
    }
  }, [JSON.stringify(props.inventarioDeRiscoDoProcesso)]);

  useEffect(() => {
    if (props.inventarioDeRiscoDoProcesso) {
      let riskMap = {};
      if (props.inventarioDeRiscoDoProcesso.inventarios) {
        props.inventarioDeRiscoDoProcesso.inventarios.map(
          (risco) =>
            (riskMap[risco.idFatorDeRisco] =
              risco.dataUltimaModificacao === null ? false : true)
        );
        setRiskSavedStatus(riskMap);
      }
    }
  }, [JSON.stringify(props.inventarioDeRiscoDoProcesso)]);

  useEffect(() => {
    if (props.epis.length > 0) {
      setEpiList(props.epis);
      setEpcList(props.epcs);
      setMaoList(props.maos);
    }
  }, [JSON.stringify(props.epis)]);

  const showModal = (id) => {
    showRemoveRiskModal(() => excluirFatorDeRisco(props.dispatch, id));
  };

  useEffect(() => {
    if (props.fatoresDeRiscoDisponiveis) {
      setFatoresDeRiscoDisponiveis(props.fatoresDeRiscoDisponiveis);
    }
  }, [JSON.stringify(props.fatoresDeRiscoDisponiveis)]);

  const getButtonsHeader = (classification, type, id) => {
    return (
      <React.Fragment>
        <h5 className="header-fake-button">
          CLASSIFICAÇÃO: {classification === 1 ? "Quantitativo" : "Qualitativo"}
        </h5>
        <h5 className="header-fake-button">TIPO: {type}</h5>
        <ButtonAntd
          icon={<CloseOutlined />}
          danger
          onClick={() => showModal(id)}
        >
          EXCLUIR RISCO
        </ButtonAntd>
      </React.Fragment>
    );
  };

  const getRiskType = (typeId) => {
    switch (typeId) {
      case 1:
        return "Físico";
      case 2:
        return "Químico";
      case 3:
        return "Biológico";
      case 4:
        return "Ergonômico";
      case 5:
        return "Acidente";
      case 6:
        return "Inespecífico";
      default:
        break;
    }
  };

  const getDangerLevel = (levelId) => {
    switch (levelId) {
      case 0:
        return "Não Aplicável";
      case 1:
        return "Baixo";
      case 2:
        return "Médio";
      case 3:
        return "Alto";
      case 4:
        return "Excessivo";
      default:
        break;
    }
  };

  const percentageList = [
    {
      value: 1,
      label: "Até 10% da empresa",
    },
    {
      value: 2,
      label: "De 10% a 30% da empresa",
    },
    {
      value: 3,
      label: "De 30% a 60% da empresa",
    },
    {
      value: 4,
      label: "Acima de 60% da empresa",
    },
  ];

  const frequencyList = [
    {
      value: 1,
      label: "1 vez por mês ou menos",
    },
    {
      value: 2,
      label: "A cada 15 dias",
    },
    {
      value: 3,
      label: "1 ou 2 vezes por semana",
    },
    {
      value: 4,
      label: "Diariamente",
    },
  ];

  const exposureTimeList = [
    {
      value: 1,
      label: "Até 2h",
    },
    {
      value: 2,
      label: "De 2h a 4h",
    },
    {
      value: 3,
      label: "De 4h a 6h",
    },
    {
      value: 4,
      label: "De 6h a 8h",
    },
  ];

  const intensityPerceptionList = [
    {
      value: 1,
      label: "Baixa",
    },
    {
      value: 2,
      label: "Média",
    },
    {
      value: 3,
      label: "Alta",
    },
    {
      value: 4,
      label: "Muito Alta",
    },
  ];

  const handleSubmit = (id, fatorDeRiscoNome) => {
    form
      .validateFields()
      .then((values) => {
        props.edit(props.dispatch, values, id, fatorDeRiscoNome);
      })
      .catch((err) => console.log(err));
  };

  const setHasMeasured = (value, riskName) => {
    setShowMeasureFields({
      ...showMeasureFields,
      ...{ [riskName]: value },
    });
  };

  const hasRegisterAccident = (value, riskName) => {
    setShowAccidentFields({
      ...showAccidentFields,
      ...{ [riskName]: value },
    });
  };

  const handleEPCChange = (value, formName) => {
    if (value.length === 0) {
      form.setFieldsValue({
        [formName]: [`Não implementa EPC-0`],
      });
    }
  };

  const handleEPIChange = (value, formName) => {
    if (value.length === 0) {
      form.setFieldsValue({
        [formName]: [`Não utiliza EPI-0`],
      });
    }
  };

  const handleMAOChange = (value, formName) => {
    if (value.length === 0) {
      form.setFieldsValue({
        [formName]: [`Não implementa MAO-0`],
      });
    }
  };

  const onSelectTipoFatorDeRisco = (id) => {
    props.getFatorDeRiscoPorTipo(props.dispatch, id);
  };

  const onSelectFatorDeRisco = (id) => {
    setFatorDeRiscoSelected(id);
  };

  const addFatorDeRisco = () => {
    newRiskForm
      .validateFields()
      .then((values) => {
        const fatoresDeRiscoBody = {
          processoProdutivoId:
            inventarioDeRiscoDoProcesso.processoProdutivoDoCatalogoId
              ? inventarioDeRiscoDoProcesso.processoProdutivoDoCatalogoId
              : inventarioDeRiscoDoProcesso.processoProdutivoId,
          fatoresDeRisco: [
            {
              id: values.risk,
              fonteGeradora: values.generator,
            },
          ],
        };
        props.addFatorDeRisco(
          props.dispatch,
          fatoresDeRiscoBody,
          newRiskForm.resetFields,
          setShowSelectNewRisk
        );
      })
      .catch((err) => console.log(err));
  };

  const toggleRiskSavedStatus = (id) => {
    setRiskSavedStatus({ ...riskSavedStatus, ...{ [id]: true } });
  };

  const checkNoControl = (id) => {
    const result = form.getFieldsValue();
    const epcs = result[`${id}-epcs`].map((value) => value.replace(/\D/g, ""));
    const epis = result[`${id}-epis`].map((value) => value.replace(/\D/g, ""));
    const maos = result[`${id}-maos`].map((value) => value.replace(/\D/g, ""));
    if (epcs.includes("0") && epis.includes("0") && maos.includes("0")) {
      alertNoControl();
    }
  };

  return (
    <React.Fragment>
      <div className="header-form ">
        <Button
          shape="circle"
          ghost
          text={<IconSESI type="arrow-left" />}
          onClick={() => {
            props.toggleTab();
            //props.getInventarioDeRisco(props.dispatch);
          }}
        />
        <h2>{`Processo: ${inventarioDeRiscoDoProcesso.nome}`}</h2>
      </div>
      <div className="content">
        <div className="add-risk-container">
          <ButtonAntd
            ghost
            className="add"
            onClick={() => setShowSelectNewRisk(true)}
          >
            <IconSESI type="add-circle" theme="filled" />
            Adicionar perigo
          </ButtonAntd>
          {showSelectNewRisk ? (
            <div className="adicionar-risco-wrapper">
              <Form
                onFinish={addFatorDeRisco}
                layout="inline"
                form={newRiskForm}
              >
                <Form.Item
                  label="Tipo de perigo"
                  name="riskType"
                  rules={[
                    {
                      required: true,
                      message: "Selecione um tipo!",
                    },
                  ]}
                >
                  <Select onSelect={onSelectTipoFatorDeRisco}>
                    {fatoresDeRiscoTypes.map((fatorDeRisco, index) => (
                      <Option key={index + 1} value={index + 1}>
                        {fatorDeRisco}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Perigo"
                  name="risk"
                  rules={[
                    {
                      required: true,
                      message: "Selecione um perigo!",
                    },
                  ]}
                  className="risk-name"
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={onSelectFatorDeRisco}
                    value={fatorDeRiscoSelected.id}
                  >
                    {fatoresDeRiscoDisponiveis
                      .filter((fatorDeRisco) => fatorDeRisco.ativo)
                      .map((fatorDeRisco) => (
                        <Option key={fatorDeRisco.id} value={fatorDeRisco.id}>
                          {fatorDeRisco.nome}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Fonte geradora do perigo"
                  name="generator"
                  rules={[
                    {
                      required: true,
                      message: "Selecione uma fonte geradora!",
                    },
                  ]}
                >
                  <Input type="text"></Input>
                </Form.Item>
                <Form.Item>
                  <Button
                    ghost
                    text="Adicionar"
                    style={{ padding: "6px 15px", width: "auto" }}
                    htmlType="submit"
                  />
                </Form.Item>
              </Form>
            </div>
          ) : null}
        </div>
        {inventarioDeRiscoDoProcesso.inventarios ? (
          <div className="risks-container">
            <Collapse
              defaultActiveKey={inventarioDeRiscoDoProcesso.inventarios.map(
                (risco) => risco.id
              )}
            >
              {inventarioDeRiscoDoProcesso.inventarios.map((risco) =>
                risco.fatorDeRiscoNome.toLowerCase().includes("ausência") ? (
                  <Panel
                    header={<h4>{`PERIGO: ${risco.fatorDeRiscoNome}`}</h4>}
                    key={risco.id}
                    extra={getButtonsHeader(
                      risco.fatorDeRiscoClassificacao,
                      getRiskType(risco.fatorDeRiscoTipo),
                      risco.id
                    )}
                    collapsible={"disabled"}
                    className="not-collapsible"
                  ></Panel>
                ) : (
                  <Panel
                    //showArrow={false}
                    header={<h4>{`PERIGO: ${risco.fatorDeRiscoNome}`}</h4>}
                    key={risco.id}
                    extra={getButtonsHeader(
                      risco.fatorDeRiscoClassificacao,
                      getRiskType(risco.fatorDeRiscoTipo),
                      risco.id
                    )}
                  >
                    <Form
                      form={form}
                      onFinish={() =>
                        handleSubmit(risco.id, risco.fatorDeRiscoNome)
                      }
                      name={risco.id}
                    >
                      <div className="field-div">
                        <Row gutter={24}>
                          <Col span={12} className="risk-info">
                            <h3>
                              Percentual de trabalhadores em contato com o
                              risco:
                            </h3>{" "}
                            <h4>
                              {
                                percentageList[
                                  risco.indiceDePessoasExpostas - 1
                                ].label
                              }
                            </h4>
                          </Col>
                          <Col span={12} className="risk-info">
                            <h3>Fonte ou circunstância geradora:</h3>{" "}
                            <h4>{risco.fonteGeradora}</h4>
                          </Col>
                        </Row>
                      </div>

                      <div className="field-div">
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              label={<h3>Frequência: </h3>}
                              name={`${risco.id}-frequency`}
                              initialValue={
                                risco.frequencia
                                  ? frequencyList[risco.frequencia - 1].value
                                  : frequencyList[0].value
                              }
                            >
                              <Select
                                onSelect={() =>
                                  toggleRiskSavedStatus(risco.idFatorDeRisco)
                                }
                              >
                                {frequencyList.map((frequency) => (
                                  <Option
                                    key={frequency.value}
                                    value={frequency.value}
                                  >
                                    {frequency.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={<h3>Tempo de exposição: </h3>}
                              name={`${risco.id}-exposureTime`}
                              initialValue={
                                risco.tempoDeExposicao
                                  ? exposureTimeList[risco.tempoDeExposicao - 1]
                                      .value
                                  : exposureTimeList[0].value
                              }
                            >
                              <Select
                                onSelect={() =>
                                  toggleRiskSavedStatus(risco.idFatorDeRisco)
                                }
                              >
                                {exposureTimeList.map((exposure) => (
                                  <Option
                                    key={exposure.value}
                                    value={exposure.value}
                                  >
                                    {exposure.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>

                      <div className="field-div">
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              label={<h3>Percepção de intensidade: </h3>}
                              name={`${risco.id}-intensityPerception`}
                              initialValue={
                                risco.percepcaoDeIntensidade
                                  ? intensityPerceptionList[
                                      risco.percepcaoDeIntensidade - 1
                                    ].value
                                  : intensityPerceptionList[0].value
                              }
                            >
                              <Select
                                onSelect={() =>
                                  toggleRiskSavedStatus(risco.idFatorDeRisco)
                                }
                              >
                                {intensityPerceptionList.map((perception) => (
                                  <Option
                                    key={perception.value}
                                    value={perception.value}
                                  >
                                    {perception.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12} className="risk-info">
                            <h3>Meio de propagação:</h3>{" "}
                            <h4>{risco.fatorDeRiscoMeioDePropagacao}</h4>
                          </Col>
                        </Row>
                      </div>

                      {risco.fatorDeRiscoClassificacao === 1 ? (
                        <div className="field-div">
                          <Row gutter={24}>
                            <Col span={24}>
                              <Form.Item
                                label={
                                  <h3>
                                    Já foi realizado algum processo de medição
                                    na empresa?
                                  </h3>
                                }
                                name={`${risco.id}-hasMeasured`}
                                initialValue={risco.valorMedicao > 0}
                              >
                                <Switch
                                  checkedChildren="Sim"
                                  unCheckedChildren="Não"
                                  onChange={(value) => {
                                    setHasMeasured(
                                      value,
                                      risco.fatorDeRiscoNome
                                    );
                                    toggleRiskSavedStatus(risco.idFatorDeRisco);
                                  }}
                                  defaultChecked={risco.valorMedicao > 0}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                      {showMeasureFields[risco.fatorDeRiscoNome] ? (
                        <div className="field-div measure-div">
                          <Row gutter={24}>
                            <Col span={6}>
                              <Form.Item
                                label={<h3>Limite de tolerância: </h3>}
                                name={`${risco.id}-toleranceLimit`}
                                initialValue={
                                  risco.limiteDeToleranciaLimite === -1
                                    ? ""
                                    : risco.limiteDeToleranciaLimite
                                }
                              >
                                <Input></Input>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label={<h3>Nível de ação: </h3>}
                                name={`${risco.id}-actionLevel`}
                                initialValue={
                                  risco.limiteDeToleranciaNivelDeAcao === -1 ||
                                  risco.limiteDeToleranciaNivelDeAcao === -2 ||
                                  risco.limiteDeToleranciaNivelDeAcao === 0
                                    ? ""
                                    : risco.limiteDeToleranciaNivelDeAcao
                                }
                              >
                                <Input></Input>
                              </Form.Item>
                            </Col>
                            <Col span={10}>
                              <Form.Item
                                label={
                                  <h3>{`Resultado da medição do risco  ${
                                    risco.limiteDeToleranciaUnidadeDeMedida
                                      ? `(em ${risco.limiteDeToleranciaUnidadeDeMedida})`
                                      : ""
                                  }:`}</h3>
                                }
                                name={`${risco.id}-measure`}
                                initialValue={risco.valorMedicao}
                              >
                                <Input></Input>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="field-div">
                        <Row gutter={24}>
                          <Col span={16} className="risk-info">
                            <h3>Possíveis lesões ou agravos à saúde:</h3>{" "}
                            <h4>{risco.fatorDeRiscoDanosPossiveis}</h4>
                          </Col>
                          <Col span={8} className="risk-info">
                            <h3>Índice de gravidade:</h3>
                            <h4>{getDangerLevel(risco.indiceGravidade)}</h4>
                          </Col>
                        </Row>
                      </div>

                      <div className="field-div">
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              className="accident-label"
                              label={
                                <>
                                  <h3>
                                    Acidente/doença registrado nos últimos 2
                                    anos
                                  </h3>
                                  <h3>relacionado a esse perigo: </h3>
                                </>
                              }
                              initialValue={
                                risco.acidenteOuDoencaRegistrada > 1
                                  ? true
                                  : false
                              }
                              name={`${risco.id}-hasRegisterAccident`}
                            >
                              <Switch
                                checkedChildren="Sim"
                                unCheckedChildren="Não"
                                onChange={(value) => {
                                  hasRegisterAccident(
                                    value,
                                    risco.fatorDeRiscoNome
                                  );
                                  toggleRiskSavedStatus(risco.idFatorDeRisco);
                                }}
                                defaultChecked={
                                  risco.acidenteOuDoencaRegistrada > 1
                                }
                              />
                            </Form.Item>
                          </Col>
                          {showAccidentFields[risco.fatorDeRiscoNome] ? (
                            <Col span={12}>
                              <Form.Item
                                initialValue={
                                  risco.acidenteOuDoencaRegistrada === 1 ||
                                  risco.acidenteOuDoencaRegistrada === 0
                                    ? undefined
                                    : risco.acidenteOuDoencaRegistrada
                                }
                                name={`${risco.id}-accidentTime`}
                                rules={[
                                  {
                                    required: true,
                                    message: "Selecione uma opção!",
                                  },
                                ]}
                              >
                                {/* Don't forget to implement "Não aplicável value={0}" options */}
                                <Radio.Group>
                                  <Radio value={2}>1 a 2 anos atrás</Radio>
                                  <Radio value={3}>No último ano</Radio>
                                  <Radio value={4}>Nos últimos 6 meses</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>

                      <div className="tags-div">
                        <div className="field-div">
                          <Row gutter={24}>
                            <Col span={24}>
                              <Form.Item
                                label={
                                  <h3>
                                    MPC (Medida de Proteção Coletiva)
                                    Implementado:{" "}
                                  </h3>
                                }
                                name={`${risco.id}-epcs`}
                                initialValue={
                                  risco.epcs.length > 0
                                    ? risco.epcs.map(
                                        (epc) => `${epc.nome}-${epc.id}`
                                      )
                                    : ["Não implementa EPC-0"]
                                }
                              >
                                <Select
                                  mode="tags"
                                  onChange={(value) =>
                                    handleEPCChange(value, `${risco.id}-epcs`)
                                  }
                                >
                                  {epcList
                                    .filter(
                                      (epc) =>
                                        epc.idFatores.includes(
                                          risco.idFatorDeRisco
                                        ) || epc.id === 0
                                    )
                                    .map((epc) => (
                                      <Option
                                        key={epc.id}
                                        value={`${epc.nome}-${epc.id}`}
                                      >
                                        {epc.nome}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div className="field-div">
                          <Row gutter={24}>
                            <Col span={24}>
                              <Form.Item
                                label={<h3>EPI Implementado: </h3>}
                                name={`${risco.id}-epis`}
                                initialValue={
                                  risco.epis.length > 0
                                    ? risco.epis.map(
                                        (epi) => `${epi.nome}-${epi.id}`
                                      )
                                    : ["Não utiliza EPI-0"]
                                }
                              >
                                <Select
                                  mode="tags"
                                  onChange={(value) =>
                                    handleEPIChange(value, `${risco.id}-epis`)
                                  }
                                >
                                  {epiList
                                    .filter(
                                      (epi) =>
                                        epi.idFatores.includes(
                                          risco.idFatorDeRisco
                                        ) || epi.id === 0
                                    )
                                    .map((epi) => (
                                      <Option
                                        key={epi.id}
                                        value={`${epi.nome}-${epi.id}`}
                                      >
                                        {epi.nome}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div className="field-div">
                          <Row gutter={24}>
                            <Col span={24}>
                              <Form.Item
                                label={
                                  <h3>
                                    MAO (Medida Administrativa ou
                                    Organizacional) Implementado:
                                  </h3>
                                }
                                name={`${risco.id}-maos`}
                                initialValue={
                                  risco.maos.length > 0
                                    ? risco.maos.map(
                                        (mao) => `${mao.nome}-${mao.id}`
                                      )
                                    : ["Não implementa MAO-0"]
                                }
                              >
                                <Select
                                  mode="tags"
                                  onChange={(value) =>
                                    handleMAOChange(value, `${risco.id}-maos`)
                                  }
                                >
                                  {maoList
                                    .filter(
                                      (mao) =>
                                        mao.idFatores.includes(
                                          risco.idFatorDeRisco
                                        ) || mao.id === 0
                                    )
                                    .map((mao) => (
                                      <Option
                                        key={mao.id}
                                        value={`${mao.nome}-${mao.id}`}
                                      >
                                        {mao.nome}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div style={{ display: "none" }}>
                          <Form.Item
                            name={`${risco.id}-fatorDeRiscoNome`}
                            initialValue={risco.fatorDeRiscoNome}
                          ></Form.Item>
                        </div>
                      </div>

                      <div className="field-div save-button-div">
                        <Row gutter={24}>
                          <Col span={24}>
                            <Form.Item>
                              <ButtonAntd
                                htmlType="submit"
                                onClick={() => checkNoControl(risco.id)}
                              >
                                {riskSavedStatus[risco.idFatorDeRisco] === false
                                  ? "Confirmo os dados sem alteração"
                                  : "Salvar"}
                              </ButtonAntd>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </Panel>
                )
              )}
            </Collapse>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default RiskList;
