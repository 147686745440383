import { Col, Row, Button } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import React, { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Assistant from "../../../../components/VirtualAssistant";
import { autodeclaracaoTalks } from "../../../../constants/aroldoTalks";
import LogoUpload from "../../../Caracterizacao/_/components/CaracterizacaoEmpresa/_/components/LogoUpload";
import {
  captureLead,
  generateAutodeclaracao,
  finishAutodeclaracao,
} from "../actions";
import { connect } from "react-redux";

const GerarAutodeclaracao = (props) => {
  const [link, setLink] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const [deleteLogo, setDeleteLogo] = useState([]);

  const getLink = (url) => {
    setLink(url.data.url);
    setIsDisabled(false);
  };

  const checkLead = () => {
    const isLeadChecked = document.getElementById("lead-checkbox").checked;
    if (isLeadChecked) {
      captureLead("declaracao");
    }
  };

  const getLogoFormData = (logo) => {
    if (logo.name) {
      let formData = new FormData();
      formData.append("logo", logo);
      setLogo(formData);
    } else {
      setLogo(null);
    }
  };

  const getDeletedLogoFormData = () => {
    let formData = new FormData();
    formData.append("removerLogo", 1);
    setDeleteLogo(formData);
  };

  const logoLoadingToggle = (bool) => {
    setLogoLoading(bool);
  };

  const openSurvey = () => {
    const newWindows = window.open(
      "https://evaluate.typeform.com/to/Xr66h8tU",
      "name",
      "height=500,width=550"
    );
    if (window.focus) {
      newWindows.focus();
    }
    return false;
  };

  return (
    <div className="gerar-autodeclaracao-wrapper">
      <Row gutter={24}>
        <Col span={24}>
          <Assistant talk={autodeclaracaoTalks[6]} />
        </Col>
      </Row>
      <Row gutter={24} className="logo-upload-wrapper">
        <Col span={24}>
          <LogoUpload
            getLogoFormData={getLogoFormData}
            getDeletedLogoFormData={getDeletedLogoFormData}
            perfil={props.perfil}
            logoLoading={logoLoading}
          />
        </Col>
      </Row>
      <Row gutter={24} className="buttons-wrapper">
        <Col span={12} className="generate-selfdeclaration-wrapper">
          <ButtonGroup>
            <div className="generate-selfdeclaration-button">
              <Button
                onClick={() => {
                  checkLead();
                  generateAutodeclaracao(
                    getLink,
                    setLoading,
                    logo,
                    logoLoadingToggle,
                    deleteLogo
                  );
                  finishAutodeclaracao();
                  openSurvey();
                }}
                loading={loading}
              >
                <h3>GERAR AUTODECLARAÇÃO</h3>
              </Button>
            </div>
            <div className="download-button">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_BACKEND_URL}${link}`}
                download={true}
              >
                <Button
                  onClick={() => console.log("ok")}
                  disabled={isDisabled}
                  // loading={loading}
                >
                  <DownloadOutlined />
                </Button>
              </a>
            </div>
          </ButtonGroup>
        </Col>
        <Col span={12}>
          <Link>
            <div className="go-to-pgr-button">
              <Link to={"/caracterizacao"}>
                <Button>
                  <h3>IR PARA O PGR</h3>
                </Button>
              </Link>
            </div>
          </Link>
        </Col>
      </Row>
      {/* <Row style={{ marginTop: "250px" }}>
        <Col span={24}>
          <h3 style={{ textAlign: "center" }}>
            {props.companySelected.premium ? (
              <bold>
                Que tal emitir o eSocial a partir da sua autodeclaração? Clique{" "}
                <a href="#" style={{ textDecoration: "underline" }}>
                  aqui
                </a>{" "}
                para começar.
              </bold>
            ) : (
              <bold>
                Aproveite para assinar a versão premium e enviar o eSocial de
                sua auto declaração. Clique{" "}
                <a href="#" style={{ textDecoration: "underline" }}>
                  aqui
                </a>{" "}
                para garantir sua assinatura.
              </bold>
            )}
          </h3>
        </Col>
      </Row> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companySelected: state.caracterizacao.companySelected,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     dispatch,
//     ...bindActionCreators({
//       listQuestions,
//     }),
//   };
// };

export default connect(mapStateToProps, {})(GerarAutodeclaracao);
