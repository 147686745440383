import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  getEPIPorGrupo,
  getEPI,
  emptyRelacaoEPISelecionanda,
} from "../../actions";
import "../../../styles.scss";

const { Option } = Select;
const { Search } = Input;

const EPIList = (props) => {
  const [listaEPIsPorGrupo, setListaEPIsPorGrupo] = useState([]);
  const [form] = Form.useForm();

  const handleGroupSelection = () => {
    form
      .validateFields()
      .then((values) => {
        getEPIPorGrupo(values.riskGroup, props.dispatch);
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (id) => {
    emptyRelacaoEPISelecionanda(props.dispatch);
    getEPI(id, props.dispatch);
  };

  const handleNew = () => {
    emptyRelacaoEPISelecionanda(props.dispatch);
  };

  useEffect(() => {
    setListaEPIsPorGrupo(props.listaEPIsPorGrupo);
  }, [JSON.stringify(props.listaEPIsPorGrupo)]);

  return (
    <React.Fragment>
      <div>
        <div>
          <Form form={form}>
            <Form.Item name="riskGroup">
              <Select
                style={{
                  width: 250,
                }}
                placeholder="Selecione um grupo de risco"
                onSelect={() => handleGroupSelection()}
              >
                <Option value="1">Físico</Option>
                <Option value="2">Químico</Option>
                <Option value="3">Biológico</Option>
                <Option value="4">Ergonômico</Option>
                <Option value="5">Acidente</Option>
                <Option value="6">Inespecífico</Option>
              </Select>
            </Form.Item>
          </Form>
          {/* <Link to="/formulario-mao">
            <Button onClick={() => handleNew()}>Adicionar MAO</Button>
          </Link> */}
        </div>
        <div className="dn-content">
          {listaEPIsPorGrupo.map((epi) => {
            return (
              <div className="fatores-de-risco-item">
                <p>Fator de risco: {epi.descricao}</p>
                <p>Código do fator de risco {epi.codigo}</p>
                {epi.epis.map((epiItem) => {
                  return (
                    <>
                      <p>EPI: {epiItem.nome}</p>
                      <p>Código EPI: {epiItem.id}</p>
                    </>
                  );
                })}
                <Link to="/formulario-epi">
                  <Button onClick={() => handleEdit(epi.id)}>Editar</Button>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userInfo,
    listaEPIsPorGrupo: state.gestorDN.listaEPIsPorGrupo
      ? state.gestorDN.listaEPIsPorGrupo
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ getEPIPorGrupo }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EPIList);
