import axios from "axios";
import openNotification from "../../../../components/notifications";
import {
  deletarFatorDeRisco,
  epcepimaoUrl,
  fatorDeRiscoEditURL,
  inventarioDeRiscoPorProcesso,
  inventarioUrl,
  fatorDeRiscoPorTipo,
  inventariosFatoresDeRiscoProcessosUrl,
  processosProdutivos,
} from "../../../../constants";
import {
  LISTAR_INVENTARIO_DE_RISCO,
  LISTAR_INVENTARIO_DE_RISCO_DO_PROCESSO,
  LISTAR_EPC_EPI_MAO,
  REMOVER_INVENTARIO_DE_RISCO,
  LISTAR_FATORES_DE_RISCO_DISPONIVEIS,
} from "./types";

const risksDictionary = {
  crítico: "Crítico",
  alto: "Alto",
  médio: "Médio",
  baixo: "Baixo",
  irrelevante: "Irrelevante",
};

export const editRiskInventory = (dispatch, values, id, fatorDeRiscoNome) => {
  axios
    .patch(fatorDeRiscoEditURL(id), riskInventoryData(values, id))
    .then((response) => {
      openNotification(
        "Salvo com sucesso!",
        `Nível de risco para ${fatorDeRiscoNome}: ${
          risksDictionary[response.data.nivelDeRisco]
        }`,
        "success"
      );
      getInventarioDeRisco(dispatch);
    })
    .catch();
};

const getMeasureInt = (measure, limite, acao, fatorDeRiscoNome) => {
  const medida = parseFloat(measure);
  const limiteTolerancia = parseFloat(limite);
  const nivelAcao = parseFloat(acao);
  let indice;
  if (fatorDeRiscoNome === "Ruído contínuo ou intermitente (trabalhista)") {
    if (medida < nivelAcao) {
      indice = 1;
    } else if (medida >= nivelAcao && medida <= limiteTolerancia) {
      indice = 2;
    } else if (medida >= limiteTolerancia && medida <= 90) {
      indice = 3;
    } else if (medida >= limiteTolerancia && medida >= 90) {
      indice = 4;
    }
    return indice;
  } else if (fatorDeRiscoNome === "Temperaturas anormais (calor)") {
    if (medida < nivelAcao) {
      indice = 1;
    } else if (medida >= nivelAcao && medida <= limiteTolerancia) {
      indice = 2;
    } else if (medida > limiteTolerancia) {
      indice = 4;
    }
    return indice;
  } else {
    if (medida < nivelAcao) {
      indice = 1;
    } else if (medida >= nivelAcao && medida <= limiteTolerancia) {
      indice = 2;
    } else if (medida >= limiteTolerancia && medida <= limiteTolerancia * 2) {
      indice = 3;
    } else if (medida >= limiteTolerancia && medida >= limiteTolerancia * 2) {
      indice = 4;
    }
    return indice;
  }
};

const riskInventoryData = (values, id) => {
  const riskInventoryObj = {
    frequencia: values[`${id}-frequency`],
    tempoDeExposicao: values[`${id}-exposureTime`],
    percepcaoIntensidade: values[`${id}-intensityPerception`],
    limiteTolerancia: values[`${id}-toleranceLimit`],
    nivelAcao: parseFloat(values[`${id}-actionLevel`]),
    resultadoMedicao: values[`${id}-hasMeasured`]
      ? getMeasureInt(
          values[`${id}-measure`],
          values[`${id}-toleranceLimit`],
          parseFloat(values[`${id}-actionLevel`]),
          values[`${id}-fatorDeRiscoNome`]
        )
      : 0,
    realizouMedicao: values[`${id}-hasMeasured`] ? 1 : 0,
    valorMedicao: parseFloat(values[`${id}-measure`]),
    acidenteRegistrado: values[`${id}-hasRegisterAccident`]
      ? values[`${id}-accidentTime`]
      : 1,
    epcs: values[`${id}-epcs`].map((value) => value.replace(/\D/g, "")),
    epis: values[`${id}-epis`].map((value) => value.replace(/\D/g, "")),
    maos: values[`${id}-maos`].map((value) => value.replace(/\D/g, "")),
  };
  return riskInventoryObj;
};

export const getInventarioDeRisco = (dispatch) => {
  axios
    .get(inventarioUrl)
    .then((response) => {
      dispatch({
        type: LISTAR_INVENTARIO_DE_RISCO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getInventarioDeRiscoByProcess = (dispatch, id) => {
  axios
    .get(inventarioDeRiscoPorProcesso(id))
    .then((response) => {
      dispatch({
        type: LISTAR_INVENTARIO_DE_RISCO_DO_PROCESSO,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getProcessWithoutInventarioDeRisco = (dispatch, id) => {
  axios
    .get(`${processosProdutivos}/${id}`)
    .then((response) => {
      dispatch({
        type: LISTAR_INVENTARIO_DE_RISCO_DO_PROCESSO,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getInventarioDeRiscoByProcessIds = (ids, callback) => {
  let idsInventarioDeRiscoDoProcesso = {};
  Promise.all(
    ids.map((id) => axios.get(inventarioDeRiscoPorProcesso(id)))
  ).then((responses) => {
    responses.map((response) => {
      idsInventarioDeRiscoDoProcesso[response.data.processoProdutivoId] =
        response.data.inventarios.map((risco) => risco.id);
      return response;
    });
    callback(idsInventarioDeRiscoDoProcesso);
  });
};

export const getEPCEPIMAO = (dispatch) => {
  axios
    .get(epcepimaoUrl)
    .then((response) => {
      dispatch({
        type: LISTAR_EPC_EPI_MAO,
        payload: response.data,
      });
    })
    .catch();
};

export const excluirFatorDeRisco = (dispatch, id) => {
  axios
    .delete(deletarFatorDeRisco(id))
    .then(() => {
      dispatch({
        type: REMOVER_INVENTARIO_DE_RISCO,
        payload: id,
      });
      openNotification("Perigo excluído com sucesso!", "", "success");
      getInventarioDeRisco(dispatch);
    })
    .catch();
};

export const getFatorDeRiscoPorTipo = (dispatch, tipo) => {
  axios
    .get(fatorDeRiscoPorTipo(tipo))
    .then((response) => {
      dispatch({
        type: LISTAR_FATORES_DE_RISCO_DISPONIVEIS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const addFatorDeRisco = (
  dispatch,
  fatoresDeRiscoBody,
  resetFields,
  toggleShowNewRisk,
  newId
) => {
  if (newId) fatoresDeRiscoBody.processoProdutivoId = newId;
  axios
    .post(inventariosFatoresDeRiscoProcessosUrl, fatoresDeRiscoBody)
    .then(() => {
      openNotification("Perigo adicionado com sucesso!", "", "success");
      axios
        .get(
          inventarioDeRiscoPorProcesso(fatoresDeRiscoBody.processoProdutivoId)
        )
        .then((response) => {
          dispatch({
            type: LISTAR_INVENTARIO_DE_RISCO_DO_PROCESSO,
            payload: response.data,
          });
          getInventarioDeRisco(dispatch);
          if (resetFields) resetFields();
          if (toggleShowNewRisk) toggleShowNewRisk();
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addAusenciaDeRisco = (dispatch, processoProdutivoId) => {
  axios
    .post(
      inventariosFatoresDeRiscoProcessosUrl,
      ausenciaDeRiscoBody(processoProdutivoId)
    )
    .then(() => {
      openNotification(
        "Ausência de risco adicionada com sucesso!",
        "",
        "success"
      );
      axios
        .get(inventarioUrl)
        .then((response) => {
          dispatch({
            type: LISTAR_INVENTARIO_DE_RISCO,
            payload: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

const ausenciaDeRiscoBody = (processoProdutivoId) => {
  return {
    fatoresDeRisco: [
      {
        fonteGeradora: "-",
        id: 190,
      },
    ],
    processoProdutivoId: processoProdutivoId,
  };
};
