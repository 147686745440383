import { Button as ButtonAnt, Col, Row, Progress, List } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Button from "../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../components/IconsSESI";
import {
  showFinishQuestionQuestionario,
  showFinishAutodeclaracaoQuestionario,
  showOptionToGoToPGR,
} from "../../../../components/modals";
import {
  listQuestions,
  answerQuestion,
  getCompanyAnswers,
  finishQuestionario,
  finishAutodeclaracao,
} from "../actions";

const Perguntas = (props) => {
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({
    id: "",
    pergunta: "",
    descricao: "",
    subperguntas: [{ id: "", desc_pergunta: "" }],
  });
  const [showPerguntasSecundarias, setShowPerguntasSecundarias] =
    useState(false);
  const [continueQuestions, setContinueQuestions] = useState(false);

  useEffect(() => {
    getCompanyAnswers(props.dispatch);
    listQuestions(props.dispatch);
  }, []);

  useEffect(() => {
    setContinueQuestions(props.continue);
  }, [props.continue]);

  useEffect(() => {
    if (props.respostasAutodeclaracao.length > 0) {
      setAnswers(props.respostasAutodeclaracao);
      const respostasIds = props.respostasAutodeclaracao.map(
        ({ pergunta }) => pergunta
      );
      if (
        respostasIds.includes(7) &&
        props.respostasAutodeclaracao.find(
          (resposta) => resposta.pergunta === 7
        ).resposta === false &&
        continueQuestions
      ) {
        setCurrentQuestionIndex(3);
        props.changeTalk(4);
        setContinueQuestions(false);
      } else if (
        respostasIds.includes(6) &&
        props.respostasAutodeclaracao.find(
          (resposta) => resposta.pergunta === 6
        ).resposta === false &&
        continueQuestions
      ) {
        setCurrentQuestionIndex(2);
        props.changeTalk(3);
        setContinueQuestions(false);
      } else if (
        respostasIds.includes(5) &&
        props.respostasAutodeclaracao.find(
          (resposta) => resposta.pergunta === 5
        ).resposta === false &&
        continueQuestions
      ) {
        setCurrentQuestionIndex(2);
        props.changeTalk(3);
        setContinueQuestions(false);
      } else if (
        respostasIds.includes(1) &&
        props.respostasAutodeclaracao.find(
          (resposta) => resposta.pergunta === 1
        ).resposta === false &&
        continueQuestions
      ) {
        setCurrentQuestionIndex(1);
        props.changeTalk(2);
        setContinueQuestions(false);
      } else if (
        respostasIds.includes(5) &&
        props.respostasAutodeclaracao.find(
          (resposta) => resposta.pergunta === 5
        ).resposta === true &&
        continueQuestions
      ) {
        setShowPerguntasSecundarias(true);
        setContinueQuestions(false);
      } else if (
        respostasIds.includes(1) &&
        props.respostasAutodeclaracao.find(
          (resposta) => resposta.pergunta === 1
        ).resposta === true &&
        continueQuestions
      ) {
        setShowPerguntasSecundarias(true);
        setContinueQuestions(false);
      }
      // if (
      //   respostasIds.includes(1) &&
      //   respostasIds.includes(2) &&
      //   respostasIds.includes(3) &&
      //   respostasIds.includes(4) &&
      //   respostasIds.length === 4 &&
      //   continueQuestions
      // ) {
      //   setCurrentQuestionIndex(1);
      //   setContinueQuestions(false);
      // } else if (
      //   respostasIds.includes(1) &&
      //   respostasIds.length < 4 &&
      //   continueQuestions
      // ) {
      //   setShowPerguntasSecundarias(true);
      //   setContinueQuestions(false);
      // } else if (
      //   respostasIds.includes(1) &&
      //   respostasIds.includes(2) &&
      //   respostasIds.includes(3) &&
      //   respostasIds.includes(4) &&
      //   respostasIds.includes(5) &&
      //   respostasIds.length === 5 &&
      //   continueQuestions
      // ) {
      //   setCurrentQuestionIndex(1);
      //   setContinueQuestions(false);
      // } else if (
      //   respostasIds.includes(5) &&
      //   respostasIds.includes(6) &&
      //   respostasIds.length === 6 &&
      //   continueQuestions
      // ) {
      //   setCurrentQuestionIndex(2);
      //   setContinueQuestions(false);
      // } else if (
      //   respostasIds.includes(5) &&
      //   respostasIds.length < 6 &&
      //   continueQuestions
      // ) {
      //   setShowPerguntasSecundarias(true);
      //   setContinueQuestions(false);
      // }
    }
  }, [props.respostasAutodeclaracao]);

  useEffect(() => {
    if (props.perguntasAutodeclaracao.length > 0) {
      setQuestions(props.perguntasAutodeclaracao);
      setCurrentQuestion(props.perguntasAutodeclaracao[currentQuestionIndex]);
      if (answers) {
        const currentQuestion = answers.find(
          (answer) => answer.pergunta === currentQuestionIndex + 1
        );
        if (
          currentQuestion &&
          currentQuestionIndex === 0 &&
          currentQuestion.resposta
        ) {
          setShowPerguntasSecundarias(true);
        } else if (
          currentQuestion &&
          currentQuestionIndex === 1 &&
          currentQuestion.resposta
        ) {
          setShowPerguntasSecundarias(true);
        }
      }
    }
  }, [currentQuestionIndex, props.perguntasAutodeclaracao]);

  const handleNextQuestion = () => {
    currentQuestionIndex < questions.length - 1
      ? setCurrentQuestionIndex(currentQuestionIndex + 1)
      : setCurrentQuestionIndex(questions.length - 1);
    setShowPerguntasSecundarias(false);
    props.changeTalk(currentQuestionIndex + 2);
  };

  const goToPGR = () => {
    finishQuestionario();
    finishAutodeclaracao();
    setTimeout(document.getElementById("caracterizacao-link").click(), 3000);
  };

  const goToAutodeclaracao = () => {
    finishQuestionario();
    document.getElementById("autodeclaracao-link").click();
  };

  return (
    <div className="perguntas-wrapper container">
      <div style={{ display: "none" }}>
        <Link to={"/gerar-autodeclaracao"} id="autodeclaracao-link"></Link>
      </div>
      <div style={{ display: "none" }}>
        <Link to={"/caracterizacao"} id="caracterizacao-link"></Link>
      </div>
      <div className="content">
        <React.Fragment>
          <Row gutter={24}>
            <Col span={24}>
              <div className="card-pergunta pergunta-pai">
                <div className="texto-pergunta-pai">
                  <h3>{currentQuestion.pergunta}</h3>
                  {currentQuestion.id !== 1 ? (
                    <Button
                      shape="circle"
                      ghost
                      className="pergunta-previa"
                      text={<IconSESI type="arrow-left" />}
                      onClick={() =>
                        currentQuestionIndex > 0
                          ? (setCurrentQuestionIndex(currentQuestionIndex - 1),
                            props.changeTalk(currentQuestionIndex))
                          : (setCurrentQuestionIndex(0), props.changeTalk(1))
                      }
                    />
                  ) : null}
                </div>
                <Row gutter={24}>
                  {currentQuestion.descricao ? (
                    <Col span={24}>
                      <div className="activity-list">
                        <List
                          size="large"
                          dataSource={currentQuestion.descricao.split(";")}
                          renderItem={(item) => (
                            <List.Item className="activity-list-item">
                              <h4>{item}</h4>
                            </List.Item>
                          )}
                        />
                      </div>
                    </Col>
                  ) : null}
                </Row>
                <div className="botoes-pergunta">
                  <ButtonAnt
                    className={`no-button ${
                      answers.find(
                        (answer) =>
                          answer.pergunta === currentQuestion.id &&
                          answer.resposta === true
                      )
                        ? "answered"
                        : ""
                    }`}
                    onClick={() => {
                      if (
                        currentQuestion.id === 7 ||
                        currentQuestion.id === 8 ||
                        currentQuestion.id === 5 ||
                        currentQuestion.id === 6
                      ) {
                        showOptionToGoToPGR(goToPGR);
                      } else {
                        answerQuestion(
                          props.dispatch,
                          currentQuestion.id,
                          true
                        );
                        setShowPerguntasSecundarias(true);
                      }
                    }}
                  >
                    SIM
                  </ButtonAnt>
                  <ButtonAnt
                    className={`no-button ${
                      answers.find(
                        (answer) =>
                          answer.pergunta === currentQuestion.id &&
                          answer.resposta === false
                      )
                        ? "answered"
                        : ""
                    }`}
                    onClick={() => {
                      answerQuestion(props.dispatch, currentQuestion.id, false);
                      if (currentQuestion.id === 8) {
                        showFinishAutodeclaracaoQuestionario(
                          goToAutodeclaracao
                        );
                      } else {
                        handleNextQuestion();
                      }
                    }}
                  >
                    NÃO
                  </ButtonAnt>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <div className="barra-progresso">
                <Progress
                  percent={
                    ((currentQuestionIndex + 1) / questions.length) * 100
                  }
                  showInfo={false}
                  strokeColor="var(--comet)"
                  trailColor="var(--ebony)"
                />
              </div>
            </Col>
          </Row>
          {showPerguntasSecundarias ? (
            <Row gutter={24}>
              <Col span={24}>
                <div className="perguntas-filhas-wrapper">
                  {currentQuestion.subperguntas.map((subpergunta) => {
                    return (
                      <div className="card-pergunta pergunta-filha">
                        <Row gutter={24}>
                          <Col span={12}>
                            <h4>{subpergunta.pergunta}</h4>
                          </Col>
                          <Col className="coluna-botoes" span={12}>
                            <ButtonAnt
                              className={`no-button ${
                                answers.find(
                                  (answer) =>
                                    answer.pergunta === subpergunta.id &&
                                    answer.resposta === true
                                )
                                  ? "answered"
                                  : ""
                              }`}
                              onClick={() => {
                                answerQuestion(
                                  props.dispatch,
                                  subpergunta.id,
                                  true
                                );
                                showOptionToGoToPGR(goToPGR);
                              }}
                            >
                              SIM
                            </ButtonAnt>
                            <ButtonAnt
                              className={`no-button ${
                                answers.find(
                                  (answer) =>
                                    answer.pergunta === subpergunta.id &&
                                    answer.resposta === false
                                )
                                  ? "answered"
                                  : ""
                              }`}
                              onClick={() => {
                                answerQuestion(
                                  props.dispatch,
                                  subpergunta.id,
                                  false
                                );
                              }}
                            >
                              NÃO
                            </ButtonAnt>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          ) : null}
        </React.Fragment>
        {currentQuestionIndex < questions.length - 1 ? (
          <Row gutter={24}>
            <Col span={24}>
              <div className="proxima-pergunta-div">
                <ButtonAnt
                  disabled={
                    currentQuestion.subperguntas
                      ? currentQuestion.subperguntas.length + 1 !==
                        answers.filter(
                          ({ pergunta }) =>
                            currentQuestion.id === pergunta ||
                            currentQuestion.subperguntas
                              .map(({ id }) => id)
                              .includes(pergunta)
                        ).length
                      : true
                  }
                  onClick={() => {
                    showFinishQuestionQuestionario(handleNextQuestion);
                  }}
                >
                  PRÓXIMA PERGUNTA
                </ButtonAnt>
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    perguntasAutodeclaracao: state.autodeclaracao.autodeclaracao
      ? state.autodeclaracao.autodeclaracao
      : [],
    respostasAutodeclaracao: state.autodeclaracao.respostas
      ? state.autodeclaracao.respostas
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      listQuestions,
    }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Perguntas)
);
