import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Radio,
  Switch,
  Row,
  Col,
  Select,
  ConfigProvider,
  Tag,
} from "antd";
import Button from "../../../../../../../components/Buttons/_/components/button";
import IconSESI from "../../../../../../../components/IconsSESI";
import MaskedInput from "antd-mask-input";
import "./style.scss";

const situacaoEnum = Object.freeze({
  NORMAL: "1",
  APOSENTADORIA_POR_INVALIDEZ: "2",
  AFASTADO_PELO_INSS: "3",
  DESLIGADO: "4",
});

const FuncionarioForm = (props) => {
  const [form] = Form.useForm();
  const [funcionarioSelected, setFuncionarioSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [canEditName, setCanEditName] = useState(true);
  const [viewOnly, setViewOnly] = useState(props.viewOnly);
  const [selectedTag, setSelectedTag] = useState("TRABALHADOR");
  const [isActive, setIsActive] = useState(true);
  const [validateFieldsName] = useState([]);
  const [tipoDeDesligamento, setTipoDeDesligamento] =
    useState("do desligamento");
  const { CheckableTag } = Tag;

  useEffect(() => {
    if (props.modo === "edit" && props.funcionario && funcionarioSelected) {
      setFuncionarioSelected(props.funcionario);
      iniciarModoEdicao(props.funcionario);
    }
  }, [JSON.stringify(props.funcionario)]);

  useEffect(() => {
    setViewOnly(props.viewOnly);
  }, [props.viewOnly]);

  const handleCargoOnChange = (selectedIdCargo) => {
    // onChange happens before updating the form values
    //const currentIdCargo = form.getFieldValue("idCargo");
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return "onChange";
    return "onBlur";
  };

  const iniciarModoEdicao = ({ funcionario, cargosPorSetor }) => {
    if (funcionario) {
      let data = moment(funcionario.nascimento).format("DD/MM/YYYY");
      cargosPorSetor.forEach((setor) => {
        const cargo = setor.cargos.find(
          (cargo) =>
            cargo.descricao === funcionario.setorCargo.descricaoCargo &&
            setor.nome === funcionario.setorCargo.nomeSetor
        );
        if (cargo) funcionario.cargo = cargo.id;
      });
      const bool = funcionario.ativo ? true : false;
      setCanEditName(funcionario.podeSerAlterado === 0 ? false : true);
      if (situacaoEnum[funcionario.situacao] !== "1") {
        setIsActive(false);
      }
      form.setFieldsValue({
        id: funcionario.id,
        nome: funcionario.nome,
        ativo: bool,
        cpf: funcionario.cpf,
        rg: funcionario.rg,
        orgaoEmissor: funcionario.orgaoEmissor,
        sexo: funcionario.sexo,
        nascimento: data,
        idCargo: funcionario.cargo,
        situacao: situacaoEnum[funcionario.situacao],
        admissao: moment(funcionario.dataDeAdmissao).format("DD/MM/YYYY"),
        desligamento: funcionario.dataDeDesligamento
          ? funcionario.dataDeDesligamento.split("/").reverse().join("-")
          : "",
        tipoEspecifico:
          funcionario.tipo === "OUTRO" ? funcionario.tipoEspecifico : "",
      });
      setSelectedTag(funcionario.tipo);
    }
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        values.tipo = selectedTag;
        props.modo === "edit"
          ? props.edit(
              props.dispatch,
              values,
              props.funcionario.funcionario.id,
              props.tab,
              resetFields,
              toggleLoading
            )
          : props.post(
              props.dispatch,
              values,
              props.tab,
              resetFields,
              toggleLoading
            );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetFields = () => {
    document.getElementsByClassName("trabalhadores-form")[0].scrollTop = 0;
    form.resetFields();
    setSelectedTag("TRABALHADOR");
    setIsActive(true);
    setCanEditName(true);
  };

  const { Option, OptGroup } = Select;
  // let collapsed =
  //   selectedOption === "4" && !isActive ? true : false;

  const customizeRenderEmpty = () => (
    <div>
      <p style={{ color: "#303030", marginTop: "1rem" }}>
        Nenhum cargo disponível
      </p>
    </div>
  );

  return (
    <React.Fragment>
      <div className="header-form transparent">
        <Button
          shape="circle"
          ghost
          text={<IconSESI type="arrow-left" />}
          onClick={() => {
            props.tab();
            props.changeModo();
            resetFields();
          }}
        />
        <h2>Trabalhadores</h2>
      </div>

      <Form
        onFinish={viewOnly ? null : handleSubmit}
        form={form}
        className="trabalhadores-form"
      >
        {props.modo === "edit" ? (
          <div className={isActive ? "section active-inactive" : "section"}>
            <Row>
              <Col span={isActive ? 24 : 12}>
                <Form.Item label="Trabalhador ativo ou inativo" name="ativo">
                  <Switch
                    checkedChildren="Ativo"
                    unCheckedChildren="Inativo"
                    disabled={viewOnly}
                    checked={isActive}
                    onChange={(e) => setIsActive(e)}
                  />
                </Form.Item>
              </Col>
              <Col
                span={12}
                style={{
                  display: isActive ? "none" : "block",
                }}
              >
                <Form.Item
                  label="Situação do trabalhador"
                  name="situacao"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator() {
                        if (
                          getFieldValue("situacao") === "1" &&
                          getFieldValue("ativo") === false
                        ) {
                          return Promise.reject(
                            new Error("Altere a situação do trabalhador.")
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                  initialValue={form.setFieldsValue(
                    isActive ? { situacao: "1" } : ""
                  )}
                >
                  <Select
                    disabled={isActive}
                    onChange={(value) => {
                      setTipoDeDesligamento(
                        value === "2"
                          ? "da aposentadoria"
                          : value === "3"
                          ? "do afastamento"
                          : "do desligamento"
                      );
                    }}
                  >
                    <Option
                      value="1"
                      disabled={!isActive}
                      key="normal"
                      style={!isActive ? { opacity: "0.5" } : ""}
                    >
                      Normal
                    </Option>
                    <Option value="2" disabled={isActive} key="aposentado">
                      Aposentadoria por invalidez
                    </Option>
                    <Option value="3" disabled={isActive} key="afastado">
                      Afastado pelo INSS
                    </Option>
                    <Option value="4" disabled={isActive} key="desligado">
                      Desligado
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col
                span={10}
                style={{
                  display: isActive ? "none" : "block",
                }}
              >
                <Form.Item
                  label={`Data ${tipoDeDesligamento}`}
                  name="desligamento"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator() {
                        if (
                          getFieldValue("desligamento") === "" &&
                          getFieldValue("ativo") === false
                        ) {
                          return Promise.reject(
                            new Error(
                              "Informe a data de desligamento do trabalhador."
                            )
                          );
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <MaskedInput mask="11/11/1111" disabled={isActive} />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}

        <div className="section">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Nome"
                name="nome"
                rules={[
                  {
                    required: true,
                    message: "Escreva o nome!",
                  },
                ]}
              >
                <Input disabled={viewOnly || !canEditName} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Sexo"
                name="sexo"
                rules={[
                  {
                    required: true,
                    message: "Selecione o sexo!",
                  },
                ]}
              >
                <Radio.Group disabled={viewOnly}>
                  <Radio value="M">Masculino</Radio>
                  <Radio value="F">Feminino</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="CPF"
                name="cpf"
                rules={[
                  {
                    required: true,
                    message: "Escreva o CPF!",
                  },
                ]}
              >
                <MaskedInput
                  mask="111.111.111-11"
                  disabled={viewOnly || props.modo === "edit"}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Data de nascimento"
                name="nascimento"
                validateTrigger={handleValidateFieldNames("nascimento")}
                rules={[
                  {
                    required: true,
                    message: "Informe a data de nascimento!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const date = moment(
                        getFieldValue("nascimento"),
                        "DD/MM/YYYY"
                      );
                      const age = moment().diff(date, "years");
                      if (age < 14) {
                        return Promise.reject(
                          new Error("A idade deve ser maior do que 14 anos")
                        );
                      } else if (age > 120) {
                        return Promise.reject(
                          new Error("A idade deve ser menor do que 120 anos")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <MaskedInput mask="11/11/1111" disabled={viewOnly} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="RG"
                name="rg"
                rules={[
                  {
                    max: 15,
                    message: "Não pode ter mais do que 15 caracteres.",
                  },
                ]}
                normalize={(value) =>
                  value && value.replaceAll(/[^\d\-/.]/gi, "")
                }
              >
                <Input disabled={viewOnly} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Órgão emissor" name="orgaoEmissor">
                <Input disabled={viewOnly} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Form.Item
                  label="Cargo"
                  name="idCargo"
                  rules={[
                    {
                      required: true,
                      message: "Selecione um cargo!",
                    },
                  ]}
                >
                  <Select
                    disabled={props.viewOnly}
                    dropdownClassName="dropdown-cargos"
                    onChange={handleCargoOnChange}
                  >
                    {props.cargos.map((setor) => {
                      return (
                        <OptGroup key={setor.nome} label={setor.nome}>
                          {setor.cargos.map((cargo) => {
                            return (
                              <Option value={cargo.id} key={cargo.id}>
                                {cargo.descricao}
                              </Option>
                            );
                          })}
                        </OptGroup>
                      );
                    })}
                  </Select>
                </Form.Item>
              </ConfigProvider>
            </Col>
            <Col span={12}>
              <Form.Item label="Data de admissão" name="admissao">
                <MaskedInput mask="11/11/1111" disabled={viewOnly} />
              </Form.Item>
            </Col>

            <Col
              span={12}
              className={viewOnly ? "tipo-trabalhador-disabled" : ""}
            >
              <Form.Item label="Tipo de trabalhador" name="tipo">
                <CheckableTag
                  key="TRABALHADOR"
                  checked={selectedTag === "TRABALHADOR" ? true : false}
                  onClick={() => {
                    if (!viewOnly) setSelectedTag("TRABALHADOR");
                  }}
                >
                  Trabalhador(a)
                </CheckableTag>
                <CheckableTag
                  key="MENOR_APRENDIZ"
                  checked={selectedTag === "MENOR_APRENDIZ" ? true : false}
                  onClick={() => {
                    if (!viewOnly) setSelectedTag("MENOR_APRENDIZ");
                  }}
                >
                  Menor aprendiz
                </CheckableTag>
                <CheckableTag
                  key="ESTAGIARIO"
                  checked={selectedTag === "ESTAGIARIO" ? true : false}
                  onClick={() => {
                    if (!viewOnly) setSelectedTag("ESTAGIARIO");
                  }}
                >
                  Estagiário(a)
                </CheckableTag>
                <CheckableTag
                  key="OUTRO"
                  checked={selectedTag === "OUTRO" ? true : false}
                  onClick={() => {
                    if (!viewOnly) setSelectedTag("OUTRO");
                  }}
                >
                  Outro
                </CheckableTag>
              </Form.Item>
              {selectedTag === "OUTRO" ? (
                <Form.Item label="Especifique:" name="tipoEspecifico">
                  <Input disabled={viewOnly} />
                </Form.Item>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
        {!viewOnly ? (
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              text="Salvar"
              loading={loading}
            />
          </Form.Item>
        ) : null}
      </Form>
    </React.Fragment>
  );
};

export default FuncionarioForm;
