import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  postEPI,
  getEPIFromList,
  editEPI,
  emptyEPISelecionado,
  deleteEPI,
} from "../../actions";
import "../../../styles.scss";

const CadastroEPIForm = (props) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("new");
  const [epiSelecionado, setEpiSelecionado] = useState({});

  useEffect(() => {
    if (epiSelecionado) setMode("edit");
    if (props.epiSelecionado) setEpiSelecionado(props.epiSelecionado);
    setEdit(props.epiSelecionado);
  }, [JSON.stringify(props.epiSelecionado)]);

  const setEdit = (epiSelecionado) => {
    if (mode == "edit" || props.epiSelecionado) {
      form.setFieldsValue({
        epiCode: epiSelecionado.id,
        epiName: epiSelecionado.nome,
      });
    }
  };

  const handleEdit = () => {
    form.validateFields().then((values) => {
      let newEPI = {
        id: values.epiCode,
        nome: values.epiName,
      };
      editEPI(values.epiCode, newEPI, props.dispatch);
      form.resetFields();
    });
  };

  const handleNew = () => {
    form.validateFields().then((values) => {
      let newEPI = {
        id: values.epiCode,
        nome: values.epiName,
      };
      postEPI(newEPI, props.dispatch);
      form.resetFields();
    });
  };

  const handleDelete = () => {
    form.validateFields().then((values) => {
      deleteEPI(values.epiCode, props.dispatch);
      setMode("new");
      form.resetFields();
    });
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={mode === "new" ? () => handleNew() : () => handleEdit()}
      >
        <Form.Item label="Código do EPI" name="epiCode">
          <Input type="number" />
        </Form.Item>
        <Form.Item label="EPI" name="epiName">
          <Input />
        </Form.Item>
        <div className="epi-button-div">
          {!epiSelecionado.nome ? (
            <Button onClick={() => handleNew()}>Salvar</Button>
          ) : (
            ""
          )}
          {epiSelecionado.nome ? (
            <React.Fragment>
              <Button onClick={() => handleEdit()}>Alterar</Button>
              <Button onClick={() => handleDelete()}>Excluir</Button>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    epiSelecionado: state.gestorDN.epiSelecionado,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...dispatch,
    ...bindActionCreators({
      getEPIFromList,
      emptyEPISelecionado,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CadastroEPIForm);
