import React from "react";
import { connect } from "react-redux";
import ESocialBanner from "../components/ESocialBanner";
import ESocialDashboard from "../components/ESocialDashboard";

const ESocialContainer = (props) => {
  const userHasPremiumCompany = props.companies.some(
    (company) => company.premium
  );
  return userHasPremiumCompany ? (
    <ESocialDashboard {...props} />
  ) : (
    <ESocialBanner />
  );
};

const mapStateToProps = (state) => {
  return {
    companies: state.user.companies,
  };
};

export default connect(mapStateToProps, {})(ESocialContainer);
