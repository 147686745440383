import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  Switch,
  ConfigProvider,
  Col,
  Row,
  Tooltip,
  List,
} from "antd";
import Button from "../../../../../../../components/Buttons/_/components/button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editProcessoProdutivo } from "../actions";
import {
  CloseOutlined,
  CheckOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import locale from "antd/lib/locale/pt_BR";
import FormList from "./FormList";

const ProcessoProdutivoForm = (props) => {
  const [form] = Form.useForm();
  const [showFinalDate, setShowFinalDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validateFieldsName] = useState([]);
  const [processMachines, setProcessMachines] = useState([]);
  const [processChemicalProducts, setProcessChemicalProducts] = useState([]);

  useEffect(() => {
    if (props.mode === "new") {
      form.resetFields();
      setProcessMachines([]);
      setProcessChemicalProducts([]);
    } else if (props.mode === "edit") {
      const { nome, descricao, dataFim, equipamentos, produtosQuimicos } =
        props.processoProdSelecionado;
      form.setFieldsValue({
        processName: nome,
        processDescription: descricao !== "" ? descricao : null,
        finalDate: dataFim ? moment(dataFim) : moment(),
      });
      if (equipamentos) {
        setProcessMachines(equipamentos);
      } else {
        setProcessMachines([]);
      }
      if (produtosQuimicos) {
        setProcessChemicalProducts(produtosQuimicos);
      } else {
        setProcessChemicalProducts([]);
      }
      if (dataFim) {
        setShowFinalDate(true);
        form.setFieldsValue({
          isProcessActive: false,
        });
      } else {
        setShowFinalDate(false);
        form.setFieldsValue({
          isProcessActive: true,
        });
      }
    } else if (props.mode === "accept") {
      const { nome } = props.processoProdSelecionado;
      form.setFieldsValue({
        processName: nome,
      });
    }
  }, [JSON.stringify(props.processoProdSelecionado)]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const newProcessBody = {
          nome: values.processName,
          cnae: props.cnae,
          descricao: values.processDescription,
          equipamentos: processMachines,
          produtosQuimicos: processChemicalProducts,
        };
        if (values.finalDate) newProcessBody.dataFim = values.finalDate;
        if (props.mode === "new") {
          props.addProcessoProdutivo(
            props.dispatch,
            newProcessBody,
            props.goToList,
            toggleLoading
          );
        } else if (props.mode === "edit") {
          editProcessoProdutivo(
            props.dispatch,
            props.processoProdSelecionado,
            {
              nome: newProcessBody.nome,
              descricao: newProcessBody.descricao,
              dataFim: newProcessBody.dataFim,
              equipamentos: processMachines,
              produtosQuimicos: processChemicalProducts,
            },
            props.goToList,
            toggleLoading
          );
        } else if (props.mode === "accept") {
          props.acceptProductiveProcess(
            props.dispatch,
            props.processoProdSelecionado.processoProdutivoId,
            newProcessBody,
            props.goToList,
            toggleLoading
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const toggleLoading = (bool) => {
    setLoading(bool);
  };

  const handleValidateFieldNames = (name) => {
    const isFieldName = validateFieldsName.find(
      (fieldName) => fieldName === name
    );
    if (isFieldName) return "onChange";
  };

  const handleActiveProcessChange = (value) => {
    if (!value) {
      setShowFinalDate(true);
    } else {
      setShowFinalDate(false);
    }
  };

  const addItem = (type, item) => {
    if (type === "maquinas") {
      setProcessMachines([...processMachines, item]);
    } else {
      setProcessChemicalProducts([...processChemicalProducts, item]);
    }
  };

  const removeItem = (type, item) => {
    if (type === "maquinas") {
      setProcessMachines(processMachines.filter((machine) => machine !== item));
    } else {
      setProcessChemicalProducts(
        processChemicalProducts.filter((chemical) => chemical !== item)
      );
    }
  };

  return (
    <ConfigProvider locale={locale}>
      <Form
        onFinish={handleSubmit}
        form={form}
        className={
          false
            ? "processo-produtivo-form has-error"
            : "processo-produtivo-form"
        }
      >
        {props.mode === "edit" ? (
          <div className="section">
            <Row>
              {props.mode === "edit" ? (
                <Col span={12}>
                  <Form.Item
                    label="Processo ativo"
                    name="isProcessActive"
                    valuePropName="checked"
                  >
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={handleActiveProcessChange}
                    ></Switch>
                  </Form.Item>
                </Col>
              ) : null}
              {props.mode === "edit" && showFinalDate ? (
                <Col span={12} className="dates-super-div">
                  <div className="dates-div">
                    <Form.Item
                      label={
                        <span>
                          Data-fim do processo{" "}
                          <Tooltip title="A data que o processo deixa de existir na sua empresa.">
                            {" "}
                            <QuestionCircleOutlined />{" "}
                          </Tooltip>
                        </span>
                      }
                      name="finalDate"
                    >
                      <DatePicker
                        onChange={(e) => console.log(e)}
                        format="DD/MM/YYYY"
                        placeholder="__/__/__"
                        bordered={false}
                        suffixIcon={false}
                        locale={locale}
                      />
                    </Form.Item>
                  </div>
                </Col>
              ) : null}
            </Row>
          </div>
        ) : null}
        <div className="section">
          <Form.Item
            label="Nome do processo produtivo:"
            name="processName"
            validateTrigger={handleValidateFieldNames("processName")}
            rules={[
              ({ getFieldValue }) => ({
                validator(_) {
                  if (getFieldValue("processName")) {
                    const name = getFieldValue("processName").toLowerCase();
                    const processesCreatedByTheUser = props.processos
                      .filter((processo) => processo.isCatalogo === 0)
                      .map((processo) => processo.nome.toLowerCase());
                    if (
                      processesCreatedByTheUser.includes(name) &&
                      props.mode === "new"
                    ) {
                      return Promise.reject(
                        new Error(
                          "Esse processo já foi cadastrado! Cadastre um processo diferente."
                        )
                      );
                    }
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error("Escreva o nome do processo produtivo!")
                    );
                  }
                },
              }),
            ]}
          >
            <Input
              disabled={
                props.mode === "new"
                  ? false
                  : props.processoProdSelecionado.catalogo ||
                    props.mode === "accept"
                  ? true
                  : false
              }
              type="text"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Descrição do processo produtivo:"
            name="processDescription"
          >
            <Input.TextArea
              maxLength={300}
              showCount
              autoSize={{ minRows: 4, maxRows: 4 }}
            ></Input.TextArea>
          </Form.Item>
          <FormList
            addItem={addItem}
            processMachines={processMachines}
            processChemicalProducts={processChemicalProducts}
            disabled={false}
            removeItem={removeItem}
            type="maquinas"
          />
          <FormList
            addItem={addItem}
            processMachines={processMachines}
            processChemicalProducts={processChemicalProducts}
            disabled={false}
            removeItem={removeItem}
            type="produtosQuimicos"
          />
        </div>
        <Form.Item>
          <Button
            block
            type="primary"
            text="Salvar"
            htmlType="submit"
            loading={loading}
          />
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({}, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessoProdutivoForm);
