import axios from "axios";
import {
  categoriasTrabsEsocial,
  certificadoDigital,
  documentosEsocial,
  eventosEsocial,
  examesEsocial,
  funcionariosUrl,
  getCertificadoDigital,
  postMonitoramento,
} from "../../../../constants";
import {
  ALTERAR_ASSINATURA,
  DELETAR_ASSINATURA,
  ENVIAR_MONITORAMENTO,
  GERAR_DOCUMENTO,
  LISTAR_ASSINATURAS,
  LISTAR_CATEGORIAS,
  LISTAR_EVENTOS,
  LISTAR_EXAMES,
  LISTAR_FUNCIONARIOS_ESOCIAL,
  SALVAR_ASSINATURA,
} from "./types";

export const listarCategorias = (dispatch) => {
  axios
    .get(categoriasTrabsEsocial)
    .then((response) => {
      dispatch({
        type: LISTAR_CATEGORIAS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const listarExames = (dispatch) => {
  axios
    .get(examesEsocial)
    .then((response) => {
      dispatch({
        type: LISTAR_EXAMES,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const listarFuncionariosEsocial = (dispatch) => {
  axios
    .get(funcionariosUrl)
    .then((response) => {
      dispatch({
        type: LISTAR_FUNCIONARIOS_ESOCIAL,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const post2220 = (new2220, dispatch) => {
  axios
    .post(postMonitoramento, new2220)
    .then((response) => {
      dispatch({
        type: ENVIAR_MONITORAMENTO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const listarCertificados = (dispatch) => {
  axios
    .get(certificadoDigital)
    .then((response) => {
      dispatch({
        type: LISTAR_ASSINATURAS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const postCertificado = (newSignature, dispatch) => {
  axios({
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data: newSignature,
    url: certificadoDigital,
  })
    .then((response) => {
      dispatch({
        type: SALVAR_ASSINATURA,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const deleteCertificado = (id, dispatch) => {
  axios.delete(getCertificadoDigital(id)).then((response) => {
    dispatch({
      type: DELETAR_ASSINATURA,
    });
  });
};

export const listarEventos = (dispatch) => {
  axios
    .get(eventosEsocial)
    .then((response) => {
      dispatch({
        type: LISTAR_EVENTOS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const alterarCertificado = (id, newBody, dispatch) => {
  axios.patch(getCertificadoDigital(id), newBody).then((response) => {
    dispatch({
      type: ALTERAR_ASSINATURA,
    });
  });
};

export const gerarDocumento = (id, dispatch) => {
  axios
    .get(documentosEsocial(id))
    .then((response) => {
      dispatch({
        type: GERAR_DOCUMENTO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};
