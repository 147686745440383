import React, { useEffect, useState } from "react";
import Companies from "../../components/CompanyList/_/containers/Companies";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { companySelectionTalks } from "../../constants/aroldoTalks";
import Assistant from "../../components/VirtualAssistant";
import { runAction } from "../../components/Buttons/_/actions";
import { INTRO_OVER } from "../../components/Buttons/_/actions/types";
import { Link } from "react-router-dom";
import "./styles.scss";
import Button from "../../components/Buttons/_/components/button";
import { Row } from "antd";
import { selectCompany } from "../../components/CompanyList/_/containers/Companies/_/actions";

const ApresentacaoEmpresa = (props) => {
  const [empresas, setEmpresas] = useState([]);
  const [talk, setTalk] = useState(0);
  useEffect(() => {
    if (props.companyList) {
      const uniqueCompanies = props.companyList.reduce((acc, obj) => {
        let exist = acc.find(({ cnpj }) => obj.cnpj === cnpj);
        if (!exist) {
          acc.push(obj);
        }
        return acc;
      }, []);
      const empresas = uniqueCompanies;
      setEmpresas(empresas);
    }
  }, [JSON.stringify(props.companyList)]);

  useEffect(() => {
    if (props.talkInicio) {
      let talks = companySelectionTalks(props.talkInicio[0].descricao);
      setTalk(talks[props.talkInicio[0].id]);
    }
  }, [JSON.stringify(props.talkInicio)]);

  const disabled = Object.keys(props.companySelected).length === 0;

  const continueButton = () => {
    runAction(INTRO_OVER);
    selectCompany(props.dispatch, props.companySelected);
  };

  return (
    <div className="main" style={{ height: "100%" }}>
      <div className="company-list-wrapper presentation">
        <div className="align-self-center">
          <Assistant talk={talk} />
          <div className="company-cards">
            <div className="cards">
              <Row gutter={24}>
                {props.companyList &&
                  empresas.map((company) => {
                    return (
                      <Companies
                        key={company.razaoSocial}
                        colSpan={window.innerWidth < 808 ? 24 : 12}
                        company={company}
                      ></Companies>
                    );
                  })}
              </Row>
            </div>
          </div>
          {disabled ? (
            <Button
              text="Continuar"
              type="primary"
              block
              disabled={true}
              onClick={() => {
                runAction(INTRO_OVER);
              }}
            />
          ) : (
            <Link to={"/selecaoempresa"}>
              <Button
                text="Continuar"
                type="primary"
                block
                onClick={() => continueButton()}
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    page: state.buttons.pageIntro,
    companySelected: state.caracterizacao
      ? state.caracterizacao.companySelected
      : {},
    perfil: state.user.userInfo ? state.user.userInfo.perfil : "DESLOGADO",
    companyList: state.user.companies,
    userInfo: state.user.userInfo ? state.user.userInfo : null,
    talkInicio: state.gestorDN.virtualAssistant.beginningTalks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ runAction, selectCompany }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApresentacaoEmpresa);
