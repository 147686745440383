import axios from "axios";
import { setupInterceptors } from "../../../../auth/SetupInterceptors";
import { SELECT_COMPANY } from "../../../../components/CompanyList/_/containers/Companies/_/actions/types";
import openNotification from "../../../../components/notifications";
import { documentosUrl, empresasUrl } from "../../../../constants";

setupInterceptors();
export function getDocuments(dispatch, cnpj, setShowTable, formatTableData) {
  axios
    .post(`${empresasUrl}/${cnpj}`)
    .then(() => {
      axios
        .get(empresasUrl)
        .then((response) => {
          dispatch({ type: SELECT_COMPANY, payload: response.data });
          axios
            .get(documentosUrl)
            .then((res) => {
              formatTableData(res.data);
              setShowTable(true);
            })
            .catch((err) => {
              openNotification("Empresa não possui documentos.", "", "error");
              setShowTable(false);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch((err) => {
      console.error(err);
    });
}
