import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import IconSESI from "../../../../components/IconsSESI";
import {
  listarCategorias,
  listarExames,
  listarFuncionariosEsocial,
  post2220,
} from "../actions";
import { MaskedInput } from "antd-mask-input";

const { Option } = Select;

const ESocialForm = (props) => {
  const [form] = Form.useForm();
  const [hasMatricula, setHasMatricula] = useState(true);
  const [selectedTest, setSelectedTest] = useState([]);

  const audiometria = [279, 280, 281, 282, 283];
  const examesObservacao = [
    583, 998, 999, 1128, 1230, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
    9999,
  ];

  useEffect(() => {
    listarCategorias(props.dispatch);
    listarExames(props.dispatch);
    listarFuncionariosEsocial(props.dispatch);
  }, []);

  const onCheck = () => {
    setHasMatricula(!hasMatricula);
    listarCategorias(props.dispatch);
  };

  const handleClearTest = (index) => {
    let test = selectedTest;
    test.splice(index, 1);
    setSelectedTest(test);
  };

  const parseExams = (exams) => {
    return exams.map((exam) => {
      return {
        dtExm: moment(exam.dtExm).format("YYYY-MM-DD"),
        procRealizado: exam.procRealizado,
        obsProc: exam.obsProc ? exam.obsProc : "",
        ordExame: exam.ordExame ? exam.ordExame : "NAO_APLICAVEL",
        indResult: exam.indResult ? exam.indResult : "NAO_APLICAVEL",
      };
    });
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      let new2220 = {
        indRetif: "ORIGINAL",
        nrRecibo: "",
        nrInsc: "",
        cpfTrab: values.cpfTrabalhador,
        matricula: values.matriculaTrabalhador
          ? values.matriculaTrabalhador
          : "",
        codCateg: values.categoriaTrabalhador
          ? values.categoriaTrabalhador.toString()
          : "",
        tpExameOcup: values.tipoExame,
        dtAso: moment(values.dataEmissaoASO).format("YYYY-MM-DD"),
        resAso: values.resultadoASO,
        exames: values.additionalExams
          ? parseExams(values.additionalExams)
          : [],
        nmMed: values.nomeMedico,
        nrCRMMed: values.crmMedico,
        ufCRM: values.ufCRM,
        cpfResp: values.cpfResponsavel.replace(/\D/g, ""),
        nmResp: values.nomeResposavel,
        nrCRMResp: values.crmResponsavel,
        ufCRMResp: values.ufCRMResponsavel,
      };
      post2220(new2220, props.dispatch);
    });
  };

  return (
    <React.Fragment>
      <Form form={form} onFinish={handleSubmit}>
        <div className="esocial-form-card">
          <span className="esocial-card-title">
            Identificação do trabalhador
          </span>
          <Form.Item label="Trabalhador::" name="cpfTrabalhador">
            <Select>
              {props.funcionariosEsocial.map((funcionario) => {
                return (
                  <Option key={funcionario.id} value={funcionario.cpf}>
                    {funcionario.nome}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <div className="matricula-row">
            <Form.Item
              label="Número de matrícula::"
              name="matriculaTrabalhador"
            >
              <Input
                style={{ width: "90%", marginRight: "1rem" }}
                disabled={!hasMatricula}
              />
            </Form.Item>
            <Checkbox onChange={() => onCheck()}>Não tem matrícula</Checkbox>
          </div>
          {hasMatricula === false ? (
            <Form.Item
              label="Categoria do Trabalhador::"
              name="categoriaTrabalhador"
            >
              <Select>
                {props.categoriasTrabalhadores.map((categoria) => {
                  return (
                    <Option value={categoria.id}>{categoria.descricao}</Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : (
            ""
          )}
        </div>

        <div className="esocial-exams-div">
          <div className="esocial-form-card">
            <span className="esocial-card-title">Exame Médico Ocupacional</span>
            <Form.Item label="Tipo de ASO::" name="tipoExame">
              <Select>
                <Option value="0">Exame médico admissional</Option>
                <Option value="1">
                  Exame médico periódico, conforme Norma Regulamentadora 07 -
                  NR-07 e/ouplanejamento do Programa de Controle Médico de Saúde
                  Ocupacional - PCMSO{" "}
                </Option>
                <Option value="2">Exame médico de retorno ao trabalho</Option>
                <Option value="3">
                  Exame médico de mudança de função ou de mudança de risco
                  ocupacional
                </Option>
                <Option value="4">
                  Exame médico de monitoração pontual, não enquadrado nos demais
                  casos
                </Option>
                <Option value="9">Exame médico demissional</Option>
              </Select>
            </Form.Item>
            <Row>
              <Form.Item
                label="Data de emissão do ASO::"
                style={{ marginRight: "1rem" }}
                name="dataEmissaoASO"
              >
                <DatePicker format="YYYY-MM-DD" />
              </Form.Item>
              <Form.Item label="Resultado do ASO::" name="resultadoASO">
                <Radio.Group>
                  <Radio value="APTO">Apto</Radio>
                  <Radio value="INAPTO">Inapto</Radio>
                </Radio.Group>
              </Form.Item>
            </Row>
            <Form.List name="additionalExams">
              {(additionalExams, { add }) => {
                return (
                  <React.Fragment>
                    <div className="esocial-exams-button-div">
                      <Button ghost className="add" onClick={() => add()}>
                        <IconSESI type="add-circle" theme="filled" />
                        Adicionar exame
                      </Button>
                    </div>
                    {additionalExams.map((additionalExam, index) => (
                      <div className="esocial-form-card" key={index}>
                        <span className="esocial-card-title">
                          Avaliações clínicas e exames complementares
                        </span>
                        <Row>
                          <Form.Item
                            label="Data do exame::"
                            style={{ marginRight: "1rem" }}
                            name={[index, "dtExm"]}
                          >
                            <DatePicker format="YYYY-MM-DD" />
                          </Form.Item>
                          <Form.Item
                            label="Exame::"
                            style={{ width: "50%" }}
                            name={[index, "procRealizado"]}
                          >
                            <Select
                              showSearch
                              showArrow={false}
                              allowClear
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.props.value.toString().indexOf(input) >=
                                  0
                              }
                              onSelect={(value) => {
                                setSelectedTest([...selectedTest, value]);
                              }}
                              onClear={() => handleClearTest(index)}
                            >
                              {props.tiposExames.map((exame) => (
                                <Option key={exame.id} value={exame.id}>
                                  {[exame.id, exame.nome].join(" - ")}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Row>
                        {audiometria.includes(selectedTest[index]) ? (
                          <Form.Item
                            label="Ordem do exame::"
                            name={[index, "ordExame"]}
                          >
                            <Radio.Group>
                              <Radio value="INICIAL">Inicial</Radio>
                              <Radio value="SEQUENCIAL">Sequencial</Radio>
                            </Radio.Group>
                          </Form.Item>
                        ) : (
                          ""
                        )}
                        {examesObservacao.includes(selectedTest[index]) ? (
                          <Form.Item
                            label="Observações do exame realizado::"
                            name={[index, "obsProc"]}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  </React.Fragment>
                );
              }}
            </Form.List>
          </div>
        </div>

        <div className="esocial-form-card">
          <span className="esocial-card-title">
            Identificação do médico emitente do ASO
          </span>
          <Form.Item label="Nome::" name="nomeMedico">
            <Input />
          </Form.Item>
          <Row className="esocial-doctor-info">
            <Form.Item label="CRM::" name="crmMedico">
              <Input />
            </Form.Item>
            <Form.Item label="UF de expedição do CRM::" name="ufCRM">
              <Select>
                <Option value="AC">Acre</Option>
                <Option value="AL">Alagoas</Option>
                <Option value="AP">Amapá</Option>
                <Option value="AM">Amazonas</Option>
                <Option value="BA">Bahia</Option>
                <Option value="CE">Ceará</Option>
                <Option value="DF">Distrito Federal</Option>
                <Option value="ES">Espírito Santo</Option>
                <Option value="GO">Goiás</Option>
                <Option value="MA">Maranhão</Option>
                <Option value="MT">Mato Grosso</Option>
                <Option value="MS">Mato Grosso do Sul</Option>
                <Option value="MG">Minas Gerais</Option>
                <Option value="PA">Pará</Option>
                <Option value="PB">Paraíba</Option>
                <Option value="PR">Paraná</Option>
                <Option value="PE">Pernambuco</Option>
                <Option value="PI">Piauí</Option>
                <Option value="RJ">Rio de Janeiro</Option>
                <Option value="RN">Rio Grande do Norte</Option>
                <Option value="RS">Rio Grande do Sul</Option>
                <Option value="RO">Rondônia</Option>
                <Option value="RR">Roraima</Option>
                <Option value="SC">Santa Catarina</Option>
                <Option value="SP">São Paulo</Option>
                <Option value="SE">Sergipe</Option>
                <Option value="TO">Tocantins</Option>
              </Select>
            </Form.Item>
          </Row>
        </div>
        <div className="esocial-form-card">
          <span className="esocial-card-title">
            Identificação do médico responsável pelo PCMSO
          </span>
          <Form.Item label="Nome::" name="nomeResposavel">
            <Input />
          </Form.Item>
          <Row className="esocial-doctor-info">
            <Form.Item label="CPF::" name="cpfResponsavel">
              <MaskedInput mask="111.111.111-11" />
            </Form.Item>
            <Form.Item label="CRM::" name="crmResponsavel">
              <Input />
            </Form.Item>
            <Form.Item label="UF de expedição do CRM::" name="ufCRMResponsavel">
              <Select>
                <Option value="AC">Acre</Option>
                <Option value="AL">Alagoas</Option>
                <Option value="AP">Amapá</Option>
                <Option value="AM">Amazonas</Option>
                <Option value="BA">Bahia</Option>
                <Option value="CE">Ceará</Option>
                <Option value="DF">Distrito Federal</Option>
                <Option value="ES">Espírito Santo</Option>
                <Option value="GO">Goiás</Option>
                <Option value="MA">Maranhão</Option>
                <Option value="MT">Mato Grosso</Option>
                <Option value="MS">Mato Grosso do Sul</Option>
                <Option value="MG">Minas Gerais</Option>
                <Option value="PA">Pará</Option>
                <Option value="PB">Paraíba</Option>
                <Option value="PR">Paraná</Option>
                <Option value="PE">Pernambuco</Option>
                <Option value="PI">Piauí</Option>
                <Option value="RJ">Rio de Janeiro</Option>
                <Option value="RN">Rio Grande do Norte</Option>
                <Option value="RS">Rio Grande do Sul</Option>
                <Option value="RO">Rondônia</Option>
                <Option value="RR">Roraima</Option>
                <Option value="SC">Santa Catarina</Option>
                <Option value="SP">São Paulo</Option>
                <Option value="SE">Sergipe</Option>
                <Option value="TO">Tocantins</Option>
              </Select>
            </Form.Item>
          </Row>
        </div>
        <Row className="esocial-submit-button-row">
          <Button htmlType="submit">Salvar</Button>
        </Row>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    categoriasTrabalhadores: state.esocial.categoriasTrabalhadores
      ? state.esocial.categoriasTrabalhadores
      : [],
    tiposExames: state.esocial.tiposExames ? state.esocial.tiposExames : [],
    funcionariosEsocial: state.esocial.funcionariosEsocial
      ? state.esocial.funcionariosEsocial
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        listarCategorias,
        listarExames,
        listarFuncionariosEsocial,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ESocialForm);
