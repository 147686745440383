import { Button, Checkbox, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { patchFatorDeRisco, salvarFatorDeRisco } from "../../actions";

const { Option } = Select;

const FatorDeRiscoDNForm = (props) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState("new");
  const [fatorDeRiscoSelecionado, setFatorDeRiscoSelecionado] = useState({});
  const [active, setActive] = useState(false);

  useEffect(() => {
    console.log();
    if (
      props.fatorDeRiscoSelecionado &&
      Object.keys(props.fatorDeRiscoSelecionado).length > 0
    ) {
      setMode("edit");
      setFatorDeRiscoSelecionado(fatorDeRiscoSelecionado);
      setEdit(props.fatorDeRiscoSelecionado);
    }
  }, [JSON.stringify(props.fatorDeRiscoSelecionado)]);

  const setEdit = (fatorDeRiscoSelecionado) => {
    if (fatorDeRiscoSelecionado) {
      form.setFieldsValue({
        riskCode: fatorDeRiscoSelecionado.codigo,
        riskName: fatorDeRiscoSelecionado.descricao,
        riskGroup: fatorDeRiscoSelecionado.grupoDeRisco,
        riskMedium: fatorDeRiscoSelecionado.meioPropagacao,
        riskDamage: fatorDeRiscoSelecionado.danoPossivel,
        riskLaw: fatorDeRiscoSelecionado.fundamentacaoLegal,
        riskClass: fatorDeRiscoSelecionado.classificacaoDeRisco,
        riskMeasure: fatorDeRiscoSelecionado.unidadeDeMedida,
        riskActive: fatorDeRiscoSelecionado.ativo,
        riskDecimals: fatorDeRiscoSelecionado.qtdCasasDecimais,
        riskTolerance: fatorDeRiscoSelecionado.nivelTolerancia,
        riskActionLevel: fatorDeRiscoSelecionado.nivelDeAcao,
        riskSeverity: fatorDeRiscoSelecionado.gravidade,
      });
      setActive(fatorDeRiscoSelecionado.ativo);
    }
  };

  const addRisk = () => {
    form.validateFields().then((values) => {
      let newRisk = {
        codigo: values.riskCode,
        nome: values.riskName,
        grupoDeRisco: values.riskGroup,
        meioPropagacao: values.riskMedium,
        danoPossivel: values.riskDamage,
        fundamentacaoLegal: values.riskLaw,
        classificacaoDeRisco: values.riskClass,
        unidadeDeMedida: values.riskMeasure,
        ativo: active,
        qtdCasasDecimais: values.riskDecimals,
        nivelTolerancia: values.riskTolerance,
        nivelDeAcao: values.riskActionLevel,
        gravidade: values.riskSeverity,
      };
      salvarFatorDeRisco(newRisk, props.dispatch);
    });
  };

  const patchRisk = () => {
    form.validateFields().then((values) => {
      let edittedRisk = {
        codigo: values.riskCode,
        nome: values.riskName,
        grupoDeRisco: values.riskGroup,
        meioPropagacao: values.riskMedium,
        danoPossivel: values.riskDamage,
        fundamentacaoLegal: values.riskLaw,
        classificacaoDeRisco: values.riskClass,
        unidadeDeMedida: values.riskMeasure,
        ativo: active,
        qtdCasasDecimais: values.riskDecimals,
        nivelTolerancia: values.riskTolerance,
        nivelDeAcao: values.riskActionLevel,
        gravidade: values.riskSeverity,
      };
      console.log(props.fatorDeRiscoSelecionado);
      patchFatorDeRisco(
        props.fatorDeRiscoSelecionado.id,
        edittedRisk,
        props.dispatch
      );
    });
  };

  const checkboxOnChange = (e) => {
    setActive(e.target.checked);
  };

  return (
    <React.Fragment>
      {console.log(props)}
      <div className="dn-content">
        <Form
          form={form}
          onFinish={mode === "new" ? () => addRisk() : () => patchRisk()}
        >
          {console.log(mode)}
          <Form.Item label="Código do fator de risco" name="riskCode">
            <Input />
          </Form.Item>
          <Form.Item label="Nome do fator de risco" name="riskName">
            <Input />
          </Form.Item>
          <Form.Item label="Grupo" name="riskGroup">
            <Select
              style={{
                width: 250,
              }}
            >
              <Option value="FISICO">Físico</Option>
              <Option value="QUIMICO">Químico</Option>
              <Option value="BIOLOGICO">Biológico</Option>
              <Option value="ERGONOMICO">Ergonômico</Option>
              <Option value="ACIDENTE">Acidente</Option>
              <Option value="INESPECIFICO">Inespecífico</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Meio de propagação" name="riskMedium">
            <Input />
          </Form.Item>
          <Form.Item label="Dano possível" name="riskDamage">
            <Input />
          </Form.Item>
          <Form.Item label="Fundamentação legal" name="riskLaw">
            <Input />
          </Form.Item>
          <Form.Item label="Classificação do risco" name="riskClass">
            <Select
              style={{
                width: 250,
              }}
            >
              <Option value="QUANTITATIVO">Quantitativo</Option>
              <Option value="QUALITATIVO">Qualitativo</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Unidade de medida" name="riskMeasure">
            <Input />
          </Form.Item>
          <Form.Item label="Ativo" name="riskActive">
            <Checkbox checked={active} onChange={checkboxOnChange} />
          </Form.Item>
          <Form.Item label="Número de casas decimais" name="riskDecimals">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Nível de tolerância" name="riskTolerance">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Nível de ação" name="riskActionLevel">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Gravidade" name="riskSeverity">
            <Select
              style={{
                width: 250,
              }}
            >
              <Option value="BAIXA">Baixa</Option>
              <Option value="MEDIA">Média</Option>
              <Option value="ALTA">Alta</Option>
              <Option value="EXCESSIVA">Excessiva</Option>
            </Select>
          </Form.Item>
          <Button htmlType="submit">Salvar</Button>
        </Form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    fatorDeRiscoSelecionado: state.gestorDN.fatorDeRiscoSelecionado,
  };
};

export default connect(mapStateToProps)(FatorDeRiscoDNForm);
