import axios from "axios";
import {
  ACEITAR_PROCESSO,
  EDITAR_PROCESSO_PRODUTIVO,
  EXCLUIR_PROCESSO_PRODUTIVO,
  LISTAR_PROCESSO_PRODUTIVO,
  RECUSAR_PROCESSO_PRODUTIVO,
  LISTAR_PROCESSOS_PRODUTIVOS_DISPONIVEIS,
} from "./types";
import { setupInterceptors } from "../../../../../../../auth/SetupInterceptors";
import openNotification from "../../../../../../../components/notifications";
import {
  processosProdutivos,
  processosProdutivosPorCnae,
  incluirProcessoProdutivo,
  recusarProcessoProdutivo,
} from "../../../../../../../constants";
import { LISTAR_PROCESSOS_PRODUTIVOS } from "../../../CaracterizacaoRealizadorProcessos/_/actions/types";
setupInterceptors();

export const acceptProductiveProcess = (
  dispatch,
  id,
  newProcessBody,
  goToList,
  toggleLoading
) => {
  if (toggleLoading) toggleLoading(true);
  axios
    .post(incluirProcessoProdutivo, { idProcessoProdutivo: id })
    .then((response) => {
      dispatch({
        type: ACEITAR_PROCESSO,
        payload: response.data,
      });
      axios
        .patch(`${processosProdutivos}/${response.data.id}`, {
          nome: newProcessBody.nome,
          descricao: newProcessBody.descricao,
        })
        .then(() => {
          if (goToList) goToList();
          if (toggleLoading) toggleLoading(false);
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addProcessoProdutivo = (
  dispatch,
  newProcessBody,
  goToList,
  toggleLoading
) => {
  if (toggleLoading) toggleLoading(true);
  axios
    .post(processosProdutivos, newProcessBody)
    .then(() => {
      axios
        .get(processosProdutivos)
        .then((response) => {
          dispatch({
            type: LISTAR_PROCESSOS_PRODUTIVOS,
            payload: response.data,
          });
          openNotification(
            "Processo produtivo adicionado com sucesso!",
            "",
            "success"
          );
          if (goToList) goToList();
          if (toggleLoading) toggleLoading(false);
        })
        .catch((err) => console.log(err));
    })
    .catch((err) => {
      console.log(err);
      if (toggleLoading) toggleLoading(true);
    });
};

export const editProcessoProdutivo = (
  dispatch,
  selectedProcess,
  newProcessBody,
  goToList,
  toggleLoading
) => {
  if (toggleLoading) toggleLoading(true);
  newProcessBody.dataFim !== ""
    ? (newProcessBody.dataFim = newProcessBody.dataFim)
    : (newProcessBody.dataFim = null);
  axios
    .patch(
      `${processosProdutivos}/${selectedProcess.processoProdutivoId}`,
      newProcessBody
    )
    .then((response) => {
      dispatch({
        type: EDITAR_PROCESSO_PRODUTIVO,
        payload: response.data,
      });
      if (toggleLoading) toggleLoading(false);
      openNotification(
        "Processo produtivo editado com sucesso!",
        "",
        "success"
      );
      if (goToList) goToList();
    })
    .catch((err) => {
      console.log(err);
      if (toggleLoading) toggleLoading(false);
    });
};

export const deleteProductiveProcess = (dispatch, id) => {
  axios
    .delete(`${processosProdutivos}/${id}`)
    .then((response) => {
      openNotification(
        "Processo produtivo excluído com sucesso!",
        "",
        "success"
      );
      dispatch({
        type: EXCLUIR_PROCESSO_PRODUTIVO,
        payload: response.data,
      });
    })
    .catch((err) => {
      console.log(err);
      openNotification("Falha ao excluir o processo produtivo", "", "error");
    });
};

export const fetchProcesso = (dispatch, id) => {
  axios
    .get(`${processosProdutivos}/${id}`)
    .then((response) => {
      dispatch({
        type: LISTAR_PROCESSO_PRODUTIVO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const fetchProcessos = (dispatch) => {
  axios
    .get(processosProdutivos)
    .then((response) => {
      dispatch({
        type: LISTAR_PROCESSOS_PRODUTIVOS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const fetchProcessosPorCNAE = (dispatch) => {
  axios
    .get(processosProdutivosPorCnae)
    .then((response) => {
      dispatch({
        type: LISTAR_PROCESSOS_PRODUTIVOS_DISPONIVEIS,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const rejectProductiveProcess = (dispatch, id) => {
  axios
    .post(recusarProcessoProdutivo, { id })
    .then((response) => {
      dispatch({
        type: RECUSAR_PROCESSO_PRODUTIVO,
        payload: response.data,
      });
    })
    .catch((err) => console.log(err));
};

export const selectProcessSuggestedByCNAE = (dispatch, processCNAE) => {
  dispatch({
    type: LISTAR_PROCESSO_PRODUTIVO,
    payload: processCNAE,
  });
};
