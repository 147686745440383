import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getEPCs,
  getEPC,
  listarFatoresforGrupoDeRisco,
  patchEPC,
  emptyRelacaoMAOSelecionanda,
  addEPC,
} from "../../actions";
import "../../../styles.scss";

const { Option } = Select;

const RelacaoEPCForm = (props) => {
  // const [newRiskRelation, setNewRiskRelation] = useState();
  const [mode, setMode] = useState("new");
  const [
    relacaoEPCFatorDeRiscoSelecionada,
    setRelacaoEPCFatorDeRiscoSelecionada,
  ] = useState({});
  const [epc, setEpcs] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    getEPCs(props.dispatch);
  }, []);

  useEffect(() => {
    if (props.relacaoEPCFatorDeRiscoSelecionada) setMode("edit");
    if (props.relacaoEPCFatorDeRiscoSelecionada)
      setRelacaoEPCFatorDeRiscoSelecionada(
        props.relacaoEPCFatorDeRiscoSelecionada
      );
    console.log(props.relacaoEPCFatorDeRiscoSelecionada);
    setEdit(props.relacaoEPCFatorDeRiscoSelecionada);
  }, [JSON.stringify(props.relacaoEPCFatorDeRiscoSelecionada)]);

  useEffect(() => {
    setEpcs(epc);
  }, [JSON.stringify(epc)]);

  const setEdit = (relacaoEPCFatorDeRiscoSelecionada) => {
    if (mode === "edit" || props.relacaoEPCFatorDeRiscoSelecionada) {
      form.setFieldsValue({
        riskName: relacaoEPCFatorDeRiscoSelecionada.descricao,
        riskEPCs: relacaoEPCFatorDeRiscoSelecionada.epcs.map((epc) => epc.id),
      });
    }
  };

  const handleGroupSelection = () => {
    form
      .validateFields()
      .then((values) => {
        listarFatoresforGrupoDeRisco(values.riskGroup, props.dispatch);
      })
      .catch((err) => console.log(err));
  };

  const addNewEPC = () => {
    form.validateFields().then((values) => {
      let newRelacaoEPC = {
        epcs: values.riskEPCs,
      };
      addEPC(values.riskName, newRelacaoEPC, props.dispatch);
    });
  };

  const patchRelacaoEPC = () => {
    form.validateFields().then((values) => {
      let edittedEPC = {
        epcs: values.riskEPCs,
      };
      patchEPC(
        props.relacaoEPCFatorDeRiscoSelecionada.id,
        edittedEPC,
        props.dispatch
      );
      emptyRelacaoMAOSelecionanda(props.dispatch);
    });
  };

  return (
    <React.Fragment>
      <Form
        form={form}
        onFinish={mode === "new" ? () => addNewEPC() : () => patchRelacaoEPC()}
      >
        <Form.Item name="riskGroup">
          <Select
            style={{
              width: 250,
            }}
            placeholder="Selecione um grupo de risco"
            onSelect={() => handleGroupSelection()}
          >
            <Option value="1">Físico</Option>
            <Option value="2">Químico</Option>
            <Option value="3">Biológico</Option>
            <Option value="4">Ergonômico</Option>
            <Option value="5">Acidente</Option>
            <Option value="6">Inespecífico</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Fator de risco" name="riskName">
          <Select>
            {props.fatoresDeRiscoPorGrupo
              ? props.fatoresDeRiscoPorGrupo.map((fator) => (
                  <Option value={fator.id}>{fator.descricao}</Option>
                ))
              : ""}
          </Select>
        </Form.Item>
        <Form.Item label="EPCs associados" name="riskEPCs">
          <Select
            mode="multiple"
            style={{
              width: "100%",
            }}
          >
            {props.epcs
              ? props.epcs.map((epc) => (
                  <Option value={epc.id}>{epc.nome}</Option>
                ))
              : ""}
          </Select>
        </Form.Item>
        <Button htmlType="submit">Salvar</Button>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    relacaoEPCFatorDeRiscoSelecionada:
      state.gestorDN.relacaoEPCFatorDeRiscoSelecionada,
    epcs: state.gestorDN.listaEPCs,
    fatoresDeRiscoPorGrupo: state.gestorDN.fatoresDeRiscoPorGrupo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({
      getEPCs,
      getEPC,
      listarFatoresforGrupoDeRisco,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RelacaoEPCForm);
